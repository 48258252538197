import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tree from "./components/Tree";
import { Button, Dashboard, PageLoader, Main } from "components";
import { getChannelTaxons } from "actions/channel_taxons";

const ChannelTaxonsList = ({ channel_taxons, history, match: { params }, getChannelTaxons }) => {
  useEffect(() => {
    getChannelTaxons();
  }, []);
  if (!channel_taxons || channel_taxons.status === "invalid" || channel_taxons.status === "loading")
    return <PageLoader color='#2959F8' />;

  if (channel_taxons.status === "failure") {
    return <p>Error</p>;
  }
  const { data } = channel_taxons;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons`;
  return (
    <Main breadcrumbs={[{ name: "Kategorie produktowe" }]}>
      <Dashboard
        header={{
          icon: "categories",
          title: "Kategorie produktowe",
          aside: <Button type='add' text='Dodaj kategorię' path={`${path}/new`} />,
        }}
      >
        {data?.length > 0 && (
          <Tree
            history={history}
            head={["Nazwa", "ID", "W menu głównym?", "Slug"]}
            data={data}
            row_data={["id", "show_in_mega_menu", "slug"]}
            path={`/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons`}
          />
        )}
      </Dashboard>
    </Main>
  );
};

const mapStateToProps = ({ channel_taxons }) => ({ channel_taxons });

const mapDispatchToProps = dispatch => ({
  getChannelTaxons: (page, per_page, query, loading) =>
    dispatch(getChannelTaxons(page, per_page, query, loading)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelTaxonsList));
