import React from "react";
import DatePickerModule from "react-datetime-picker";
import { Input } from "expano-components";
import { getDate } from "utils/helpers";
import { Field } from "redux-form";
import { Calendar } from "icons";

const InputField = (props) => <Input {...props} />;

const DatePicker = ({ value, name, change, label, minDate }) => {
  return (
    <div className="form-group">
      <div className="inp-wrapper">
        {label && (
          <label htmlFor={name} className="label-form">
            {label}
          </label>
        )}
        <div className="calendar-group">
          <Field name={name} type="hidden" component={InputField} />
          <DatePickerModule
            calendarIcon={<Calendar />}
            disableClock={true}
            onChange={(val) => {
              change(name, getDate(val));
            }}
            value={value}
            minDate={minDate}
            showLeadingZeros={false}
            clearIcon={null}
            locale="pl-PL"
            format="y-MM-dd HH:mm"
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
