import React from "react";

const Calendar = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <rect fill='#595d6e' width='15.833' height='3.333' rx='1' transform='translate(1.667 4.167)' />
    <rect
      fill='#595d6e'
      opacity='0.3'
      width='15.833'
      height='8.333'
      rx='1'
      transform='translate(1.667 9.167)'
    />
  </svg>
);

export default Calendar;
