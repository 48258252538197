import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getDiscountCodes, filterDiscountCodes, toggleFilters } from "actions/discount_codes";

const getStatus = (date_start, date_end, is_unlimited) => {
  if (!date_start || (!date_end && !is_unlimited)) return "-";
  if (new Date() < new Date(date_start)) {
    return "Zaplanowana";
  }
  if (new Date() > new Date(date_start) && new Date() < new Date(date_end)) {
    return "Aktywna";
  }
  if (new Date() > new Date(date_start) && is_unlimited) {
    return "Aktywna (nie wygasa)";
  }
  return "Zakończona";
};

const DiscountCodesList = ({
  discount_codes,
  toggleFilters,
  getDiscountCodes,
  filterDiscountCodes,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscountCodes({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscountCodes({ ...query, page: undefined });
    }
  }, [discount_codes.filters.query]);

  if (discount_codes.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = discount_codes;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discount_codes`;
  return (
    <Main breadcrumbs={[{ name: "Kody rabatowe" }]}>
      <Dashboard
        header={{
          icon: "discount_codes",
          title: "Promocje",
          aside: <Button type='add' text='Dodaj kod rabatowy' path={`${path}/new`} />,
        }}
      >
        <MainTable
          is_loading={discount_codes.status === "invalid" || discount_codes.status === "loading"}
          is_searching={discount_codes.status === "searching"}
          filters_open={discount_codes.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id", style: { width: 100 } },
            { label: "Nazwa kodu", name: "name" },
            { label: "Data rozpoczęcia", name: "date_start" },
            { label: "Data zakończenia", name: "date_end" },
            { label: "Dostępność", name: "discount_code" },
            { label: "Wartość", name: "discount_code_type_value" },
            { label: "Status", name: "status", sortable: false },
          ]}
          renderRow={({
            id,
            name,
            code,
            date_start,
            date_end,
            discount_code_type,
            discount_code_type_value,
            discount_code_reference_type,
            is_unlimited,
            available_count,
            used_count,
          }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{id}</td>
              <td>
                <p>{name}</p>
                <p className='text-muted'>{code}</p>
              </td>
              <td>{date_start ? moment(date_start).format("DD.MM.YYYY HH:mm:ss") : "-"}</td>
              <td>
                {is_unlimited
                  ? "nie wygasa"
                  : date_end
                  ? moment(date_end).format("DD.MM.YYYY HH:mm:ss")
                  : "-"}
              </td>
              <td>
                <p>Wykorzystano: {used_count || 0}</p>
                <p className='text-muted'>Zaplanowano: {available_count}</p>
              </td>
              <td>
                {discount_code_type_value}
                {discount_code_type === "percentage"
                  ? "%"
                  : discount_code_type === "quantitative"
                  ? " szt."
                  : ""}
              </td>
              <td>{getStatus(date_start, date_end, is_unlimited)}</td>
            </tr>
          )}
          empty_text='Brak kodów rabatowych'
          filtersAction={filterDiscountCodes}
          filters={[
            {
              type: "input",
              label: "Nazwa lub kod",
              name: "name_or_code",
              search_type: "matches",
            },
            {
              type: "select",
              label: "Status",
              name: "discount_status",
              search_type: "discount_status",
              options: [
                { value: "all", label: "Wszystkie" },
                { value: "planned", label: "Zaplanowana" },
                { value: "active", label: "Aktywna" },
                { value: "unlimited", label: "Nie wygasa" },
                { value: "finished", label: "Zakończona" },
              ],
            },
            {
              type: "select",
              label: "Zakres",
              search_type: "in",
              name: "discount_code_reference_type",
              options: [
                { value: 0, label: "Brak" },
                { value: 1, label: "Produkty" },
                { value: 2, label: "Kolekcje" },
                { value: 3, label: "Kategorie" },
                { value: 4, label: "Producenci" },
              ],
            },
            {
              type: "date",
              label: "Data rozpoczęcia",
              name: "date_start",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data zakończenia",
              name: "date_end",
              search_type: "lt_eq",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getDiscountCodes: data => dispatch(getDiscountCodes(data)),
  filterDiscountCodes: data => dispatch(filterDiscountCodes(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ discount_codes }) => ({ discount_codes }),
  mapDispatchToProps
)(DiscountCodesList);
