import React, { useState } from "react";
import { Dashboard, MainTable } from "components";
import { Button } from "expano-components";
import Item from "./components/Item";
import DiscountsModal from "./components/DiscountsModal";

const DiscountTable = ({ title, kind, data }) => {
  const [is_open_discount_modal, handleOpenDiscountModal] = useState(false);
  return (
    <Dashboard
      header={{
        icon: "info",
        title,
        aside: (
          <Button
            type="add"
            text="Dodaj promocje"
            onClick={() => handleOpenDiscountModal(true)}
          />
        ),
      }}>
      {is_open_discount_modal && (
        <DiscountsModal
          kind={kind}
          handleClose={() => handleOpenDiscountModal(false)}
        />
      )}

      <MainTable
        is_loading={false}
        is_searching={false}
        data={data}
        meta={null}
        head={[
          { label: "ID", name: "id", sortable: false },
          { label: "Nazwa promocji", name: "name", sortable: false },
          { label: "Zakres", name: "range", sortable: false },
          { label: "Status", name: "sales_status", sortable: false },
          { label: "", name: "action", sortable: false, style: { width: 90 } },
        ]}
        renderRow={(props) => <Item key={props?.id} {...props} />}
        empty_text="Brak sekcji promocyjnych"
      />
    </Dashboard>
  );
};

export default DiscountTable;
