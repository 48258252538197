import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Input, Button, ModalDelete } from "expano-components";
import { deleteHreflang, updateHreflang } from "actions/articles";

const InputField = props => <Input {...props} />;

class EditPropertyOptionRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showDeleteModal: false, showEdit: false };
  }
  submit = values => {
    const { updateHreflang, id } = this.props;
    return updateHreflang(id, values)
      .then(data => {
        this.setState({ showEdit: false });
      })
      .catch(err => {
        throw new SubmissionError(err);
      });
  };

  handleDelete = () => {
    const { deleteHreflang, id } = this.props;
    deleteHreflang(id)
      .then(() => {
        this.setState({ showDeleteModal: false });
      })
  };

  render() {
    const { handleSubmit, initialValues } = this.props;
    const { showDeleteModal, showEdit } = this.state;
    return !showEdit ? (
      <>
        {showDeleteModal && (
          <ModalDelete
            handleDelete={this.handleDelete}
            handleClose={() => this.setState({ showDeleteModal: false })}
            name={`hreflang ${initialValues.lang_code}`}
          />
        )}
        <tr>
          <td>{initialValues.lang_code}</td>
          <td>{initialValues.url}</td>
          <td>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button type="edit" onClick={() => this.setState({ showEdit: true })} />
              <Button type="delete-bin" onClick={() => this.setState({ showDeleteModal: true })} />
            </div>
          </td>
        </tr>
      </>
    ) : (
      <tr>
        <td style={{ paddingRight: "30px" }}>
          <Field name={`lang_code`} component={InputField} />
        </td>
        <td style={{ paddingRight: "30px" }}>
          <Field name={`url`} component={InputField} />
        </td>
        <td>
          <button type="button" className="btn btn-icon" onClick={handleSubmit(this.submit)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g transform="translate(-1175 -784)">
                <rect fill="#0abb87" width="24" height="24" rx="4" transform="translate(1175 784)" />
                <path
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2px"
                  d="M9937,2197.432l3.8,3.71,6.2-8"
                  transform="translate(-8754.504 -1400.643)"
                />
              </g>
            </svg>
          </button>
          <button className="btn btn-icon" type="button" onClick={() => this.setState({ showEdit: false })}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g transform="translate(-1204.5 -784.5)">
                <rect fill="#fd397a" width="24" height="24" rx="4" transform="translate(1204.5 784.5)" />
                <line fill="none" stroke="#fff" strokeWidth="2px" x2="8" y2="8" transform="translate(1212.5 792.5)" />
                <line fill="none" stroke="#fff" strokeWidth="2px" x1="8" y2="8" transform="translate(1212.5 792.5)" />
              </g>
            </svg>
          </button>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateHreflang: (id, data) => dispatch(updateHreflang(id, data)),
  deleteHreflang: id => dispatch(deleteHreflang(id)),
});

export default connect(null, mapDispatchToProps)(reduxForm({ enableReinitialize: true })(EditPropertyOptionRow));
