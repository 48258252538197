import React from "react";

const OrderSummary = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M7.369,3v.421A1.263,1.263,0,0,0,8.632,4.684h4.211a1.263,1.263,0,0,0,1.263-1.263V3H15.79a1.684,1.684,0,0,1,1.684,1.684V18.158a1.684,1.684,0,0,1-1.684,1.684H5.684A1.684,1.684,0,0,1,4,18.158V4.684A1.684,1.684,0,0,1,5.684,3Z'
      transform='translate(-0.631 -0.474)'
    />
    <path
      fill='#5d78ff'
      d='M10.684,1.842a.842.842,0,0,1,1.684,0h1.263a.421.421,0,0,1,.421.421v.842a.421.421,0,0,1-.421.421H9.421A.421.421,0,0,1,9,3.105V2.263a.421.421,0,0,1,.421-.421Z'
      transform='translate(-1.421 -0.158)'
    />
    <rect fill='#5d78ff' opacity='0.3' width='4' height='2' rx='1' transform='translate(6 8)' />
    <rect fill='#5d78ff' opacity='0.3' width='7' height='2' rx='1' transform='translate(6 12)' />
  </svg>
);

export default OrderSummary;
