
import {toastr} from "react-redux-toastr";
import {client, apiFormatter} from "utils/api";

export const getPaymentMethods = () => async (dispatch) => {
  dispatch({type: "PAYMENT_METHODS_LOADING"});
  await client
    .get(`/payment_methods`)
    .then(({data}) => {
      dispatch({
        type: "PAYMENT_METHODS_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({type: "PAYMENT_METHODS_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};
