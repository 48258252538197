import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Main,
  ActionTop,
  PageLoader,
  ProductSaleStatus,
} from "components";
import { getChannelProduct } from "actions/channel_products";
import {
  Specification,
  BasicData,
  Gallery,
  Description,
  PriceHistory,
} from "./components";

const Redirect = ({ channel_product, params, history }) => {
  useEffect(() => {
    history.push(
      `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_products/${channel_product.data.id}/basic_data`
    );
  }, []);
  return null;
};

const ChannelProductShow = ({
  channel_product,
  getChannelProduct,
  match: { params },
  history,
}) => {
  useEffect(() => {
    getChannelProduct(params.id);
  }, []);
  const tabs = [
    {
      id: "basic_data",
      name: "Podstawowe informacje",
    },
    {
      id: "gallery",
      name: "Galeria produktu",
    },
    {
      id: "description",
      name: "Opis",
    },
    {
      id: "specification",
      name: "Specyfikacja",
    },
    {
      id: "price_history",
      name: "30-dniowa historia cen",
    },
  ];

  const renderPanels = () => {
    switch (params?.tab) {
      case "basic_data":
        return <BasicData />;
      case "gallery":
        return <Gallery />;
      case "description":
        return <Description />;
      case "specification":
        return <Specification />;
      case "price_history":
        return <PriceHistory />;
      default:
        return (
          <Redirect
            channel_product={channel_product}
            history={history}
            params={params}
          />
        );
    }
  };
  if (
    channel_product.status === "invalid" ||
    channel_product.status === "loading"
  )
    return <PageLoader />;
  if (channel_product.status === "failure") {
    return <p>Error</p>;
  }
  const { data } = channel_product;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Produkty",
          path: `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_products`,
          icon: "product",
        },
        { name: data.name },
      ]}>
      <ActionTop
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24">
            <g
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                fill="#494B74"
              />
              <path
                d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                fill="#494B74"
                opacity="0.3"
              />
            </g>
          </svg>
        }
        title={data.name}
        subtitle={data.sku}
        asideComponent={
          <ProductSaleStatus status={data.sales_status} />
        }
      />
      <ul
        className="dashboard__tabs"
        style={{ margin: "-21px 0 20px" }}>
        {tabs.map(({ id, name }) => (
          <li
            key={id}
            className={`tab dashboard-tab-text ${
              id === params.tab ? "active" : ""
            }`}
            onClick={() =>
              history.push(
                `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_products/${data.id}/${id}`
              )
            }>
            {name}
          </li>
        ))}
      </ul>
      {renderPanels()}
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getChannelProduct: (id) =>
    dispatch(getChannelProduct(id)),
});

export default connect(
  ({ channel_product }) => ({
    channel_product,
  }),
  mapDispatchToProps
)(withRouter(ChannelProductShow));
