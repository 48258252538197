import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "PAGES_TOGGLE_FILTERS" });
};

export const filterPages = data => async dispatch => {
  dispatch({ type: "PAGES_SET_FILTERS_QUERY", data });
};

export const getPages = ({ page = 1, per_page = 10, ...rest }) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().articles;
  status === "invalid"
    ? dispatch({ type: "PAGES_LOADING" })
    : dispatch({ type: "PAGES_SEARCHING" });
  await client
    .get(`/pages?${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "PAGES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "PAGES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getPage = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "PAGE_LOADING" });
    await client
      .get(`/pages/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "PAGE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "PAGE_FAILURE" });
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const createPage = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/pages`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano stronę");
        resolve(data);
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updatePage = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/pages/${id}`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Strona została zaktualizowana");
        dispatch({
          type: "PAGE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(err.response.data.errors));
      });
  });

export const deletePage = id =>
  new Promise(async (resolve, reject) => {
    await client
      .delete(`/pages/${id}`)
      .then(() => {
        toastr.success("Sukces", "Strona została usunięta");
        resolve();
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });
