const getDiscountStatus = (date_start, date_end, is_unlimited) => {
  if (!date_start || (!date_end && !is_unlimited)) return "-";
  if (new Date() < new Date(date_start)) {
    return "Zaplanowana";
  }
  if (new Date() > new Date(date_start) && new Date() < new Date(date_end)) {
    return "Aktywna";
  }
  if (new Date() > new Date(date_start) && is_unlimited) {
    return "Aktywna (nie wygasa)";
  }
  return "Zakończona";
};

export default getDiscountStatus;
