import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Dashboard } from "components";
import { ImageUploader } from "expano-components";
import { updateChannelTaxon } from "actions/channel_taxons";
import { image_url } from "utils/api";
import { toBase64 } from "utils/helpers";

const Banner = ({ channel_taxon, updateChannelTaxon }) => {
  const init_image = channel_taxon?.banner_url
    ? [{ path: `${image_url}${channel_taxon.banner_url}` }]
    : [];
  const handleUploadImage = (file) => {
    return new Promise(async (resolve) => {
      const banner_data = await toBase64(file);
      await updateChannelTaxon(channel_taxon.id, { banner_data });
      resolve();
    });
  };

  return (
    <Dashboard header={{ title: "Banner", icon: "info" }}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .banner .image-uploader__box{ width: 100%; height: 70px; transition: none; padding: 0; margin: 0;}`}</style>
      </Helmet>
      <div className="dashboard__form banner">
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Banner (1600px x 200px)</label>
            <ImageUploader
              addText="dodaj banner"
              url={""}
              images={init_image}
              limit={1}
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateChannelTaxon: (id, data) =>
    dispatch(updateChannelTaxon(id, data)),
});

export default connect(
  ({ channel_taxon }) => ({
    channel_taxon,
  }),
  mapDispatchToProps
)(Banner);
