import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dashboard } from "components";

const ClientPanel = ({
  data,
  shipping_address,
  billing_address,
  user,
  channel_id,
  history,
  match,
}) => {
  return (
    <Dashboard
      header={{
        title: "Dane klienta",
        icon: "clients",
        aside: data?.client && (
          <Link
            className='user-link'
            to={`/platforms/${match.params?.platform_id}/channels/${channel_id}/clients/${data?.client?.id}`}
          >
            <span>Profil klienta</span>
          </Link>
        ),
      }}
    >
      {data?.client && (
        <div className='order-client__user'>
          <div className='user-icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              viewBox='0 0 20.255 20.255'
            >
              <path
                fill='#5d78f9'
                opacity='0.3'
                d='M11.376,9.752a3.376,3.376,0,1,1,3.376-3.376A3.376,3.376,0,0,1,11.376,9.752Z'
                transform='translate(-1.248 -0.468)'
              />
              <path
                fill='#5d78f9'
                d='M3,19.076C3.328,15.048,6.6,13,10.582,13c4.041,0,7.361,1.935,7.608,6.077a.58.58,0,0,1-.634.675H3.614A.934.934,0,0,1,3,19.076Z'
                transform='translate(-0.468 -2.028)'
              />
            </svg>
          </div>
          <p className='user-name'>
            {data?.client?.first_name} {data?.client?.last_name}
          </p>
          <p className='user-id'>ID {data?.client?.id}</p>
        </div>
      )}
      <div className='order-client__address'>
        <h3 className='title'>Adres rozliczeniowy</h3>
        {(data?.billing_company_name || data?.billing_company_vat_id) && (
          <div className='group'>
            {data?.billing_company_name && <p>{data?.billing_company_name}</p>}
            {data?.billing_company_vat_id && <p>NIP: {data?.billing_company_vat_id}</p>}
          </div>
        )}
        <div className='group'>
          <p>
            {data?.billing_first_name} {data?.billing_last_name}
          </p>
          <p>{data?.billing_street}</p>
          <p>
            {data?.billing_zip_code} {data?.billing_city}
          </p>
          <p>{data?.billing_email}</p>
          <p>{data?.billing_phone}</p>
        </div>
      </div>
      <div className='order-client__address'>
        <h3 className='title'>Adres dostawy</h3>
        {shipping_address?.same_as_billing ? (
          <div className='group'>
            <p>Taki sam jak adres rozliczeniowy</p>
          </div>
        ) : (
          <div className='group'>
            <p>
              {data?.shipping_first_name} {data?.shipping_last_name}
            </p>
            <p>{data?.shipping_street}</p>
            <p>
              {data?.shipping_zip_code} {data?.shipping_city}
            </p>
            <p>{data?.shipping_email}</p>
            <p>{data?.shipping_phone}</p>
          </div>
        )}
        {data?.shipping_point_id && (
          <>
            <h3 className='title'>Adres punktu dostawy</h3>
            <div className='group'>
              <p>{data?.shipping_point_id}</p>
              <p> ul. {data?.shipping_point_street}</p>
              <p>
                {data?.shipping_point_zip_code} {data?.shipping_point_city}
              </p>
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
};

export default connect(
  ({ order, channel }) => ({
    data: order.data,
    channel_id: channel.data.id,
  }),
  null
)(withRouter(ClientPanel));
