import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalDelete, Button } from "expano-components";
import { deleteHomeSection, moveHomeSection } from "actions/home_sections";
import section_types from "../../const/section_types";

const Item = ({
  id,
  position,
  count,
  deleteHomeSection,
  moveHomeSection,
  sectionable,
}) => {
  const [is_open, handleOpen] = useState(false);
  return (
    <tr>
      {is_open && (
        <ModalDelete
          name={id}
          handleDelete={async () => {
            await deleteHomeSection(id);
            handleOpen(false);
          }}
          handleClose={() => {
            handleOpen(false);
          }}
        />
      )}
      <td>{id}</td>
      <td>{sectionable?.name}</td>
      <td>
        {section_types.find(
          ({ table_value }) => table_value === sectionable?.type
        )?.label || "-"}
      </td>
      <td>
        <div className="order-buttons">
          <button
            class="order-button up"
            disabled={position <= 1}
            onClick={() => moveHomeSection(id, { position: position - 1 })}>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false">
              <path
                fill="rgba(162, 165, 185, 1)"
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </button>
          <button
            type="button"
            class="order-button down"
            disabled={position >= count}
            onClick={() => moveHomeSection(id, { position: position + 1 })}>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false">
              <path
                fill="rgba(162, 165, 185, 1)"
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </button>
        </div>
      </td>
      <td>
        <Button type="delete-bin" onClick={() => handleOpen(true)} />
      </td>
    </tr>
  );
};

export default connect(null, (dispatch) => ({
  deleteHomeSection: (id) => dispatch(deleteHomeSection(id)),
  moveHomeSection: (id, data) => dispatch(moveHomeSection(id, data)),
}))(Item);
