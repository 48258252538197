import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getPages, filterPages, toggleFilters } from "actions/pages";

const PagesList = ({
  pages,
  toggleFilters,
  getPages,
  filterPages,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getPages({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getPages({ ...query, page: undefined });
    }
  }, [pages.filters.query]);

  if (pages.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = pages;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/pages`;
  return (
    <Main breadcrumbs={[{ name: "Artykuły" }]}>
      <Dashboard
        header={{
          icon: "page",
          title: "Strony statyczne",
          aside: <Button type='add' text='Dodaj stronę' path={`${path}/new`} />,
        }}
      >
        <MainTable
          is_loading={pages.status === "invalid" || pages.status === "loading"}
          is_searching={pages.status === "searching"}
          filters_open={pages.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Nazwa", name: "title" },
            { label: "Slug", name: "slug" },
          ]}
          renderRow={({ id, title, slug }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{title}</td>
              <td>{slug}</td>
            </tr>
          )}
          empty_text='Brak stron'
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getPages: data => dispatch(getPages(data)),
  filterPages: data => dispatch(filterPages(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(({ pages }) => ({ pages }), mapDispatchToProps)(PagesList);
