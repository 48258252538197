import React from "react";
import { Button } from "expano-components";
import { Dashboard, Main } from "components";
import { reduxForm, SubmissionError } from "redux-form";
import { createChannelTaxon } from "actions/channel_taxons";
import { withRouter } from "react-router-dom";
import BasicData from "./components/BasicData";

const ChannelTaxonNew = ({
  handleSubmit,
  history,
  pristine,
  match: { params },
  change,
}) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons`;
  const submit = (values) =>
    createChannelTaxon(values)
      .then((id) => history.push(`${path}/${id}`))
      .catch((err) => {
        console.log(err);
        throw new SubmissionError(err);
      });
  return (
    <Main
      breadcrumbs={[
        {
          name: "Kategorie produktowe",
          path,
        },
        {
          name: "Dodaj",
        },
      ]}>
      <form onSubmit={handleSubmit(submit)}>
        <Dashboard
          header={{
            icon: "category",
            title: "Dodaj kategorię",
            aside: (
              <div className="row">
                <Button
                  type="cancel"
                  text="Anuluj"
                  style={{ marginRight: 20 }}
                  onClick={() => history.push(path)}
                />
                <Button
                  type="save"
                  text="Zapisz"
                  disabled={pristine}
                  onClick={handleSubmit(submit)}
                />
              </div>
            ),
          }}
        />
        <BasicData change={change} />
      </form>
    </Main>
  );
};

export default reduxForm({
  form: "new-category",
  enableReinitialize: true,
})(withRouter(ChannelTaxonNew));
