import React from "react";
import { BasicForm, Content, Seo, Icon, Banner } from "./components";

const BasicData = () => {
  return (
    <>
      <BasicForm />
      <div className='dashboard-wrapper-flex'>
        <div className='column-half'>
          <Icon />
        </div>
        <div className='column-half'>
          <Banner />
        </div>
      </div>
      <div className='dashboard-wrapper-flex'>
        <div className='column-2'>
          <Content />
        </div>
        <div className='column-1'>
          <Seo />
        </div>
      </div>
    </>
  );
};

export default BasicData;
