import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Add, Edit } from "./components";

const HrefLangs = ({ article }) => {
  const [show_add, showAdd] = useState(false);
  return (
    <div className='column-half'>
      <div className='dashboard dashboard'>
        <header className='dashboard__header dashboard__header--edit'>
          <div className='icon__container'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
              version='1.1'
              className='kt-svg-icon'
            >
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <rect x='0' y='0' width='24' height='24' />
                <path
                  d='M10,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,7 C22,7.55228475 21.5522847,8 21,8 L10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 L22,19 C22,19.5522847 21.5522847,20 21,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z'
                  fill='#000000'
                />
                <rect fill='#000000' className='svg-rect' opacity='0.3' x='2' y='4' width='5' height='16' rx='1' />
              </g>
            </svg>
          </div>
          <h2 className='dashboard__title heading'>Hreflangi</h2>
        </header>

        <table className='table'>
          <thead className='table__header table-head'>
            <tr>
              <th width='120px'>Kod języka</th>
              <th>URL</th>
              <th width='110px'>Działania</th>
            </tr>
          </thead>
          {article?.article_hreflangs?.length > 0 && (
            <tbody className='table__body table-body'>
              {article.article_hreflangs.map(item => (
                <Edit
                  key={item.id}
                  form={`edit-hreflang_${item.id}`}
                  initialValues={{ lang_code: item.lang_code, url: item.url, article_id: article.id }}
                  id={item.id}
                />
              ))}
            </tbody>
          )}
        </table>

        {show_add ? (
          <Add article_id={article?.id} handleClose={() => showAdd(false)} />
        ) : (
          <div className='dashboard__button-section'>
            <Button type='add' text='Dodaj hreflang' onClick={() => showAdd(true)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(({ article }) => ({ article }), null)(HrefLangs);
