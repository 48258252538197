import React from "react";
import { BillingForm, ShippingForm } from "./components";

const ClientEditAddresses = () => (
  <div className='dashboard-wrapper-flex'>
    <div className='column-half'>
      <BillingForm />
    </div>
    <div className='column-half'>
      <ShippingForm />
    </div>
  </div>
);

export default ClientEditAddresses;
