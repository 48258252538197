const initialState = {
  loading_status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "COLLECTION_LOADING":
      return {
        ...state,
        loading_status: "loading",
      };
    case "COLLECTION_SUCCESS":
      return {
        ...state,
        loading_status: "success",
        ...action.data,
      };
    case "COLLECTION_FAILURE":
      return {
        ...state,
        loading_status: "failure",
      };
    default:
      return state;
  }
};
