import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "components";
import { Button, Select } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { addHomeSection } from "actions/home_sections";
import { Sections } from "icons";

import AsyncCollections from "./components/AsyncCollections";
import AsyncTaxons from "./components/AsyncTaxons";
import AsyncProducers from "./components/AsyncProducers";

import section_types from "../../const/section_types";

const SectionModal = ({
  change,
  addHomeSection,
  handleSubmit,
  handleClose,
  pristine,
}) => {
  const [sectionable_type, setSectionableType] = useState(null);
  const submit = ({ sectionable_id }) =>
    addHomeSection({
      sectionable_type,
      sectionable_id,
    }).then(() => {
      handleClose();
    });
  const renderField = () => {
    const common_props = {
      label: "Obiekt",
      name: "sectionable_id",
      change,
    };
    switch (sectionable_type) {
      case "Collection":
        return <Field {...common_props} component={AsyncCollections} />;
      case "ChannelTaxon":
        return <Field {...common_props} component={AsyncTaxons} />;
      case "ChannelProducer":
        return <Field {...common_props} component={AsyncProducers} />;
      default:
        return <Select label="Obiekt" isDisabled={true} />;
    }
  };
  return (
    <Modal>
      <form onSubmit={handleSubmit(submit)}>
        <header className="popup__header">
          <div className="icon__container">
            <Sections />
          </div>
          <h2 className="dashboard__title heading">Dodaj sekcję</h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body" style={{ padding: 30 }}>
          <Select
            label="Rodzaj"
            options={section_types}
            handleChange={(value) => {
              change("sectionable_id", null);
              setSectionableType(value);
            }}
          />
          {renderField()}
        </div>

        <div className="popup__footer p-r-30">
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            text="Dodaj"
            onClick={handleSubmit(submit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default connect(
  () => ({ initialValues: { sectionable_id: null } }),
  (dispatch) => ({
    addHomeSection: (data) => dispatch(addHomeSection(data)),
  })
)(
  reduxForm({
    form: "home-page-sections-form",
    enableReinitialize: true,
  })(SectionModal)
);
