import React, { useEffect, PureComponent } from "react";
import { connect } from "react-redux";
import { PageLoader, Main } from "components";
import { getChannelTaxon, resetChannelTaxonEdit } from "actions/channel_taxons";
import { BasicData, ProductsList } from "./components";

const Redirect = ({ channel_taxon, match: { params }, history }) => {
  useEffect(() => {
    history.push(`/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons/${channel_taxon.id}/basic_data`);
  }, []);
  return null;
};

const tabs = [
  { id: "basic_data", name: "Ogólne" },
  { id: "products", name: "Produkty" },
];
class ChannelTaxonEdit extends PureComponent {
  componentDidMount() {
    const {
      getChannelTaxon,
      match: {
        params: { id },
      },
    } = this.props;
    getChannelTaxon(id);
  }

  renderPanels = () => {
    switch (this.props.match?.params?.tab) {
      case "basic_data":
        return <BasicData />;
      case "products":
        return <ProductsList />;
      default:
        return <Redirect {...this.props} />;
    }
  };

  componentWillUnmount = () => {
    this.props.resetChannelTaxonEdit();
  };

  render() {
    const {
      channel_taxon,
      history,
      match: { params },
    } = this.props;

    if (!channel_taxon || channel_taxon.status === "invalid" || channel_taxon.status === "loading") return <PageLoader color='#2959F8' />;

    if (channel_taxon.status === "failure") {
      return <p>Error</p>;
    }
    return (
      <Main
        breadcrumbs={[
          {
            name: "Kategorie produktowe",
            path: `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons`,
            icon: "product_channel_taxons",
          },
          {
            name: channel_taxon?.name,
          },
        ]}
      >
        <div className='dashboard-wrapper-flex'>
          <div className='dashboard dashboard--nav'>
            <ul className='dashboard__tabs'>
              {tabs.map(({ id, name }) => (
                <li
                  key={id}
                  onClick={() =>
                    history.push(`/platforms/${params.platform_id}/channels/${params.channel_id}/channel_taxons/${channel_taxon.id}/${id}`)
                  }
                  className={`tab dashboard-tab-text ${id === params.tab ? "active" : ""}`}
                >
                  {name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {this.renderPanels()}
      </Main>
    );
  }
}
const mapStateToProps = ({ channel_taxon }) => ({
  channel_taxon,
});

const mapDispatchToProps = dispatch => ({
  getChannelTaxon: id => dispatch(getChannelTaxon(id)),
  resetChannelTaxonEdit: () => dispatch(resetChannelTaxonEdit()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChannelTaxonEdit);
