import React from "react";
import { connect } from "react-redux";
import { Modal } from "components";
import { Button } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { addHomeDiscount } from "actions/home_discounts";
import { Discounts } from "icons";

import AsyncDiscounts from "./components/AsyncDiscounts";

const SelectField = (props) => <AsyncDiscounts isMulti={true} {...props} />;

const DiscountsModal = ({
  change,
  handleSubmit,
  handleClose,
  pristine,
  addHomeDiscount,
  kind,
}) => {
  const submit = ({ discount_id }) =>
    addHomeDiscount({
      discount_id,
      kind,
    }).then(() => {
      handleClose();
    });
  return (
    <Modal size="md">
      <form onSubmit={handleSubmit(submit)}>
        <header className="popup__header">
          <div className="icon__container">
            <Discounts />
          </div>
          <h2 className="dashboard__title heading">Dodaj sekcję promocyjną</h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body" style={{ padding: 30 }}>
          <Field
            label="Dodaj promocje"
            name="discount_id"
            component={SelectField}
            change={change}
          />
        </div>

        <div className="popup__footer p-r-30">
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            text="Dodaj"
            onClick={handleSubmit(submit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  addHomeDiscount: (data) => dispatch(addHomeDiscount(data)),
}))(
  reduxForm({
    form: "home-page-discounts-form",
    enableReinitialize: true,
  })(DiscountsModal)
);
