import React from "react";
import { Button } from "expano-components";

const ActionTop = ({
  title,
  subtitle,
  icon,
  handleCancel,
  disabled,
  handleSubmit,
  saveText = "Zapisz",
  asideComponent,
}) => {
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">{icon}</div>
        <h2 className="dashboard__title heading">
          {title}
          <span className="heading__text">{subtitle}</span>
        </h2>
        {handleCancel && (
          <Button
            type="cancel"
            text="Anuluj"
            style={{ marginRight: 20 }}
            onClick={handleCancel}
          />
        )}
        {handleSubmit && (
          <Button
            type="save"
            text={saveText}
            disabled={disabled}
            onClick={handleSubmit}
          />
        )}
        {asideComponent}
      </header>
    </div>
  );
};

export default ActionTop;
