import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

const SidebarSelect = ({ initValue, options = [], value, onChange }) => {
  const customStyles = {
    control: styles => ({
      ...styles,
      border: "none",
      background: "rgba(162, 165, 185, .3)",
      minHeight: 25,
      boxShadow: "none",
    }),

    option: (provided, state) => ({
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "2px 10px",
      color: state.isSelected ? "#fff" : "#A2A5B9",
      transition: "0.2s",
      ":hover": {
        color: "#5d78ff",
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "0 10px",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      lineHeight: "15px",
      padding: "0px",
      color: "#A2A5B9",
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    menu: provided => ({
      ...provided,
      background: "#1e1e2d",
    }),
  };
  return (
    <div className='channel-select'>
      <Select onChange={onChange} styles={customStyles} options={options} placeholder={"Wybierz..."} value={value} />
    </div>
  );
};

export default connect(({ channels, channel }) => ({ channels: channels.data, channel: channel.data }))(SidebarSelect);
