import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "expano-components";
import TopLineActions from './components/TopLineActions'

const Main = ({ children, breadcrumbs, actions }) => {
  return (
    <div className="main-dashboard">
      <div className="main-dashboard__top-line">
        <div className="top-line__wrapper">
          <Breadcrumbs
            data={breadcrumbs}
            Link={Link}
          />
          {actions && <TopLineActions data={actions} />}
        </div>
      </div>
      <div className="main-dashboard__container">{children}</div>
    </div>
  )
}

export default Main;
