import React from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { PageLoader } from "components";
import Form from "./components/Form";
import FormNew from "./components/FormNew";
import { getBanners } from "actions/banners";
import { Info } from "icons";

class Banners extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active_tab_id: null,
    };
  }
  componentDidMount = () => {
    this.props.getBanners().then(() => {
      if (this.props.banners?.data?.length > 0) {
        this.setState({ active_tab_id: this.props.banners.data[0].id });
      }
    });
  };

  render() {
    const { banners } = this.props;
    const { active_tab_id } = this.state;
    if (banners.status === "invalid" || banners.status === "loading")
      return <PageLoader />;
    if (banners.status === "failure") {
      return <p>Error</p>;
    }
    return (
      <div className="dashboard-wrapper-flex">
        <div className="column-1">
          <div className="dashboard bg-none">
            <ul className="aside-tabs">
              {banners?.data
                .sort((item, next) => item.id - next.id)
                .map(({ name, id }) => (
                  <li
                    className={`tab ${id === active_tab_id ? "active" : ""}`}
                    key={id}
                    onClick={() => this.setState({ active_tab_id: id })}>
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16">
                        <g transform="translate(-324 -532)">
                          <circle
                            fill="#5d78ff"
                            cx="8"
                            cy="8"
                            r="8"
                            opacity="0.3"
                            transform="translate(324 532)"
                          />
                          <path
                            fill="#5d78ff"
                            d="M23.1,14.636l1.725-1.651a.55.55,0,0,0,.179-.394.625.625,0,0,0-.15-.388.569.569,0,0,0-.88.02l-2.457,2.419V12.648a.6.6,0,0,0-.162-.427.58.58,0,0,0-.441-.177.622.622,0,0,0-.449.173.584.584,0,0,0-.18.432V17.4a.587.587,0,0,0,.612.6.608.608,0,0,0,.452-.175.594.594,0,0,0,.168-.43V16.15l.665-.636,1.716,2.26a.538.538,0,0,0,.452.23.7.7,0,0,0,.453-.166.571.571,0,0,0,.209-.454.655.655,0,0,0-.136-.4Z"
                            transform="translate(309.713 524.996)"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="text">
                      <p>{name}</p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          {banners?.data?.length < 4 && (
            <div className="aside-tabs__action">
              <Button
                type="add"
                text="Dodaj baner "
                onClick={() => this.setState({ active_tab_id: "new" })}
              />
            </div>
          )}
        </div>
        <div className="column-2">
          <div className="dashboard">
            <header className="dashboard__header dashboard__header--edit">
              <div className="icon__container">
                <Info />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            {banners?.data.map(
              ({ id, name, url, image_url }) =>
                id === active_tab_id && (
                  <Form
                    key={id}
                    form={`banner_${id}`}
                    id={id}
                    data={{ image_url }}
                    initialValues={{ name, url }}
                  />
                )
            )}
            {active_tab_id === "new" && (
              <FormNew
                handleChangeTab={(active_tab_id) =>
                  this.setState({ active_tab_id })
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBanners: () => dispatch(getBanners()),
});

export default connect(
  ({ banners }) => ({ banners }),
  mapDispatchToProps
)(Banners);
