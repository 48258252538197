import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "DISCOUNT_CODES_TOGGLE_FILTERS" });
};

export const filterDiscountCodes = data => async dispatch => {
  dispatch({ type: "DISCOUNT_CODES_SET_FILTERS_QUERY", data });
};

export const getDiscountCodes = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().discount_codes;
  status === "invalid"
    ? dispatch({ type: "DISCOUNT_CODES_LOADING" })
    : dispatch({ type: "DISCOUNT_CODES_SEARCHING" });
  await client
    .get(`/discount_codes?&${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "DISCOUNT_CODES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "DISCOUNT_CODES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getDiscountCode = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "DISCOUNT_CODE_LOADING" });
    await client
      .get(`/discount_codes/${id}?include=orders`)
      .then(({ data }) => {
        dispatch({
          type: "DISCOUNT_CODE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "DISCOUNT_CODE_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const updateDiscountCode = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/discount_codes/${id}`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano kod rabatowy");
        dispatch({
          type: "DISCOUNT_CODE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania");
        dispatch({ type: "DISCOUNT_CODE_FAILURE" });
        reject();
      });
  });

export const createDiscountCode = values =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/discount_codes/`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Utworzono kod rabatowy");
        resolve(data);
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas tworzenia kodu rabatowego");
        reject();
      });
  });

export const deleteDiscountCode = id =>
  new Promise(async (resolve, reject) => {
    await client
      .delete(`/discount_codes/${id}`)
      .then(({ data }) => {
        toastr.success("Sukces", "Kod rabatowy został usunięty");
        resolve();
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas usuwania kodu rabatowego");
        reject();
      });
  });
