import React from "react";

const Logs = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      d='M1.423,6.423A.833.833,0,1,1,.244,5.244l5-5A.833.833,0,0,1,6.4.219l5,4.583A.833.833,0,1,1,10.27,6.031L5.858,1.987Z'
      transform='translate(10.833 4.167) rotate(90)'
    />
    <rect
      fill='#5d78ff'
      opacity='0.3'
      width='8.333'
      height='1.667'
      rx='0.833'
      transform='translate(10 14.167)'
    />
  </svg>
);

export default Logs;
