import React, { useState } from "react";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import Editor from "react-markdown-editor-lite";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Dashboard } from "components";
import { updateCollection } from "actions/collections";

const Content = ({ collection, updateCollection, handleSubmit, pristine, change, dispatch }) => {
  const [text, setText] = useState(collection?.description || "");

  const submit = values =>
    updateCollection(collection.id, values).catch(err => {
      console.log(err);
      throw new SubmissionError(err);
    });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Content", icon: "edit" }}
        footer={{
          buttons: (
            <>
              {!pristine && (
                <Button
                  type='cancel'
                  onClick={() => {
                    dispatch(reset("edit-collection-content"));
                    setText(collection?.description || "");
                  }}
                />
              )}
              <Button
                type='save'
                text='Zapisz'
                disabled={pristine}
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form'>
          <Field component={props => <Input {...props} />} type='hidden' name='description' />
          <Editor
            plugins={["header", "fonts", "link"]}
            value={text}
            config={{
              view: {
                menu: true,
                md: true,
                html: false,
                fullScreen: false,
                hideMenu: false,
              },
            }}
            style={{ height: "600px" }}
            onChange={({ text }) => {
              change("description", text);
              setText(text);
            }}
          />
        </div>
      </Dashboard>
    </form>
  );
};

const mapStateToProps = ({ collection }) => ({
  collection,
  initialValues: { description: collection?.description },
});

const mapDispatchToProps = dispatch => ({
  updateCollection: (id, data) => dispatch(updateCollection(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "edit-collection-content", enableReinitialize: true })(Content));
