import React from "react";

const OrdersHistory = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.255'
    height='20.255'
    viewBox='0 0 20.255 20.255'
  >
    <path
      fill='#5d78ff'
      d='M11.525,14.02,16.949,9.7l.133-.106a1.266,1.266,0,0,0,0-1.983L11.525,3.192a1.266,1.266,0,0,0-1.576,0L4.386,7.616a1.266,1.266,0,0,0,0,1.981l.132.105L9.949,14.02A1.266,1.266,0,0,0,11.525,14.02Z'
      transform='translate(-0.61 -0.455)'
    />
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M9.935,17.929,5.107,14.091a.762.762,0,0,0-1.071.122l-.006.008h0A.767.767,0,0,0,4.167,15.3l0,0,5.79,4.429a1.266,1.266,0,0,0,1.538,0L17.287,15.3a.772.772,0,0,0,.144-1.083l0,0h0a.769.769,0,0,0-1.078-.136l-.007.006-4.832,3.843A1.266,1.266,0,0,1,9.935,17.929Z'
      transform='translate(-0.604 -2.172)'
    />
  </svg>
);

export default OrdersHistory;
