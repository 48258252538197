const initialState = {
  status: "invalid",
  filters: {
    open: true,
    values: {},
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CLIENT_ORDERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "CLIENT_ORDERS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "CLIENT_ORDERS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action.data,
      };
    case "CLIENT_ORDERS_FAILURE":
      return {
        ...state,
        status: "failure",
      };

    default:
      return state;
  }
};
