import React, { useState } from "react";
import { ModalPreview } from "expano-components";

const ImageListRow = ({
  channel_product,
  regular_total_price,
  total_price,
  quantity,
}) => {
  const [show, setShow] = useState(false);
  const is_discounted = regular_total_price !== total_price;
  return (
    <>
      {channel_product?.channel_product_gallery_images
        ?.length > 0 &&
        show && (
          <ModalPreview
            src={`${channel_product?.channel_product_gallery_images[0]?.url}`}
            handleClose={() => setShow(false)}
          />
        )}
      <tr>
        {channel_product?.channel_product_gallery_images
          ?.length > 0 && (
          <td className="image-box">
            <button
              type="button"
              className="btn-preview"
              onClick={() => setShow(true)}
              style={{
                backgroundImage: `url(${channel_product?.channel_product_gallery_images[0]?.url})`,
              }}></button>
          </td>
        )}
        <td style={{ paddingRight: 20 }}>
          {channel_product?.name}
        </td>
        <td width="120px">{channel_product?.sku}</td>
        <td width="80px">{quantity || "-"}</td>
        <td width="150px">
          {is_discounted ? (  
            <>
              <span className="m-r-10 old-price" style={{textDecoration: 'line-through'}}>{regular_total_price}</span>
              <span className="new-price" style={{fontWeight: 500}}>{total_price}</span>
            </>
          ) : (
            <span>{total_price}</span>
          )}
        </td>
      </tr>
    </>
  );
};

export default ImageListRow;
