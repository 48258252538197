import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dashboard, MainTable, ProductSaleStatus } from "components";
import { parseQuery } from "utils/api";
import {
  getChannelTaxonProducts,
  filterChannelTaxonProducts,
  toggleFilters,
} from "actions/channel_taxon_products";

const ChannelTaxonProductsList = ({
  channel_taxon_products,
  toggleFilters,
  getChannelTaxonProducts,
  filterChannelTaxonProducts,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getChannelTaxonProducts({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getChannelTaxonProducts({ ...query, page: undefined }, params.id);
    }
  }, [channel_taxon_products.filters.query]);

  if (channel_taxon_products.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = channel_taxon_products;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_products`;

  return (
    <Dashboard
      header={{
        icon: "products",
        title: "Produkty",
      }}>
      <MainTable
        is_loading={
          channel_taxon_products.status === "invalid" ||
          channel_taxon_products.status === "loading"
        }
        is_searching={channel_taxon_products.status === "searching"}
        filters_open={channel_taxon_products.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        head={[
          { label: "", name: "image", sortable: false, style: { width: 70 } },
          { label: "ID", name: "id" },
          { label: "SKU", name: "sku" },
          { label: "Nazwa", name: "name" },
          { label: "Producent", name: "producer", sortable: false },
          { label: "Status", name: "sales_status" },
        ]}
        renderRow={({
          id,
          name,
          sku,
          sales_status,
          product,
          channel_product_gallery_images,
        }) => (
          <tr
            key={id}
            onMouseDown={(event) => {
              if (event.button === 1) {
                window.open(`${path}/${id}`, "_blank");
              }
            }}
            onClick={() => history.push(`${path}/${id}`)}>
            <td>
              <img
                src={channel_product_gallery_images?.[0]?.url}
                alt={name}
                style={{ width: 50, height: 50, margin: "-10px 0 -15px" }}
              />
            </td>
            <td>{id}</td>
            <td>{sku || product?.sku}</td>
            <td>{name || product?.name}</td>
            <td>{product?.producer?.name}</td>
            <td>
              <ProductSaleStatus status={sales_status} />
            </td>
          </tr>
        )}
        empty_text="Brak produktów"
        filtersAction={filterChannelTaxonProducts}
        filters={[
          {
            type: "input",
            label: "Sku lub nazwa produktu",
            name: "sku_or_name",
            search_type: "matches",
          },
          {
            type: "input",
            label: "Producent",
            name: "product_producer_name",
            search_type: "matches",
          },
        ].map((item) => ({
          ...item,
          default_value: channel_taxon_products.filters.values[item.name],
        }))}
      />
    </Dashboard>
  );
};

const mapStateToProps = ({ channel_taxon_products, channel }) => ({
  channel_taxon_products,
  channel_id: channel.data.id,
});

const mapDispatchToProps = (dispatch) => ({
  getChannelTaxonProducts: (data, id) =>
    dispatch(getChannelTaxonProducts(data, id)),
  filterChannelTaxonProducts: (data) =>
    dispatch(filterChannelTaxonProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChannelTaxonProductsList));
