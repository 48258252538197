import styled from "styled-components";

const StyledEmpty = styled.div`
  padding: 20px;
  p {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
  }
`;
export default StyledEmpty;
