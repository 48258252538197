import React from "react";

const Posts = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <path
        fill='#5d78ff'
        d='M2.811,4H16.605a.811.811,0,0,1,.811.811V6.434a.811.811,0,0,1-.811.811H2.811A.811.811,0,0,1,2,6.434V4.811A.811.811,0,0,1,2.811,4Zm0,4.868h8.114a.811.811,0,0,1,.811.811V11.3a.811.811,0,0,1-.811.811H2.811A.811.811,0,0,1,2,11.3V9.68A.811.811,0,0,1,2.811,8.868Zm0,4.868h8.114a.811.811,0,0,1,.811.811v1.623a.811.811,0,0,1-.811.811H2.811A.811.811,0,0,1,2,16.171V14.548A.811.811,0,0,1,2.811,13.737Z'
        transform='translate(-0.377 -0.754)'
      />
      <rect fill='#5d78ff' opacity='0.3' width='4' height='7' rx='1' transform='translate(13 9)' />
    </svg>
  );
};

export default Posts;
