import React from "react";
import ReactSelect from "react-select";

const Select = ({ data, handleChange }) => {
  const customStyles = {
    control: styles => ({
      ...styles,
      borderColor: "#E2E5EC",
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 40,
    }),
    option: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "6px",
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    placeholder: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    multiValue: provided => ({
      ...provided,
      flexDirection: "row-reverse",
      backgroundColor: "#F3F6F9",
    }),
    multiValueLabel: provided => ({
      ...provided,
      paddingRight: "6px",
      fontSize: 11,
      color: "#595D6E",
    }),
  };
  return (
    <div className='inp-wrapper'>
      {data?.label && <label>{data.label}</label>}
      <ReactSelect
        onChange={handleChange}
        styles={customStyles}
        placeholder={"Wybierz..."}
        options={data.options || []}
      />
    </div>
  );
};

export default Select;
