import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors } from "utils/api";

export const updateChannel = values => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano kanał");

        dispatch({
          type: "CHANNEL_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        console.log(err);
        toastr.error("Błąd", "Wystąpił błąd podczas aktualizacji");
        reject(handleErrors(err.response.data.errors));
      });
  });
