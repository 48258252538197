import React from "react";
import { connect } from "react-redux";
import MarkdownIt from "markdown-it";
let md = new MarkdownIt({ html: true });

const ChannelProductDescription = ({ channel_product }) => {
  return (
    <div className='dashboard-wrapper-flex'>
      <div className='dashboard'>
        <header className='dashboard__header dashboard__header--edit'>
          <div className='icon__container'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
              <rect style={{ fill: "none" }} width='20' height='20' />
              <rect
                fill='#5d78f9'
                width='13.333'
                height='2.5'
                rx='1.25'
                transform='translate(3.333 4.167)'
              />
              <path
                fill='#5d78f9'
                opacity='0.3'
                d='M5.25,14.167H16.083a1.25,1.25,0,1,1,0,2.5H5.25a1.25,1.25,0,1,1,0-2.5ZM5.25,10h5.833a1.25,1.25,0,0,1,0,2.5H5.25a1.25,1.25,0,0,1,0-2.5Z'
                transform='translate(-0.667 -1.667)'
              />
            </svg>
          </div>
          <h2 className='dashboard__title heading'>Opis produktu</h2>
        </header>
        <div className='product-desc'>
          {channel_product?.channel_product_description_sections?.length > 0 &&
            channel_product.channel_product_description_sections.map(
              ({ id, url, type, content }) => {
                switch (type) {
                  case "ChannelProductDescriptionSection::TextImageSection":
                    return (
                      <article key={id}>
                        <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
                        <picture>
                          <img src={url} alt='' />
                        </picture>
                      </article>
                    );
                  case "ChannelProductDescriptionSection::ImageTextSection":
                    return (
                      <article key={id}>
                        <picture>
                          <img src={url} alt='' />
                        </picture>
                        <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
                      </article>
                    );
                  case "ChannelProductDescriptionSection::TextSection":
                    return (
                      <article key={id}>
                        <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
                      </article>
                    );
                  case "ChannelProductDescriptionSection::ImageSection":
                    return (
                      <article key={id}>
                        <picture>
                          <img src={url} alt='' />
                        </picture>
                      </article>
                    );
                  default:
                    return null;
                }
              }
            )}
        </div>
      </div>
    </div>
  );
};

export default connect(({ channel_product }) => ({ channel_product: channel_product.data }))(
  ChannelProductDescription
);
