import React from "react";
import { connect } from "react-redux";

import { Dashboard, MainTable } from "components";
import { Link, withRouter } from "react-router-dom";

const RelationOrders = ({
  discount_code,
  match: {
    params: { platform_id, channel_id },
  },
}) => {
  return (
    <Dashboard
      header={{
        title: "Zamówienia",
        icon: "orders",
      }}>
      <MainTable
        is_loading={false}
        is_searching={false}
        filters_open={false}
        data={discount_code?.orders || []}
        head={[
          {
            label: "Numer zamówienia",
            name: "number",
            sortable: false,
          },
          {
            label: "Data utworzenia",
            name: "created_at",
            sortable: false,
          },
          {
            label: "Łączna kwota",
            name: "total_price",
            sortable: false,
          },
          {
            label: "Status",
            name: "status",
            sortable: false,
          },
        ]}
        renderRow={({
          id,
          status,
          total_price,
          created_at,
          number,
        }) => (
          <tr key={id}>
            <td>
              <Link
                to={`/platforms/${platform_id}/channels/${channel_id}/orders/${id}`}>
                {number}
              </Link>
            </td>
            <td>{created_at}</td>
            <td>{total_price}</td>
            <td>{status}</td>
          </tr>
        )}
        empty_text="Brak zamówień"
      />
    </Dashboard>
  );
};

export default connect(({ discount_code }) => ({
  discount_code,
}))(withRouter(RelationOrders));
