import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors } from "utils/api";

export const getBanners = () => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "BANNERS_LOADING" });
    await client
      .get(`/banners`)
      .then(({ data }) => {
        dispatch({
          type: "BANNERS_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(() => {
        dispatch({ type: "BANNERS_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const addBanner = data => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/banners`, data)
      .then(({ data }) => {
        const formatted_data = apiFormatter(data);
        dispatch({
          type: "BANNER_ADD",
          data: formatted_data,
        });
        resolve(formatted_data.id);
      })
      .catch(err => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};

export const updateBanner = (id, data) => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/banners/${id}`, data)
      .then(({ data }) => {
        dispatch({
          type: "BANNER_UPDATE",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};

export const updateImage = (id, image) => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client
      .patch(`/banners/${id}`, image)
      .then(({ data }) => {
        dispatch({
          type: "BANNER_UPDATE",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        reject(handleErrors(err.response.data.errors));
      });
  });
};
