import React from "react";

const Orders = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#597dfa'
      opacity='0.3'
      d='M10.933,4.107,7.454,8.282A.822.822,0,0,1,6.191,7.229L10.3,2.3a.822.822,0,0,1,1.263,0l4.111,4.933a.822.822,0,0,1-1.263,1.053Z'
      transform='translate(-1.067 -0.356)'
    />
    <path
      fill='#597dfa'
      d='M3.1,9H17.441a.822.822,0,0,1,.8,1.022L16.495,17A2.467,2.467,0,0,1,14.1,18.867H6.442A2.467,2.467,0,0,1,4.049,17L2.305,10.022A.822.822,0,0,1,3.1,9Zm7.169,2.467a.822.822,0,0,0-.822.822v3.289a.822.822,0,1,0,1.644,0V12.289A.822.822,0,0,0,10.272,11.467Zm-4.14.112a.822.822,0,0,0-.581,1.007L6.4,15.762a.822.822,0,1,0,1.588-.426L7.139,12.16A.822.822,0,0,0,6.132,11.579Zm8.28,0a.822.822,0,0,0-1.007.581l-.851,3.177a.822.822,0,1,0,1.588.426l.851-3.177A.822.822,0,0,0,14.412,11.579Z'
      transform='translate(-0.405 -1.6)'
    />
  </svg>
);
export default Orders;
