import React, { useState } from "react";
import { ModalPreview } from "expano-components";

const ImageListRow = ({ id, filename, file, url }) => {
  const [show, setShow] = useState(false);
  const image = file?.url || url;
  return (
    <>
      {show && <ModalPreview src={`${image}`} handleClose={() => setShow(false)} />}
      <tr>
        <td className="image-box">
          <button
            type="button"
            className="btn-preview"
            onClick={() => setShow(true)}
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></button>
        </td>
        <td>{id}</td>
        <td>{filename || '-'}</td>
      </tr>
    </>
  );
};

export default ImageListRow;
