import React from "react";
import { Button, Input, Checkbox } from "expano-components";
import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";
import { updateChannelTaxon } from "actions/channel_taxons";
import { AsyncSelect, Dashboard } from "components";
import getCategories from "../../../../const/getCategories";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <AsyncSelect {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const BasicForm = ({
  channel_taxon,
  pristine,
  handleSubmit,
  updateChannelTaxon,
  change,
}) => {
  const submit = (values) => updateChannelTaxon(channel_taxon?.id, values);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{ title: "Podstawowe informacje", icon: "info" }}
        footer={{
          buttons: (
            <Button
              disabled={pristine}
              type="save"
              text="Zapisz"
              onClick={handleSubmit(submit)}
            />
          ),
        }}>
        <div noValidate className="dashboard__form">
          <Field
            name="name"
            component={InputField}
            label="Nazwa kategorii"
            type="text"
          />

          <div className="form-row grid-2">
            <Field
              name="name"
              component={InputField}
              label="Nazwa kategorii"
              type="hidden"
            />
            <Field
              label="Kategoria nadrzędna"
              name="parent_id"
              component={SelectField}
              getData={getCategories}
              change={change}
              initValue={
                channel_taxon?.parent
                  ? {
                      label: channel_taxon.parent.name,
                      value: channel_taxon.parent.id,
                    }
                  : { label: "Brak", value: null }
              }
            />
            <div class="form-group new-checkbox">
              <div class="inp-wrapper">
                <p class="label-form">Mega menu</p>
                <Field
                  component={CheckField}
                  name="show_in_mega_menu"
                  label="Wyświetl"
                />
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updateChannelTaxon: (id, data) => dispatch(updateChannelTaxon(id, data)),
});
export default connect(
  ({ channel_taxon }) => ({
    channel_taxon,
    initialValues: {
      name: channel_taxon?.name,
      parent_id: channel_taxon?.parent?.id,
      show_in_mega_menu: channel_taxon?.show_in_mega_menu,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-channel-taxon-basic",
    enableReinitialize: true,
  })(BasicForm)
);
