const nav_data = ({ platform_id, channel_id }) =>
  platform_id && channel_id
    ? {
        platforms: [
          {
            items: [
              {
                name: `Kategorie platformy`,
                path: `/platforms/${platform_id}/platform_taxons`,
                icon: `category`,
                id: 1,
              },
            ],
          },
        ],
        channels: [
          {
            name: `Ustawiena`,
            items: [
              {
                name: `Strona główna`,
                path: `/platforms/${platform_id}/channels/${channel_id}/home_page`,
                icon: `home_page`,
                id: 2,
              },
              {
                name: `Dane sklepu`,
                path: `/platforms/${platform_id}/channels/${channel_id}/edit`,
                icon: `channel_settings`,
                id: 3,
              },
              {
                name: `Strony statyczne`,
                path: `/platforms/${platform_id}/channels/${channel_id}/pages`,
                icon: `static_pages`,
                id: 4,
              },
            ],
          },
          {
            name: `Sklep`,
            items: [
              {
                name: `Klienci`,
                path: `/platforms/${platform_id}/channels/${channel_id}/clients`,
                icon: `producer`,
                id: 5,
              },
              {
                name: `Zamówienia`,
                path: `/platforms/${platform_id}/channels/${channel_id}/orders`,
                icon: `orders`,
                id: 6,
              },
              {
                name: `Kategorie`,
                path: `/platforms/${platform_id}/channels/${channel_id}/channel_taxons`,
                icon: `product_categories`,
                id: 12,
              },
              {
                name: `Producenci`,
                path: `/platforms/${platform_id}/channels/${channel_id}/channel_producers`,
                icon: `product_categories`,
                id: 13,
              },
              {
                name: `Kolekcje`,
                path: `/platforms/${platform_id}/channels/${channel_id}/collections`,
                icon: `product_categories`,
                id: 15,
              },
              {
                name: `Produkty`,
                path: `/platforms/${platform_id}/channels/${channel_id}/channel_products`,
                icon: `product`,
                id: 14,
              },
            ],
          },
          {
            name: `Marketing`,
            items: [
              {
                name: `Promocje`,
                path: `/platforms/${platform_id}/channels/${channel_id}/discounts`,
                icon: `discounts`,
                id: 16,
              },
              {
                name: `Kody rabatowe`,
                path: `/platforms/${platform_id}/channels/${channel_id}/discount_codes`,
                icon: `discount_codes`,
                id: 17,
              },
            ],
          },
          {
            name: `Blog`,
            items: [
              {
                name: `Wpisy`,
                path: `/platforms/${platform_id}/channels/${channel_id}/articles`,
                icon: `post`,
                id: 9,
              },
              {
                name: `Autorzy`,
                path: `/platforms/${platform_id}/channels/${channel_id}/authors`,
                icon: `author`,
                id: 10,
              },
            ],
          },
          {
            name: `Checkout`,
            items: [
              {
                name: `Dostawa`,
                path: `/platforms/${platform_id}/channels/${channel_id}/shipping_methods`,
                icon: `shipment`,
                id: 11,
              },
              {
                name: `Płatności`,
                path: `/platforms/${platform_id}/channels/${channel_id}/payment_methods`,
                icon: `payment`,
                id: 12,
              },
            ],
          },
        ],
      }
    : null;
export default nav_data;
