import React from "react";

const Logotype = ({name}) => {
  return (
    <div className='sidebar__logo'>
      <svg xmlns='http://www.w3.org/2000/svg' width='146' height='16' viewBox='0 0 146 16'>
        <g id='Group_549' data-name='Group 549' transform='translate(3910 3167)'>
          <g id='Group_548' data-name='Group 548' transform='translate(-684 -98)'>
            <g id='Group_547' data-name='Group 547' transform='translate(-5283.442 -4532.987)'>
              <g id='Group_183' data-name='Group 183' transform='translate(2092.021 1465.916)'>
                <path
                  id='Path_105'
                  data-name='Path 105'
                  d='M2268.266,1479.114a.824.824,0,0,1-.823-.823V1475a.824.824,0,0,1,.823-.823h5.763c1.519,0,3.293-.647,3.293-2.47s-1.774-2.47-3.293-2.47h-5.763a.823.823,0,1,1,0-1.647h5.763c2.955,0,4.94,1.655,4.94,4.117s-1.985,4.117-4.94,4.117h-4.94v2.47A.823.823,0,0,1,2268.266,1479.114Z'
                  transform='translate(-2267.442 -1467.587)'
                  fill='#fff'
                />
              </g>
              <g id='Group_186' data-name='Group 186' transform='translate(2075.555 1465.916)'>
                <g id='Group_184' data-name='Group 184' transform='translate(0 0)'>
                  <path
                    id='Path_106'
                    data-name='Path 106'
                    d='M2179.792,1479.113a.82.82,0,0,1-.535-.2l-11.526-9.88a.823.823,0,1,1,1.072-1.25l11.526,9.88a.823.823,0,0,1-.536,1.449Z'
                    transform='translate(-2167.442 -1467.587)'
                    fill='#fff'
                  />
                </g>
                <g id='Group_185' data-name='Group 185' transform='translate(0 0)'>
                  <path
                    id='Path_107'
                    data-name='Path 107'
                    d='M2168.266,1479.113a.823.823,0,0,1-.536-1.449l11.526-9.88a.823.823,0,1,1,1.071,1.25l-11.526,9.88A.824.824,0,0,1,2168.266,1479.113Z'
                    transform='translate(-2167.442 -1467.587)'
                    fill='#fff'
                  />
                </g>
              </g>
              <g id='Group_190' data-name='Group 190' transform='translate(2057.442 1465.916)'>
                <g id='Group_187' data-name='Group 187' transform='translate(3.293)'>
                  <path
                    id='Path_108'
                    data-name='Path 108'
                    d='M2088.145,1469.234h-9.88a.823.823,0,0,1,0-1.647h9.88a.823.823,0,1,1,0,1.647Z'
                    transform='translate(-2077.442 -1467.587)'
                    fill='#fff'
                  />
                </g>
                <g id='Group_188' data-name='Group 188' transform='translate(3.293 9.88)'>
                  <path
                    id='Path_109'
                    data-name='Path 109'
                    d='M2088.146,1529.234h-9.88a.823.823,0,0,1,0-1.646h9.88a.823.823,0,0,1,0,1.646Z'
                    transform='translate(-2077.442 -1527.587)'
                    fill='#fff'
                  />
                </g>
                <g id='Group_189' data-name='Group 189' transform='translate(0 4.94)'>
                  <path
                    id='Path_110'
                    data-name='Path 110'
                    d='M2068.145,1499.234h-9.88a.823.823,0,1,1,0-1.647h9.88a.823.823,0,1,1,0,1.647Z'
                    transform='translate(-2057.442 -1497.587)'
                    fill='#fff'
                  />
                </g>
              </g>
              <g id='Group_191' data-name='Group 191' transform='translate(2121.661 1465.916)'>
                <path
                  id='Path_111'
                  data-name='Path 111'
                  d='M2458.969,1479.113a.823.823,0,0,1-.559-.219l-9.321-8.6v8a.823.823,0,1,1-1.646,0v-9.88a.823.823,0,0,1,1.381-.6l9.322,8.6v-8a.823.823,0,1,1,1.646,0v9.88a.824.824,0,0,1-.823.823Z'
                  transform='translate(-2447.442 -1467.587)'
                  fill='#fff'
                />
              </g>
              <g id='Group_192' data-name='Group 192' transform='translate(2136.481 1465.587)'>
                <path
                  id='Path_112'
                  data-name='Path 112'
                  d='M2544.029,1477.772c-3.632,0-6.587-2.364-6.587-5.269v-1.647c0-2.905,2.955-5.269,6.587-5.269s6.586,2.364,6.586,5.269v1.647C2550.615,1475.408,2547.661,1477.772,2544.029,1477.772Zm0-10.539c-2.678,0-4.94,1.659-4.94,3.623v1.647c0,1.963,2.262,3.623,4.94,3.623s4.939-1.659,4.939-3.623v-1.647C2548.968,1468.893,2546.706,1467.234,2544.029,1467.234Z'
                  transform='translate(-2537.442 -1465.587)'
                  fill='#fff'
                />
              </g>
              <g id='Group_195' data-name='Group 195' transform='translate(2104.371 1465.916)'>
                <g id='Group_193' data-name='Group 193' transform='translate(0)'>
                  <path
                    id='Path_113'
                    data-name='Path 113'
                    d='M2356.439,1479.114a.822.822,0,0,1-.686-.366l-5.9-8.853-5.9,8.853a.823.823,0,0,1-1.37-.914l6.587-9.88a.823.823,0,0,1,1.37,0l6.587,9.88a.823.823,0,0,1-.685,1.28Z'
                    transform='translate(-2342.442 -1467.587)'
                    fill='#fff'
                  />
                </g>
                <g id='Group_194' data-name='Group 194' transform='translate(2.305 6.587)'>
                  <path
                    id='Path_114'
                    data-name='Path 114'
                    d='M2365.663,1509.234h-8.4a.823.823,0,1,1,0-1.646h8.4a.823.823,0,1,1,0,1.646Z'
                    transform='translate(-2356.442 -1507.587)'
                    fill='#fff'
                  />
                </g>
              </g>
            </g>
            <rect
              id='Rectangle_431'
              className='sidebar__logo__background'
              data-name='Rectangle 431'
              width='43'
              height='16'
              rx='4'
              transform='translate(-3123 -3069)'
            />
            <text
              id='ROOT'
              transform='translate(-3117 -3057)'
              fill='#fff'
              fontSize='10'
              fontFamily='Poppins-SemiBold, Poppins'
              fontWeight='600'
              letterSpacing='0.081em'
            >
              <tspan x='0' y='0'>
                {name}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logotype;
