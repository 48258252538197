import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Main, PageLoader } from "components";
import { getArticle } from "actions/articles";
import { HrefLangs, BasicInfo, Content, Seo } from "./components";

const ArticleEdit = ({ article, getArticle, channel_id, match: { params }, pristine, history }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/blog/articles`;

  useEffect(() => {
    getArticle(params.id);
  }, []);

  if (!article || article.status === "invalid" || article.status === "loading")
    return <PageLoader />;

  if (article.status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        {
          name: "Wpisy",
          path,
          icon: "article",
        },
        { name: article?.title },
      ]}
    >
      <Helmet>
        <style>{`.image-uploader__box{ width: 100%; height: 200px; } .image-uploader{padding: 0;} .image-uploader__box{margin: 0; transition: none;}`}</style>
      </Helmet>

      <BasicInfo />
      <div className='dashboard-wrapper-flex'>
        <div className='column-2'>
          <Content />
          <HrefLangs />
        </div>
        <div className='column-1'>
          <Seo />
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ article, channel }) => ({
  article,
  channel_id: channel.data.id,
});

const mapDispatchToProps = dispatch => ({
  getArticle: id => dispatch(getArticle(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEdit);
