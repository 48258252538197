import styled from "styled-components";

const StyledImageUploader = styled.div`
  max-width: 135px;
  margin: 0 auto;
  .uploader-box {
    text-align: center;
    &__title {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 15px;
      color: #646c9a;
    }
    .image-holder,
    .image-placeholder {
      margin: 0 auto -10px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .image-holder {
      background-position: center;
      background-size: cover;
    }
    .image-placeholder {
      position: relative;
      background-color: #f1f2f8;
      &::before {
        content: "";
        width: 23px;
        height: 23px;
        position: absolute;
        top: 22px;
        left: 50%;
        border-radius: 50%;
        transform: translateX(-50%);
        background-color: #ffffff;
      }
      &::after {
        content: "";
        width: 53px;
        height: 23px;
        position: absolute;
        top: 52px;
        left: 50%;
        border-top-left-radius: 106px;
        border-top-right-radius: 106px;
        transform: translateX(-50%);
        background-color: #ffffff;
      }
    }
    .btn-upload {
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: rgba(145, 160, 218, 0.3);
      transition: 0.2s;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #91a0da;
      }
      &::before {
        width: 10px;
        height: 2px;
      }
      &::after {
        width: 2px;
        height: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
      &.rotated {
        transform: rotate(45deg);
      }
    }
  }
`;
export default StyledImageUploader;
