import React from "react";
import { Button, Input } from "expano-components";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { createPlatformTaxon } from "actions/platform_taxons";
import { AsyncSelect } from "components";
import { client_platform } from "utils/api";

const InputField = props => <Input {...props} />;
const SelectField = props => <AsyncSelect {...props} />;

const BasicData = ({ submitting, pristine, handleSubmit, createPlatformTaxon, change }) => {
  const submit = values => {
    createPlatformTaxon(values);
  };
  const loadOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client_platform.get(`/platform_taxons?page=1&per_page=20${q ? `&q[name_matches]=%25${q}%25` : ""}`);
        const data = categories.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  return (
    <form className='dashboard-wrapper-flex' onSubmit={handleSubmit(submit)}>
      <div className='dashboard'>
        <header className='dashboard__header dashboard__header--edit'>
          <div className='icon__container'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
              version='1.1'
              className='kt-svg-icon'
            >
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <rect x='0' y='0' width='24' height='24' />
                <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                <rect className='svg-rect' fill='#000000' x='11' y='10' width='2' height='7' rx='1' />
                <rect className='svg-rect' fill='#000000' x='11' y='7' width='2' height='2' rx='1' />
              </g>
            </svg>
          </div>
          <h2 className='dashboard__title heading'>Podstawowe dane</h2>
          <Button type='save' text='Zapisz' disabled={pristine || submitting} onClick={handleSubmit(submit)} />
        </header>
        <div noValidate className='dashboard__form'>
          <div className='form-row grid-2'>
            <Field component={InputField} name='name' label='Nazwa' />
            <Field label='Kategoria nadrzędna' name='parent_id' component={SelectField} getData={loadOptions} change={change} />
          </div>
          <div className='form-row grid-2'>
            <Field component={InputField} name='slug' label='Slug' />
          </div>
        </div>
      </div>
    </form>
  );
};
const mapDispatchToProps = dispatch => ({
  createPlatformTaxon: data => dispatch(createPlatformTaxon(data)),
});
export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "add-platform-taxon",
    enableReinitialize: false,
  })(BasicData)
);
