import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Dashboard } from "components";
import { Field, reduxForm, reset } from "redux-form";
import { updateChannelTaxon } from "actions/channel_taxons";

const InputField = props => <Input {...props} />;

const Seo = ({ data, submitting, pristine, handleSubmit, updateChannelTaxon, dispatch }) => {
  const submit = values => updateChannelTaxon(data.id, values);
  return (
    <Dashboard
      header={{ title: "SEO", icon: "info" }}
      footer={{
        buttons: [
          !pristine && (
            <Button type='cancel' onClick={() => dispatch(reset("edit-category-seo"))} />
          ),
          <Button type='save' text='Zapisz' disabled={pristine} onClick={handleSubmit(submit)} />,
        ],
      }}
    >
      <form onSubmit={handleSubmit(submit)}>
        <div noValidate className='dashboard__form'>
          <Field
            name='show_in_mega_menu'
            label='Czy pokazać kategorię w mega menu?'
            component={InputField}
          />
        </div>
      </form>
    </Dashboard>
  );
};
const mapDispatchToProps = dispatch => ({
  updateChannelTaxon: (id, data) => dispatch(updateChannelTaxon(id, data)),
});
export default connect(
  ({ channel_taxon: { data } }) => ({
    data,
    initialValues: {
      meta_title: data?.meta_title,
      meta_description: data?.meta_description,
      slug: data?.slug,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-category-seo",
    enableReinitialize: true,
  })(Seo)
);
