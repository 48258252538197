import React from "react";

const Shipping = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M17.648,6.159a.842.842,0,0,1,.5.769V14.3a.842.842,0,0,1-.433.736l-6.733,3.74a.842.842,0,0,1-.817,0l-6.733-3.74A.842.842,0,0,1,3,14.3V6.928a.842.842,0,0,1,.5-.769l6.733-2.992a.842.842,0,0,1,.684,0Z'
      transform='translate(-0.475 -0.49)'
    />
    <path
      fill='#5d78ff'
      d='M13.8,4.224,7.6,7.669V11.53l1.683.842V8.659L15.725,5.08Z'
      transform='translate(-1.204 -0.669)'
    />
  </svg>
);

export default Shipping;
