import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "ARTICLES_TOGGLE_FILTERS" });
};

export const filterArticles = data => async dispatch => {
  dispatch({ type: "ARTICLES_SET_FILTERS_QUERY", data });
};

export const getArticles = ({ page = 1, per_page = 10, ...rest }) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().articles;
  status === "invalid"
    ? dispatch({ type: "ARTICLES_LOADING" })
    : dispatch({ type: "ARTICLES_SEARCHING" });
  await client
    .get(`/articles?include=author&${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "ARTICLES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "ARTICLES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getArticle = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "ARTICLE_LOADING" });
    await client
      .get(`/articles/${id}?include=author,article_hreflangs`)
      .then(({ data }) => {
        dispatch({
          type: "ARTICLE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "ARTICLE_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const updateArticle = (id, article) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/articles/${id}?include=author,article_hreflangs`, article)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano artykuł");
        dispatch({
          type: "ARTICLE_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
        dispatch({ type: "ARTICLE_FAILURE" });
        reject();
      });
  });

export const deleteArticle = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/articles/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createArticle = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/articles`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano stronę");
        resolve(data);
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(err.response.data.errors));
      });
  });

export const deleteHreflang = id => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .delete(`/article_hreflangs/${id}`)
      .then(() => {
        toastr.success("Sukces", "Usunięto hreflang");
        dispatch({
          type: "REMOVE_HREFLANG",
          id,
        });
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });

export const updateHreflang = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .patch(`/article_hreflangs/${id}`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano hreflang");
        dispatch({
          type: "UPDATE_HREFLANG",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        console.log(err);
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(err?.response?.data?.errors || []));
      });
  });

export const createHreflang = data => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/article_hreflangs`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano hreflang");
        dispatch({
          type: "ADD_HREFLANG",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(err.response.data.errors));
      });
  });
