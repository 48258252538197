import React from "react";
import { Dashboard } from "components";

const LogsPanel = () => {
  return (
    <Dashboard header={{ title: "Logi", icon: "logs" }}>
      {/* <ul className="logs-list">
        <li className="logs-list__item">
          <p className="title">21.03.2010 14:34 API</p>
          <p>Utworzono zamówienie: Zamówił: Konrad Igancyk</p>
        </li>
        <li className="logs-list__item">
          <p className="title">21.03.2010 14:34 API</p>
          <p>Utworzono zamówienie: Zamówił: Konrad Igancyk</p>
        </li>
        <li className="logs-list__item">
          <p className="title">21.03.2010 14:34 API</p>
          <p>Utworzono zamówienie: Zamówił: Konrad Igancyk</p>
        </li>
        <li className="logs-list__item">
          <p className="title">21.03.2010 14:34 API</p>
          <p>Utworzono zamówienie: Zamówił: Konrad Igancyk</p>
        </li>
      </ul> */}
    </Dashboard>
  );
};

export default LogsPanel;
