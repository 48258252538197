import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Button, Input } from "expano-components";
import { Main, Dashboard, ImageUploader } from "components";
import { createAuthor } from "actions/authors";
import { toBase64 } from "utils/helpers";

const InputField = props => <Input {...props} />;

const AuthorNew = ({ handleSubmit, pristine, history, change, match: { params } }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/authors`;
  const [image, addImage] = useState(null);
  const submit = values => {
    return createAuthor({ ...values })
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano autora");
        history.push(`${path}/${data.id}`);
      })
      .catch(err => {
        console.log(err);
        toastr.error("Błąd", "Wystąpił błąd");
        throw new SubmissionError(err);
      });
  };

  const handleUploadImage = file => {
    return new Promise(async (resolve, reject) => {
      const path = await toBase64(file);
      addImage({ path });
      change("image_data", path);
      resolve();
    });
  };

  return (
    <Main
      breadcrumbs={[{ name: "Autorzy", path, icon: "author" }, { name: "Dodaj nowego autora" }]}
    >
      <form onSubmit={handleSubmit(submit)} noValidate>
        <Dashboard
          header={{ icon: "info", title: "Podstawowe informacje" }}
          footer={{
            buttons: (
              <>
                <Button type='cancel' onClick={() => history.push(path)} />
                <Button
                  disabled={pristine}
                  type='save'
                  text='Zapisz'
                  onClick={handleSubmit(submit)}
                />
              </>
            ),
          }}
        >
          <div className='wrapper'>
            <div className='block'>
              <Field name='first_name' label='Imię' component={InputField} type='text' />
              <Field name='last_name' label='Nazwisko' component={InputField} type='text' />
              <Field name='position' label='Stanowisko' component={InputField} type='text' />
            </div>
            <div className='block'>
              <Field name='image_data' component={InputField} type='hidden' />
              <ImageUploader
                title='Dodaj zdjęcie autora'
                image={image}
                limit={1}
                handleUpload={handleUploadImage}
              />
            </div>
          </div>
        </Dashboard>
      </form>
    </Main>
  );
};

export default reduxForm({ form: "create-author", enableReinitialize: true })(
  withRouter(AuthorNew)
);
