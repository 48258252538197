import React from "react";
import { Main } from "components";
import BasicData from "./components/BasicData";

const CollectionNew = ({ match: { params } }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/collections`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Kolekcje",
          path,
        },
        {
          name: "Dodaj kolekcję",
        },
      ]}
    >
      <BasicData path={path} />
    </Main>
  );
};

export default CollectionNew;
