import React from "react";

const Payment = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <rect
      fill='#5d78ff'
      opacity='0.3'
      width='1.683'
      height='3.366'
      rx='0.842'
      transform='translate(9.678 1.683)'
    />
    <rect
      fill='#5d78ff'
      opacity='0.3'
      width='1.683'
      height='4.208'
      rx='0.842'
      transform='translate(9.678 13.465)'
    />
    <path
      fill='#5d78ff'
      d='M14.193,7.436a2.468,2.468,0,0,0-.919-.712,2.594,2.594,0,0,0-1.1-.264,3.261,3.261,0,0,0-.552.048,1.424,1.424,0,0,0-.5.184,1.245,1.245,0,0,0-.376.352.937.937,0,0,0-.152.552.89.89,0,0,0,.12.48,1.067,1.067,0,0,0,.352.336,3.135,3.135,0,0,0,.552.264q.32.12.72.248.576.192,1.2.424a4.485,4.485,0,0,1,1.135.616,3.231,3.231,0,0,1,.847.951,2.74,2.74,0,0,1,.336,1.415,3.7,3.7,0,0,1-.36,1.687,3.364,3.364,0,0,1-.967,1.175,4.145,4.145,0,0,1-1.391.688,5.85,5.85,0,0,1-1.615.224,6.669,6.669,0,0,1-2.35-.424,4.98,4.98,0,0,1-1.887-1.207l1.791-1.823a3.309,3.309,0,0,0,1.1.855,2.973,2.973,0,0,0,1.351.344,2.719,2.719,0,0,0,.592-.064,1.476,1.476,0,0,0,.5-.208,1.041,1.041,0,0,0,.344-.384,1.208,1.208,0,0,0,.128-.576.911.911,0,0,0-.16-.544,1.562,1.562,0,0,0-.456-.408,3.67,3.67,0,0,0-.736-.336q-.44-.152-1-.328a9.55,9.55,0,0,1-1.063-.416,3.559,3.559,0,0,1-.927-.616,2.851,2.851,0,0,1-.656-.911,3.09,3.09,0,0,1-.248-1.3,3.2,3.2,0,0,1,.384-1.615,3.336,3.336,0,0,1,1.007-1.1,4.327,4.327,0,0,1,1.407-.632,6.38,6.38,0,0,1,1.583-.2,5.877,5.877,0,0,1,1.959.352A5.043,5.043,0,0,1,15.935,5.6Z'
      transform='translate(-1.154 -0.666)'
    />
  </svg>
);

export default Payment;
