import React from "react";

const Producers = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      opacity='0.3'
      fill='#5d78ff'
      d='M4.833,5H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,5Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z'
      transform='translate(-0.667 -0.833)'
    />
    <path
      fill='#5d78ff'
      d='M4.833,9H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,9Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,1,1,0-1.667Z'
      transform='translate(-0.667 -1.5)'
    />
  </svg>
);

export default Producers;
