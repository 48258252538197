import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getArticles, filterArticles, toggleFilters } from "actions/articles";

const ArticlesList = ({ articles, toggleFilters, getArticles, filterArticles, history, location: { search }, match: { params } }) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getArticles({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getArticles({ ...query, page: undefined });
    }
  }, [articles.filters.query]);

  if (articles.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = articles;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/articles`;
  return (
    <Main breadcrumbs={[{ name: "Artykuły" }]}>
      <Dashboard
        header={{
          icon: "posts",
          title: "Artykuły",
          aside: [<Button type='add' text='Dodaj artykuł' path={`${path}/new`} />],
        }}
      >
        <MainTable
          is_loading={articles.status === "invalid" || articles.status === "loading"}
          is_searching={articles.status === "searching"}
          filters_open={articles.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Tytuł wpisu", name: "title" },
            { label: "Autor", name: "authors_last_name", sortable: false },
            { label: "Data publikacji", name: "meta_created_at" },
            { label: "Data aktualizacji", name: "meta_updated_at" },
          ]}
          renderRow={({ id, category, author, meta_created_at, meta_updated_at, title }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{title}</td>
              <td>
                {author?.first_name} {author?.last_name}
              </td>
              <td>{meta_created_at && moment(meta_created_at).format("DD-MM-YYYY")}</td>
              <td>{meta_updated_at && moment(meta_updated_at).format("DD-MM-YYYY")}</td>
            </tr>
          )}
          empty_text='Brak artykułów'
          filtersAction={filterArticles}
          filters={[
            {
              type: "input",
              label: "Tytuł",
              name: "title",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Autorzy",
              name: "author_first_name_or_author_last_name",
              search_type: "matches",
            },
            {
              type: "date",
              label: "Data utworzenia",
              name: "meta_created_at",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data aktualizacji",
              name: "meta_updated_at",
              search_type: "gt_eq",
            },
          ].map(item => ({ ...item, default_value: articles.filters.values[item.name] }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getArticles: data => dispatch(getArticles(data)),
  filterArticles: data => dispatch(filterArticles(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(({ articles }) => ({ articles }), mapDispatchToProps)(ArticlesList);