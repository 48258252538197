import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getCollections, filterCollections, toggleFilters } from "actions/collections";

const CollectionsList = ({
  collections,
  toggleFilters,
  getCollections,
  filterCollections,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getCollections({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getCollections({ ...query, page: undefined });
    }
  }, [collections.filters.query]);

  if (collections.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = collections;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/collections`;
  return (
    <Main breadcrumbs={[{ name: "Kolekcje" }]}>
      <Dashboard
        header={{
          icon: "producers",
          title: "Kolekcje",
          aside: <Button type='add' text='Dodaj kolekcję' path={`${path}/new`} />,
        }}
      >
        <MainTable
          is_loading={collections.status === "invalid" || collections.status === "loading"}
          is_searching={collections.status === "searching"}
          filters_open={collections.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id", style: { width: 100 } },
            { label: "Nazwa kolekcji", name: "name" },
            { label: "Slug", name: "slug" },
            { label: "Status", name: "status" },
          ]}
          renderRow={({ id, status, name, slug }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{id}</td>
              <td>{name}</td>
              <td>{slug}</td>
              <td>{status === "active" ? "Aktywny" : "Nieaktywny"}</td>
            </tr>
          )}
          empty_text='Brak kolekcji'
          filtersAction={filterCollections}
          filters={[
            {
              type: "input",
              label: "Nazwa kolekcji",
              name: "name",
              search_type: "matches",
            },
            {
              type: "multi-select",
              label: "Status",
              search_type: "in",
              options: [
                { value: 0, label: "Aktywny" },
                { value: 1, label: "Nieaktywny" },
              ],
              name: "status",
            },
          ].map(item => ({ ...item, default_value: collections.filters.values[item.name] }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getCollections: data => dispatch(getCollections(data)),
  filterCollections: data => dispatch(filterCollections(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(({ collections }) => ({ collections }), mapDispatchToProps)(CollectionsList);
