import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { updateClient } from "actions/clients";
import { Dashboard } from "components";

const InputField = props => <Input {...props} />;

const ClientEditBasicData = ({ id, change, handleSubmit, pristine, updateClient }) => {
  const submit = ({ email, ...values }) =>
    updateClient(id, values).then(() => {
      change("password", null);
    });
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Podstawowe dane", icon: "client" }}
        footer={{
          buttons: (
            <Button type='save' text='Zapisz' onClick={handleSubmit(submit)} disabled={pristine} />
          ),
        }}
      >
        <div className='dashboard__form'>
          <div className='row'>
            <div className='box-3'>
              <Field name='first_name' label='Imię' component={InputField} type='text' />
            </div>
            <div className='box-3'>
              <Field name='last_name' label='Nazwisko' component={InputField} type='text' />
            </div>
            <div className='box-3'>
              <Field
                name='email'
                disabled={true}
                label='Adres email'
                component={InputField}
                type='text'
              />
            </div>
            <div className='box-3'>
              <Field name='password' label='Hasło' component={InputField} type='password' />
              <p style={{ fontSize: 9, lineHeight: "13px", marginTop: "-20px", color: "#DD3B00" }}>
                Jeśli nie chcesz zmieniać, nie uzupełniaj tego pola.
              </p>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  updateClient: (id, data) => dispatch(updateClient(id, data)),
});

export default connect(
  ({ client }) => ({
    id: client.data?.id,
    initialValues: {
      first_name: client.data?.first_name,
      last_name: client.data?.last_name,
      email: client.data?.email,
    },
  }),
  mapDispatchToProps
)(reduxForm({ form: "edit-client-basic-data", enableReinitialize: true })(ClientEditBasicData));
