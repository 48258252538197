import React from "react";

const Description = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <rect fill='#5d78f9' width='13.333' height='2.5' rx='1.25' transform='translate(3.333 4.167)' />
    <path
      fill='#5d78f9'
      opacity='0.3'
      d='M5.25,14.167H16.083a1.25,1.25,0,1,1,0,2.5H5.25a1.25,1.25,0,1,1,0-2.5ZM5.25,10h5.833a1.25,1.25,0,0,1,0,2.5H5.25a1.25,1.25,0,0,1,0-2.5Z'
      transform='translate(-0.667 -1.667)'
    />
  </svg>
);

export default Description;
