import {toastr} from "react-redux-toastr";
import {client, apiFormatter, handleErrors} from "utils/api";

export const getShippingMethods = () => async (dispatch) => {
  dispatch({type: "SHIPPING_METHODS_LOADING"});
  await client
    .get(`/shipping_methods?include=speditor`)
    .then(({data}) => {
      dispatch({
        type: "SHIPPING_METHODS_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch((err) => {
      console.log(err)
      dispatch({type: "SHIPPING_METHODS_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const updateShippingMethod = (id, data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/shipping_methods/${id}?include=speditor`, data)
      .then(({data}) => {
        dispatch({
          type: "SHIPPING_METHOD_UPDATE",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};
