import React from "react";
import Loader from "react-loader-spinner";

import StyledLoading from "./styles";

const Loading = () => {
  return (
    <StyledLoading>
      <Loader type='Watch' color='#2959F8' height={40} width={40} />
    </StyledLoading>
  );
};

export default Loading;
