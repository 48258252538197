import React from "react";
import {
  Authors,
  Description,
  Contact,
  Wallet,
  Info,
  Posts,
  Edit,
  Page,
  Clients,
  Products,
  Orders,
  Categories,
  Client,
  OrdersHistory,
  Address,
  Logs,
  OrderStatus,
  OrderSummary,
  Payment,
  Shipping,
  Producers,
  Discounts,
  DiscountCodes,
} from "icons";

import StyledHeader from "./styles";

const getIcon = icon => {
  switch (icon) {
    case "authors":
      return <Authors />;
    case "info":
      return <Info />;
    case "posts":
      return <Posts />;
    case "edit":
      return <Edit />;
    case "page":
      return <Page />;
    case "contact":
      return <Contact />;
    case "wallet":
      return <Wallet />;
    case "description":
      return <Description />;
    case "clients":
      return <Clients />;
    case "products":
      return <Products />;
    case "orders":
      return <Orders />;
    case "categories":
      return <Categories />;
    case "client":
      return <Client />;
    case "orders_history":
      return <OrdersHistory />;
    case "address":
      return <Address />;
    case "logs":
      return <Logs />;
    case "order_status":
      return <OrderStatus />;
    case "order_summary":
      return <OrderSummary />;
    case "payment":
      return <Payment />;
    case "shipping":
      return <Shipping />;
    case "producers":
      return <Producers />;
    case "discounts":
      return <Discounts />;
    case "discount_codes":
      return <DiscountCodes />;

    default:
      return null;
  }
};

const Header = ({ icon, title, aside }) => {
  return (
    <StyledHeader>
      <div className='box'>
        <div className='title-wrapper'>
          {getIcon(icon)}
          <h2 className='title'>{title}</h2>
        </div>
      </div>
      <div className='box'>{aside}</div>
    </StyledHeader>
  );
};

export default Header;
