import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalDelete } from "expano-components";
import { PageLoader, Main } from "components";
import { getDiscount, deleteDiscount } from "actions/discounts";
import { BasicData, RelationTypes } from "./components";

const DiscountEdit = ({ getDiscount, discount, history, match: { params } }) => {
  const [is_delete_modal_open, handleOpenDeleteModal] = useState(false);
  useEffect(() => {
    getDiscount(params.id);
  }, []);
  if (!discount || discount.status === "invalid" || discount.status === "loading")
    return <PageLoader color='#2959F8' />;

  if (discount.status === "failure") {
    return <p>Error</p>;
  }
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discounts`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Promocje",
          path,
        },
        {
          name: discount?.name,
        },
      ]}
      actions={[
        {
          label: "Usuń promocję",
          icon: "delete",
          action: () => handleOpenDeleteModal(true),
        },
      ]}
    >
      {is_delete_modal_open && (
        <ModalDelete
          name={discount?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() =>
            deleteDiscount(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push(path);
            })
          }
        />
      )}
      <BasicData />
      <RelationTypes />
    </Main>
  );
};

export default connect(
  ({ discount }) => ({
    discount,
  }),
  dispatch => ({
    getDiscount: id => dispatch(getDiscount(id)),
  })
)(DiscountEdit);
