import React from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";

const AsyncSelect = ({
  isDisabled,
  change,
  getData,
  label,
  meta,
  input: { name },
  initValue,
  choosedValues = [],
  isMulti = false,
}) => {
  const isError = meta && meta.touched && meta.invalid;
  const customStyles = {
    control: styles => ({
      ...styles,
      borderColor: isError ? "#ff0000" : "#E2E5EC",
    }),
    option: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "4px",
    }),
    multiValue: styles => ({
      ...styles,
      borderRadius: "4px",
      backgroundColor: "rgba(93, 120, 249, .3)",
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: "rgba(93, 120, 249, 1)",
      maxWidth: '200px'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: "rgba(93, 120, 249, 1)",
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    placeholder: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group ${isError ? "error" : ""}`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          onChange={item => {
            isMulti ? change(name, !!item ? item.map(({ value }) => value).join(",") : item) : change(name, item.value);
          }}
          styles={customStyles}
          defaultOptions
          cacheOptions
          isMulti={isMulti}
          isDisabled={isDisabled}
          placeholder={"Wybierz..."}
          theme={theme => ({
            ...theme,
            fontFamily: "Poppins",
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: "#E2E5EC",
            },
            error: meta && meta.touched && meta.invalid,
          })}
          loadOptions={debounce((a, b) => getData(a, b, choosedValues), 500, {
            leading: false,
            accumulate: false,
          })}
          defaultValue={initValue}
        />
        {isError && meta.error && <p className="inp-error">{meta.error}</p>}
      </div>
    </div>
  );
};

export default AsyncSelect;
