export const order_statuses = [
  { value: "checkout", label: "W koszyku" },
  { value: "pending_payment", label: "Oczekuje na płatność" },
  { value: "pending_shipment", label: "Oczekuje na wysyłkę" },
  { value: "completed", label: "Zakończone" },
  { value: "canceled", label: "Anulowane" },
];

export const getStatus = status => {
  switch (status) {
    case "checkout":
      return "W koszyku";
    case "pending_payment":
      return "Oczekuje na płatność";
    case "pending_shipment":
      return "Oczekuje na wysyłkę";
    case "completed":
      return "Zakończone";
    case "canceled":
      return "Anulowane";
    default:
      return "-";
  }
};
