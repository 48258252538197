import React from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";
import { client } from "utils/api";

const AsyncCollections = ({ change, input, getData, initValue }) => {
  const loadOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const collections = await client.get(
          `/collections?page=1&per_page=20${q ? `&q[name_matches]=%25${q}%25` : ""}`
        );
        const data = collections.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  const customStyles = {
    control: styles => ({
      ...styles,
      borderColor: "#E2E5EC",
    }),
    option: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "4px",
    }),
    multiValue: styles => ({
      ...styles,
      borderRadius: "4px",
      backgroundColor: "rgba(93, 120, 249, .3)",
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: "rgba(93, 120, 249, 1)",
      maxWidth: "200px",
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: "rgba(93, 120, 249, 1)",
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    placeholder: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group`}>
      <div className='inp-wrapper'>
        <label>Kolekcja</label>
        <Select
          styles={customStyles}
          defaultOptions
          onChange={item => {
            change(input.name, item.value);
          }}
          cacheOptions
          placeholder={"Wybierz..."}
          theme={theme => ({
            ...theme,
            fontFamily: "Poppins",
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: "#E2E5EC",
            },
          })}
          loadOptions={debounce((a, b) => loadOptions(a, b), 500, {
            leading: false,
            accumulate: false,
          })}
          defaultValue={initValue}
        />
      </div>
    </div>
  );
};
export default AsyncCollections
