import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Button, Input, Checkbox } from "expano-components";
import { Dashboard } from "components";
import { updateClient } from "actions/clients";

const InputField = props => <Input {...props} />;
const CheckField = props => <Checkbox {...props} />;

const selector = formValueSelector("client-shipping-addresses");

const ClientEditAddresses = ({ id, same_as_billing, updateClient, handleSubmit, pristine }) => {
  const submit = values => {
    if (same_as_billing) {
      values.client_shipping_address_attributes = {
        ...values.client_billing_address_attributes,
      };
    }
    updateClient(id, values);
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Adres dostawy", icon: "address" }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div className='dashboard__form'>
          <div style={{ marginTop: -20, marginBottom: 20 }}>
            <Field
              label='Taki sam jak adres rozliczeniowy'
              component={CheckField}
              name='same_as_billing'
            />
          </div>
          <div style={{ display: same_as_billing ? "none" : "block" }}>
            <div className='form-row grid-2'>
              <Field
                name='client_shipping_address_attributes.first_name'
                component={InputField}
                type='text'
                label='Imię'
              />
              <Field
                name='client_shipping_address_attributes.last_name'
                component={InputField}
                type='text'
                label='Naziwsko'
              />
            </div>
            <Field
              name='client_shipping_address_attributes.street'
              component={InputField}
              type='text'
              label='Ulica i numer'
            />
            <div className='form-row grid-2'>
              <Field
                name='client_shipping_address_attributes.zip_code'
                component={InputField}
                type='text'
                label='Kod pocztowy'
              />
              <Field
                name='client_shipping_address_attributes.city'
                component={InputField}
                type='text'
                label='Miasto'
              />
            </div>

            <Field
              name='client_shipping_address_attributes.phone'
              component={InputField}
              type='text'
              label='Telefon'
            />
          </div>
        </div>
      </Dashboard>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  updateClient: (id, data) => dispatch(updateClient(id, data)),
});

export default connect(
  state => ({
    id: state.client.data.id,
    initialValues: {
      client_billing_address_attributes: state.client.data.client_billing_address,
      client_shipping_address_attributes: state.client.data.client_shipping_address,
      same_as_billing: false,
    },
    same_as_billing: selector(state, "same_as_billing"),
  }),
  mapDispatchToProps
)(reduxForm({ form: "client-shipping-addresses", enableReinitialize: true })(ClientEditAddresses));
