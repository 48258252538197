import React from "react";
import { Button, Select, Input } from "expano-components";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { updateCollection } from "actions/collections";
import { Dashboard } from "components";

const InputField = props => <Input {...props} />;

const status_options = [
  { label: "Aktywny", value: "active" },
  { label: "Nieaktywny", value: "inactive" },
];

const BasicForm = ({
  collection,
  data,
  submitting,
  pristine,
  handleSubmit,
  updateCollection,
  change,
}) => {
  const submit = values => updateCollection(collection.id, values);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{ title: "Podstawowe informacje", icon: "info" }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div noValidate className='dashboard__form'>
          <div className='form-row grid-2'>
            <Field label='Nazwa' name='name' component={InputField} type='text' />
            <Field name='status' component={props => <Input {...props} type='hidden' />} />
            <Select
              label='Status'
              name='status'
              options={status_options}
              handleChange={value => change("status", value)}
              defaultValue={status_options.find(({ value }) => value === collection.status) || {}}
            />
          </div>
        </div>
      </Dashboard>
    </form>
  );
};
const mapDispatchToProps = dispatch => ({
  updateCollection: (id, data) => dispatch(updateCollection(id, data)),
});
export default connect(
  ({ collection }) => ({
    collection,
    initialValues: {
      name: collection?.name,
      status: collection?.status,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-collection-basic",
    enableReinitialize: true,
  })(BasicForm)
);
