import React from "react";
import { connect } from "react-redux";
import { Button, Textarea, Input } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { Dashboard } from "components";

import { updateChannel } from "actions/channels";

const InputField = props => <Input {...props} />;
const TextAreaField = props => <Textarea {...props} />;

const BankForm = ({ channel, pristine, dispatch, updateChannel, handleSubmit }) => {
  const submit = values => updateChannel(values);

  const handleCancel = () => {
    dispatch(reset("bank-form"));
  };
  return (
    <form onSubmit={handleSubmit(submit)} style={{ marginBottom: 20 }}>
      <Dashboard
        header={{ title: "Dane do przelewu", icon: "wallet" }}
        footer={{
          buttons: (
            <>
              <Button type='cancel' onClick={handleCancel} />
              <Button
                type='save'
                disabled={pristine}
                text='Zapisz'
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form__submit'>
          <Field type='hidden' name='id' component={InputField} />
          <Field type='text' label='Nazwa odbiorcy' name='bank_recipient' component={InputField} />
          <Field label='Adres odbiorcy' name='bank_recipient_address' component={TextAreaField} />
          <Field type='text' label='Numer rachunku' name='bank_account' component={InputField} />
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  ({ channel }) => ({
    initialValues: {
      id: channel?.data?.id,
      bank_recipient: channel?.data?.bank_recipient,
      bank_recipient_address: channel?.data?.bank_recipient_address,
      bank_account: channel?.data?.bank_account,
    },
  }),
  dispatch => ({
    updateChannel: data => dispatch(updateChannel(data)),
  })
)(
  reduxForm({
    form: "bank-form",
    enableReinitialize: true,
  })(BankForm)
);
