const section_types = [
  { label: "Kolekcja", value: "Collection", table_value: "collection" },
  { label: "Kategoria", value: "ChannelTaxon", table_value: "channel_taxon" },
  {
    label: "Producent",
    value: "ChannelProducer",
    table_value: "channel_producer",
  },
];

export default section_types;
