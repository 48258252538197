import React from "react";
import { connect } from "react-redux";
import ImageListRow from "./components/ImageListRow";

const ChannelProductGallery = ({ channel_product }) => {
  return (
    <div className='dashboard-wrapper-flex'>
      <div className='column-half'>
        <div className='dashboard'>
          <header className='dashboard__header dashboard__header--edit'>
            <div className='icon__container'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                <path style={{ fill: "none" }} d='M0,0H20V20H0Z' />
                <rect fill='#5d78ff' opacity='0.3' width='16.667' height='13.333' rx='2' transform='translate(1.667 3.333)' />
                <path fill='#5d78ff' opacity='0.3' d='M4,18.5,9.417,11l5.417,7.5Z' transform='translate(-0.667 -1.833)' />
                <path fill='#5d78ff' d='M11,19l3.75-5,3.75,5Z' transform='translate(-1.833 -2.333)' />
                <circle fill='#5d78ff' opacity='0.3' cx='1.25' cy='1.25' r='1.25' transform='translate(14.167 5.833)' />
              </svg>
            </div>
            <h2 className='dashboard__title heading'>Zdjecie 360</h2>
          </header>
          <table className='table'>
            <thead className='table__header table-head'>
              <tr>
                <th width='220px'>Podgląd</th>
                <th width='100px'>ID</th>
                <th>Plik</th>
              </tr>
            </thead>
            <tbody className='table__body table-body'>
              {channel_product?.channel_product360_images?.length > 0 &&
                channel_product?.channel_product360_images.map(item => <ImageListRow key={item.id} {...item} />)}
            </tbody>
          </table>
        </div>
      </div>
      <div className='column-half'>
        <div className='dashboard'>
          <header className='dashboard__header dashboard__header--edit'>
            <div className='icon__container'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                <path
                  fill='#5d78ff'
                  opacity='0.3'
                  d='M3.25,18.167H17.417a1.25,1.25,0,0,0,1.25-1.25V7.75a1.25,1.25,0,0,0-1.25-1.25H8.667L6.533,4.366A1.25,1.25,0,0,0,5.649,4H3.25A1.25,1.25,0,0,0,2,5.25V16.917A1.25,1.25,0,0,0,3.25,18.167Z'
                  transform='translate(-0.333 -0.667)'
                />
                <path fill='#5d78ff' opacity='0.3' d='M4,17.667,9,11l5,6.667Z' transform='translate(-0.667 -1.833)' />
                <path fill='#5d78ff' d='M11,18.167,14.333,14l3.333,4.167Z' transform='translate(-1.833 -2.333)' />
                <path
                  fill='#5d78ff'
                  opacity='0.3'
                  d='M17.75,11.5a1.25,1.25,0,1,0-1.25-1.25A1.25,1.25,0,0,0,17.75,11.5Z'
                  transform='translate(-2.75 -1.5)'
                />
              </svg>
            </div>
            <h2 className='dashboard__title heading'>Galeria zdjęć</h2>
          </header>
          <table className='table'>
            <thead className='table__header table-head'>
              <tr>
                <th width='220px'>Podgląd</th>
                <th width='100px'>ID</th>
                <th>Plik</th>
              </tr>
            </thead>
            <tbody className='table__body table-body'>
              {channel_product?.channel_product_gallery_images?.length > 0 &&
                channel_product?.channel_product_gallery_images.map(item => <ImageListRow key={item.id} {...item} />)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default connect(({ channel_product }) => ({
  channel_product: channel_product?.data,
}))(ChannelProductGallery);
