import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";

const PaymentPanel = ({ payment_method, payment_status }) => {
  return (
    <Dashboard header={{ title: "Płatność", icon: "payment" }}>
      <div className='row-info'>
        <span className='row-info__label'>{payment_method?.name}</span>
      </div>
      <div className='row-info'>
        <span className='row-info__label'>Faktura</span>
        <button className='btn-download'>
          <span>Pobierz</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20.06'
            height='20.06'
            viewBox='0 0 20.06 20.06'
          >
            <path
              fill='#8ba4db'
              opacity='0.3'
              d='M5.552,2h6.586a1.254,1.254,0,0,1,.81.3l3.981,3.368a1.254,1.254,0,0,1,.444.957V17.114c0,1.5-.017,1.6-1.552,1.6H5.552c-1.535,0-1.552-.105-1.552-1.6V3.6C4,2.105,4.017,2,5.552,2Z'
              transform='translate(-0.657 -0.328)'
            />
            <path
              fill='#8ba4db'
              d='M13.831,11.419H12.2V9.741a.418.418,0,0,0-.418-.418h-.855a.418.418,0,0,0-.418.418v1.678H8.869a.418.418,0,0,0-.319.688l2.481,2.93a.418.418,0,0,0,.638,0l2.481-2.93a.418.418,0,0,0-.319-.688Z'
              transform='translate(-1.388 -1.531)'
            />
          </svg>
        </button>
      </div>
    </Dashboard>
  );
};

export default connect(({ order }) => ({
  payment_method: order?.data?.payment_method,
  payment_status: order?.data?.payment_status,
}))(PaymentPanel);
