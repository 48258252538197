import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors } from "utils/api";

export const getHomeDiscounts = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "HOME_DISCOUNTS_LOADING" });
    await client
      .get(`/home_discounts?include=discount`)
      .then(({ data }) => {
        dispatch({
          type: "HOME_DISCOUNTS_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(() => {
        dispatch({ type: "HOME_DISCOUNTS_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const addHomeDiscount = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/home_discounts?include=discount`, data)
      .then(({ data }) => {
        const formatted_data = apiFormatter(data);
        dispatch({
          type: "HOME_DISCOUNT_ADD",
          data: formatted_data,
        });
        resolve(formatted_data.id);
      })
      .catch((err) => {
        console.log(err);
        try {
          toastr.error("Błąd", "Wystąpił błąd podczas dodawani promocji");
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};

export const deleteHomeDiscount = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/home_discounts/${id}`);
      dispatch({
        type: "HOME_DISCOUNT_DELETE",
        id,
      });
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas dodawani promocji");
      reject();
    }
  });
