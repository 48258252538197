import React from "react";
import { Button, Select, Input, Checkbox } from "expano-components";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { updateChannelProducer } from "actions/channel_producers";
import { Dashboard } from "components";

const CheckField = props => <Checkbox {...props} />;

const status_options = [
  { label: "Aktywny", value: "active" },
  { label: "Nieaktywny", value: "inactive" },
];

const BasicForm = ({
  channel_producer,
  pristine,
  handleSubmit,
  updateChannelProducer,
  change,
}) => {
  const submit = values => updateChannelProducer(channel_producer.id, values);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{ title: "Podstawowe informacje", icon: "info" }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div noValidate className='dashboard__form'>
          <Input
            input={{ name: "name", defaultValue: channel_producer?.producer?.name }}
            label='Nazwa'
            disabled
            type='text'
            meta={{ touched: false, invalid: false }}
          />
          <div className='form-row grid-2'>
            <Field name='status' component={props => <Input {...props} type='hidden' />} />
            <Select
              label='Status'
              name='status'
              options={status_options}
              handleChange={value => change("status", value)}
              defaultValue={
                status_options.find(({ value }) => value === channel_producer.status) || {}
              }
            />
            <div class='form-group new-checkbox'>
              <div class='inp-wrapper'>
                <p class='label-form'>Mega menu</p>
                <Field component={CheckField} name='show_in_mega_menu' label='Wyświetl' />
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};
const mapDispatchToProps = dispatch => ({
  updateChannelProducer: (id, data) => dispatch(updateChannelProducer(id, data)),
});
export default connect(
  ({ channel_producer }) => ({
    channel_producer,
    initialValues: {
      show_in_mega_menu: channel_producer?.show_in_mega_menu,
      status: channel_producer?.status,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-channel-producer-basic",
    enableReinitialize: true,
  })(BasicForm)
);
