import React from "react";
import { connect } from "react-redux";
import { Select } from "expano-components";
import discount_reference_type_options from "../../../../const/discount_reference_type_options";
import { updateDiscount } from "actions/discounts";

const DiscountReferenceTypeSelect = ({ id, discount_reference_type, updateDiscount }) => {
  return (
    <Select
      name='discount_reference_type'
      options={discount_reference_type_options}
      isDisabled={discount_reference_type !== "none"}
      defaultValue={
        discount_reference_type_options.find(({ value }) => value === discount_reference_type) ||
        null
      }
      handleChange={value => updateDiscount(id, { discount_reference_type: value })}
    />
  );
};

export default connect(
  ({ discount: { id, discount_reference_type } }) => ({
    id,
    discount_reference_type,
  }),
  dispatch => ({ updateDiscount: (id, data) => dispatch(updateDiscount(id, data)) })
)(DiscountReferenceTypeSelect);
