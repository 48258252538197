import React from "react";

import StyledDashboardTabs from "./styles";

const DashboardTabs = ({ data }) => {
  return (
    <StyledDashboardTabs>
      {data.map(({ name, onClick, active }, index) => (
        <li key={index} className={`tab-item ${active ? "active" : ""}`} onClick={onClick}>
          {name}
        </li>
      ))}
    </StyledDashboardTabs>
  );
};

export default DashboardTabs;
