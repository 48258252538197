import styled from "styled-components";

const StyledActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px 0;
  button {
    margin-left: 15px;
  }
`;
export default StyledActionButtons;
