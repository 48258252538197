import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "CLIENTS_TOGGLE_FILTERS" });
};

export const filterClients = data => async dispatch => {
  dispatch({ type: "CLIENTS_SET_FILTERS_QUERY", data });
};

export const getClients = ({ page = 1, per_page = 10, ...rest }) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().clients;
  status === "invalid"
    ? dispatch({ type: "CLIENTS_LOADING" })
    : dispatch({ type: "CLIENTS_SEARCHING" });
  await client
    .get(
      `/clients?include=client_shipping_address&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "CLIENTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "CLIENTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getClientOrders = ({ page = 1, per_page = 10, ...rest }, user_id) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().client_orders;
  status === "invalid"
    ? dispatch({ type: "CLIENT_ORDERS_LOADING" })
    : dispatch({ type: "CLIENT_ORDERS_SEARCHING" });
  client
    .get(
      `/orders?q[client_id_eq]=${user_id}&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "CLIENT_ORDERS_SUCCESS",
        data: { data: apiFormatter(data), meta: data.meta },
      });
    })
    .catch(() => {
      dispatch({ type: "CLIENT_ORDERS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getClient = id => async dispatch => {
  dispatch({ type: "CLIENT_LOADING" });
  try {
    const { data } = await client.get(
      `/clients/${id}?include=client_billing_address,client_shipping_address`
    );
    dispatch({
      type: "CLIENT_SUCCESS",
      data: apiFormatter(data),
    });
  } catch (error) {
    dispatch({ type: "CLIENT_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
  }
};

export const updateClient = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/clients/${id}?include=client_billing_address,client_shipping_address`,
        values
      );
      toastr.success("Sukces", "Dane klienta zostały zaktualizowane");
      dispatch({
        type: "CLIENT_SUCCESS",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteClient = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/clients/${id}`);
      toastr.success("Sukces", "Klient został usuniety");
      resolve();
    } catch (error) {
      toastr.success("Sukces", "Wystąpił błąd podczas usuwania klienta");
      reject();
    }
  });

export const createClient = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/clients`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano klienta");
        resolve(data);
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił  podczas tworzenia klienta");
        reject(handleErrors(err.response.data.errors));
      });
  });
