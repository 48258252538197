import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalDelete } from "expano-components";
import { PageLoader, Main } from "components";
import {
  getDiscountCode,
  deleteDiscountCode,
} from "actions/discount_codes";
import { BasicData, RelationOrders } from "./components";

const DiscountCodeEdit = ({
  getDiscountCode,
  discount_code,
  history,
  match: { params },
}) => {
  const [
    is_delete_modal_open,
    handleOpenDeleteModal,
  ] = useState(false);
  useEffect(() => {
    getDiscountCode(params.id);
  }, []);
  if (
    !discount_code ||
    discount_code.status === "invalid" ||
    discount_code.status === "loading"
  )
    return <PageLoader color="#2959F8" />;

  if (discount_code.status === "failure") {
    return <p>Error</p>;
  }
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discount_codes`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Promocje",
          path,
        },
        {
          name: discount_code?.name,
        },
      ]}
      actions={[
        {
          label: "Usuń kod rabatowy",
          icon: "delete",
          action: () => handleOpenDeleteModal(true),
        },
      ]}>
      {is_delete_modal_open && (
        <ModalDelete
          name={discount_code?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() =>
            deleteDiscountCode(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push(path);
            })
          }
        />
      )}
      <BasicData />
      <RelationOrders />
    </Main>
  );
};

export default connect(
  ({ discount_code }) => ({
    discount_code,
  }),
  (dispatch) => ({
    getDiscountCode: (id) => dispatch(getDiscountCode(id)),
  })
)(DiscountCodeEdit);
