import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Textarea, Input, Button } from "expano-components";
import { Dashboard } from "components";
import { updatePage } from "actions/pages";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

const Seo = ({ updatePage, handleCancel, handleSubmit, pristine, data }) => {
  const submit = values =>
    updatePage(data.id, values).catch(err => {
      throw new SubmissionError(err);
    });
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Seo", icon: "info" }}
        footer={{
          buttons: (
            <>
              <Button type='cancel' onClick={handleCancel} />
              <Button
                disabled={pristine}
                type='save'
                text='Zapisz'
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form'>
          <Field name='meta_title' label='Meta title' component={InputField} type='text' />
          <Field name='meta_description' label='Meta description' component={TextareaField} />
          <Field name='slug' label='Slug URL' component={InputField} type='text' />
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  ({ page }) => ({
    data: page?.data,
    initialValues: {
      meta_title: page?.data?.meta_title,
      slug: page?.data?.slug,
      meta_description: page?.data?.meta_description,
    },
  }),
  dispatch => ({
    updatePage: (id, data) => dispatch(updatePage(id, data)),
  })
)(reduxForm({ form: "edit-page-seo", enableReinitialize: true })(Seo));
