import { client } from "utils/api";

const getCategories = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const categories = await client.get(
        `channel_taxons?page=1&per_page=20${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      const data = categories.data.data.map(({ id, attributes }) => ({
        label: attributes.name,
        value: id,
      }));
      resolve([{ label: "Brak", value: null }, ...data]);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getCategories;
