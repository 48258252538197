import { client } from "utils/api";

const getAuthors = q =>
  new Promise(async (resolve, reject) => {
    try {
      const authors = await client.get(
        `authors?page=1&per_page=20${q ? `&q[first_name_or_last_name_matches]=%25${q}%25` : ""}`
      );
      const data = authors.data.data.map(({ id, attributes }) => ({
        label: `${attributes.first_name} ${attributes.last_name}`,
        value: id,
      }));
      resolve(data);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getAuthors;
