import styled from "styled-components";

const StyledMainTable = styled.table`
  width: 100%;
  border-bottom: 1px solid #ebedf2;
  transition: 0.2s;
  ${({ searching }) => (searching ? "opacity: 0.4;" : "")}
  tbody {
    tr {
      border-top: 1px solid #ebedf2;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        background-color: #f7f8fa;
      }
      td {
        padding: 19px 0 19px 30px;
        text-align: left;
        font-size: 13px;
        line-height: 21px;
        vertical-align: middle;
        color: #595d6e;
        a {
          color: rgb(93, 120, 255);
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
export default StyledMainTable;
