import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "DISCOUNT_COLLECTIONS_TOGGLE_FILTERS" });
};

export const filterDiscountCollections = data => async dispatch => {
  dispatch({ type: "DISCOUNT_COLLECTIONS_SET_FILTERS_QUERY", data });
};

export const getDiscountCollections = ({ page = 1, per_page = 10, ...rest }, discount_id) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().discount_collections;
  status === "invalid"
    ? dispatch({ type: "DISCOUNT_COLLECTIONS_LOADING" })
    : dispatch({ type: "DISCOUNT_COLLECTIONS_SEARCHING" });
  await client
    .get(
      `/collection_discounts?include=collection&q[discount_id_eq]=${discount_id}&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "DISCOUNT_COLLECTIONS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "DISCOUNT_COLLECTIONS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const deleteDiscountCollectionProduct = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/collection_discounts/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createDiscountCollectionProduct = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/collection_discounts`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano kolekcję do promocji");
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });
