import React from "react";
import {
  NotFound,
  Home,
  ChannelTaxonsList,
  ChannelTaxonEdit,
  //
  ChannelProductsList,
  ChannelProductShow,
  ChannelTaxonNew,
  //
  ChannelProducersList,
  ChannelProducerEdit,
  //
  ClientsList,
  ClientNew,
  ClientEdit,
  //
  OrdersList,
  OrderEdit,
  //
  StaticPagesList,
  StaticPagesNew,
  StaticPagesEdit,
  //
  EditChannelData,
  //
  ShippingMethods,
  PaymentMethods,
  //
  ArticlesList,
  ArticleNew,
  ArticleEdit,
  //
  AuthorsList,
  AuthorNew,
  AuthorEdit,
  //
  CollectionsList,
  CollectionNew,
  CollectionEdit,
  //
  DiscountsList,
  DiscountNew,
  DiscountEdit,
  //
  DiscountCodesList,
  DiscountCodeEdit,
  DiscountCodeNew,
} from "pages";
import { Switch, Route } from "react-router-dom";
const path_prefix = "/platforms/:platform_id/channels/:channel_id";
const Router = () => {
  return (
    <Switch>
      <Route path={`${path_prefix}/edit`} component={EditChannelData} />
      <Route exact={true} path={`${path_prefix}/home_page/:tab*`} component={Home} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/channel_taxons`} component={ChannelTaxonsList} />
      <Route exact={true} path={`${path_prefix}/channel_taxons/new`} component={ChannelTaxonNew} />
      <Route
        exact={true}
        path={`${path_prefix}/channel_taxons/:id/:tab*`}
        component={ChannelTaxonEdit}
      />
      {/*  */}
      <Route
        exact={true}
        path={`${path_prefix}/channel_products`}
        component={ChannelProductsList}
      />
      <Route
        exact={true}
        path={`${path_prefix}/channel_products/:id/:tab*`}
        component={ChannelProductShow}
      />
      {/* */}
      <Route
        exact={true}
        path={`${path_prefix}/channel_producers`}
        component={ChannelProducersList}
      />
      <Route
        exact={true}
        path={`${path_prefix}/channel_producers/:id/:tab*`}
        component={ChannelProducerEdit}
      />
      {/* */}
      <Route exact={true} path={`${path_prefix}/clients`} component={ClientsList} />
      <Route exact={true} path={`${path_prefix}/clients/new`} component={ClientNew} />
      <Route exact={true} path={`${path_prefix}/clients/:id/:tab*`} component={ClientEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/orders`} component={OrdersList} />
      <Route exact={true} path={`${path_prefix}/orders/:id`} component={OrderEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/pages`} component={StaticPagesList} />
      <Route exact={true} path={`${path_prefix}/pages/new`} component={StaticPagesNew} />
      <Route exact={true} path={`${path_prefix}/pages/:id`} component={StaticPagesEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/shipping_methods`} component={ShippingMethods} />
      <Route exact={true} path={`${path_prefix}/payment_methods`} component={PaymentMethods} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/articles`} component={ArticlesList} />
      <Route exact={true} path={`${path_prefix}/articles/new`} component={ArticleNew} />
      <Route exact={true} path={`${path_prefix}/articles/:id`} component={ArticleEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/authors`} component={AuthorsList} />
      <Route exact={true} path={`${path_prefix}/authors/new`} component={AuthorNew} />
      <Route exact={true} path={`${path_prefix}/authors/:id/:tab*`} component={AuthorEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/collections`} component={CollectionsList} />
      <Route exact={true} path={`${path_prefix}/collections/new`} component={CollectionNew} />
      <Route
        exact={true}
        path={`${path_prefix}/collections/:id/:tab*`}
        component={CollectionEdit}
      />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/discounts`} component={DiscountsList} />
      <Route exact={true} path={`${path_prefix}/discounts/new`} component={DiscountNew} />
      <Route exact={true} path={`${path_prefix}/discounts/:id`} component={DiscountEdit} />
      {/*  */}
      <Route exact={true} path={`${path_prefix}/discount_codes`} component={DiscountCodesList} />
      <Route exact={true} path={`${path_prefix}/discount_codes/new`} component={DiscountCodeNew} />
      <Route exact={true} path={`${path_prefix}/discount_codes/:id`} component={DiscountCodeEdit} />
      {/*  */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
