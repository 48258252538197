import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import { deleteSlide, moveSlide } from "actions/sliders";

const ListItem = ({
  is_active,
  setTab,
  setFirstTab,
  name,
  id,
  deleteSlide,
  moveSlide,
  position,
  count,
}) => {
  const [is_open, handleOpen] = useState(false);
  return (
    <li
      className={`tab ${is_active ? "active" : ""}`}
      key={id}
      onClick={setTab}>
      {is_open && (
        <ModalDelete
          name={`slide ${name}`}
          handleClose={() => handleOpen(false)}
          handleDelete={() =>
            deleteSlide(id).then(() => {
              handleOpen(false);
              setFirstTab();
            })
          }
        />
      )}
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16">
          <g transform="translate(-324 -532)">
            <circle
              fill="#5d78ff"
              opacity="0.3"
              cx="8"
              cy="8"
              r="8"
              transform="translate(324 532)"
            />
            <path
              fill="#5d78ff"
              d="M45,14.928a2.068,2.068,0,0,0-.634-.326,7.032,7.032,0,0,0-.855-.213,4.632,4.632,0,0,1-.618-.16.854.854,0,0,1-.364-.221.479.479,0,0,1-.115-.332.452.452,0,0,1,.1-.292.7.7,0,0,1,.313-.209,1.549,1.549,0,0,1,.966-.019,3.1,3.1,0,0,1,.44.165,1.348,1.348,0,0,1,.328.2.5.5,0,0,0,.668-.085.563.563,0,0,0,.163-.392.629.629,0,0,0-.267-.5,2.317,2.317,0,0,0-.756-.418,2.994,2.994,0,0,0-1-.163,2.661,2.661,0,0,0-1.047.2,1.788,1.788,0,0,0-.766.6,1.587,1.587,0,0,0-.287.953,1.771,1.771,0,0,0,.119.662,1.463,1.463,0,0,0,.356.518,1.842,1.842,0,0,0,.58.358,3.392,3.392,0,0,0,.783.2,3.331,3.331,0,0,1,.73.175,1.026,1.026,0,0,1,.4.258.508.508,0,0,1,.121.345.4.4,0,0,1-.124.288.979.979,0,0,1-.362.227,1.5,1.5,0,0,1-.51.087,1.811,1.811,0,0,1-.7-.121,2.534,2.534,0,0,1-.581-.365.522.522,0,0,0-.334-.119.53.53,0,0,0-.4.173.553.553,0,0,0-.159.4.6.6,0,0,0,.065.27.76.76,0,0,0,.185.234,2.51,2.51,0,0,0,.891.514,3.474,3.474,0,0,0,1.038.145,2.621,2.621,0,0,0,1.051-.209,1.941,1.941,0,0,0,.8-.612,1.541,1.541,0,0,0,.309-.962,1.906,1.906,0,0,0-.128-.736A1.281,1.281,0,0,0,45,14.928Z"
              transform="translate(288.814 525.037)"
            />
          </g>
        </svg>
      </div>
      <div className="text">
        <p>{name}</p>
      </div>
      <div className="buttons-wrapper">
        <div className="order-buttons">
          <button
            class="order-button up"
            disabled={position <= 1}
            onClick={() => moveSlide(id, { position: position - 1 })}>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false">
              <path
                fill="rgba(162, 165, 185, 1)"
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </button>
          <button
            type="button"
            class="order-button down"
            disabled={position >= count}
            onClick={() => moveSlide(id, { position: position + 1 })}>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false">
              <path
                fill="rgba(162, 165, 185, 1)"
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </button>
        </div>
        <Button type="delete-bin" onClick={() => handleOpen(true)} />
      </div>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteSlide: (id) => dispatch(deleteSlide(id)),
  moveSlide: (id, data) => dispatch(moveSlide(id, data)),
});

export default connect(null, mapDispatchToProps)(ListItem);
