import React, { useState } from "react";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { connect } from "react-redux";
import { Button, Textarea } from "expano-components";
import { Dashboard } from "components";
import { updateOrder } from "actions/orders";
import { Edit } from "icons";

const TextAreaField = props => <Textarea {...props} />;

const NoteForm = ({
  updateOrder,
  id,
  handleClose,
  handleSubmit,
  submiting,
  pristine,
  dispatch,
  initialValues,
}) => {
  const [is_enabled, enable] = useState(false);
  const onSubmit = values => {
    return updateOrder(id, values)
      .then(() => enable(false))
      .catch(err => {
        throw new SubmissionError(err);
      });
  };
  const handleCancel = () => {
    dispatch(reset("note-form"));
    enable(false);
  };
  return (
    <Dashboard
      header={{
        title: "Notatki",
        icon: "info",
        aside: !is_enabled ? (
          <button className='btn btn-note' type='button' onClick={() => enable(true)}>
            <Edit />
            <span>Edytuj</span>
          </button>
        ) : (
          <div style={{ display: "flex" }}>
            <Button type='cancel' onClick={handleCancel} style={{ marginRight: 12 }} />
            <Button
              disabled={pristine || submiting}
              type='save'
              text='Zapisz'
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        ),
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className='dashboard__form'>
          {is_enabled ? (
            <Field name='notes' component={TextAreaField} />
          ) : (
            <p>{initialValues.notes}</p>
          )}
        </div>
      </form>
    </Dashboard>
  );
};

const mapStateToProps = ({ order }) => ({
  id: order?.data?.id,
  initialValues: {
    notes: order?.data?.notes,
  },
});

const mapDispatchToProps = dispatch => ({
  updateOrder: (id, data) => dispatch(updateOrder(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "note-form", enableReinitialize: true })(NoteForm));
