import styled from "styled-components";

const StyledTableHead = styled.thead`
  th {
    cursor: pointer;
    padding: 13px 0 13px 30px;
    text-align: left;
    vertical-align: middle;
    span {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }
    .sort-icons-group {
      display: inline-flex;
      flex-direction: column;
      vertical-align: middle;
      margin-top: -2px;
      margin-left: 10px;
      svg {
        margin: 0.7px;
      }
    }
  }
`;
export default StyledTableHead;
