const initialState = {
  status: "invalid",
  initialValues: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SHIPPING_METHODS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "SHIPPING_METHODS_SUCCESS": {
      let speditors = [];

      action.data
        .sort((item, next) => item.id - next.id)
        .forEach(item => {
          speditors.push(item.speditor?.name);
        });
      speditors = [...new Set(speditors)];
      const initialValues = {};
      if (action.data?.length > 0) {
        action.data.forEach(item => {
          initialValues[`method_${item.id}`] = {
            is_active: item.is_active,
            price: Number(item.price),
            delivery_days: item.delivery_days || 1,
            name: item.name,
          };
        });
      }

      return {
        ...state,
        status: "success",
        data: action.data,
        speditors,
        initialValues,
      };
    }
    case "SHIPPING_METHOD_UPDATE": {
      const initialValues = { ...state.initialValues };
      const data = [...state.data];
      const item_index = data.indexOf(data.find(({ id }) => id === action.data.id));
      if (item_index > -1) {
        data[item_index] = action.data;
      }
      if (initialValues[`method_${action.data.id}`]) {
        initialValues[`method_${action.data.id}`] = {
          is_active: action.data.is_active,
          price: Number(action.data.price),
          delivery_days: action.data.delivery_days || 1,
          name: action.data.name,
        };
        return {
          ...state,
          initialValues,
        };
      }

      return state;
    }
    case "SHIPPING_METHODS_FAILURE":
      return {
        ...state,
        status: "failure",
      };

    default:
      return state;
  }
};
