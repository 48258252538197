import React from "react";
import getStatus from "./statuses";

import StyledProductStatus from "./styles";

const ProductStatus = ({ status }) => {
  return (
    <StyledProductStatus className={getStatus(status)?.color}>
      <span>{getStatus(status)?.name}</span>
    </StyledProductStatus>
  );
};

export default ProductStatus;