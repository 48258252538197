import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Main, DashboardTabs, PageLoader } from "components";
import { getAuthor } from "actions/authors";
import { BasicData, Posts } from "./components";

const renderPanels = tab => {
  switch (tab) {
    case "posts":
      return <Posts />;
    default:
      return <BasicData />;
  }
};

const AuthorEdit = ({ author, getAuthor, updateAuthor, history, match: { params }, channel_id, handleSubmit, pristine, change }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/authors`;
  useEffect(() => {
    getAuthor(params.id);
  }, []);

  if (author.status === "invalid" || author.status === "loading") return <PageLoader />;
  if (author.status === "failure") {
    return <p>Error</p>;
  }
  return (
    <Main breadcrumbs={[{ name: "Autorzy", path, icon: "author" }, { name: `${author?.first_name} ${author?.last_name}` }]}>
      <DashboardTabs
        data={[
          { name: "Podstawowe informacje", active: !params.tab, onClick: () => history.push(`${path}/${params.id}`) },
          { name: " Wpisy autora", active: params.tab === "posts", onClick: () => history.push(`${path}/${params.id}/posts`) },
        ]}
      />
      {renderPanels(params.tab)}
    </Main>
  );
};

const mapStateToProps = ({ author, channel }) => ({
  author,
  channel_id: channel.data.id,
});
const mapDispatchToProps = dispatch => ({
  getAuthor: id => dispatch(getAuthor(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorEdit);
