import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10.5px 30px;
  min-height: 60px;
  border-top: 1px solid #ebedf2;
  button {
    margin-left: 20px;
  }
`;
export default StyledHeader;
