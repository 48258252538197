import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Input } from "expano-components";
import { createHreflang } from "actions/articles";

const InputField = props => <Input {...props} />;

class PropertyOptionNew extends PureComponent {
  submit = values => {
    const { createHreflang, article_id, handleClose } = this.props;
    return createHreflang({
      ...values,
      article_id,
    })
      .then(() => {
        handleClose();
      })
      .catch(err => {
        throw new SubmissionError(err);
      });
  };

  render() {
    const { handleSubmit, handleClose } = this.props;
    return (
      <form noValidate onSubmit={handleSubmit(this.submit)} className="property-options__form">
        <div className="form-row" style={{ padding: "10px 30px" }}>
          <div style={{ flexBasis: 120 }}>
            <Field name={`lang_code`} component={InputField} />
          </div>
          <Field name={`url`} component={InputField} />

          <div className="form-group" style={{ flexBasis: 120 }}>
            <button className="btn btn-icon" type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(-1175 -784)">
                  <rect fill="#0abb87" width="24" height="24" rx="4" transform="translate(1175 784)" />
                  <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2px"
                    d="M9937,2197.432l3.8,3.71,6.2-8"
                    transform="translate(-8754.504 -1400.643)"
                  />
                </g>
              </svg>
            </button>
            <button type="button" className="btn btn-icon" onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(-1204.5 -784.5)">
                  <rect fill="#fd397a" width="24" height="24" rx="4" transform="translate(1204.5 784.5)" />
                  <line fill="none" stroke="#fff" strokeWidth="2px" x2="8" y2="8" transform="translate(1212.5 792.5)" />
                  <line fill="none" stroke="#fff" strokeWidth="2px" x1="8" y2="8" transform="translate(1212.5 792.5)" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createHreflang: data => dispatch(createHreflang(data)),
});

export default connect(null, mapDispatchToProps)(reduxForm({ form: "create-hreflang" })(PropertyOptionNew));
