import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Main, DashboardTabs, PageLoader } from "components";
import { getClient } from "actions/clients";
import { Addresses, BasicData, Orders } from "./components";

const Redirect = ({ client, channel_id, history, match }) => {
  useEffect(() => {
    history.push(
      `/platforms/${match.params?.platform_id}/channels/${channel_id}/clients/${client.data.id}/basic_data`
    );
  }, []);
  return null;
};

const ClientEdit = ({ client, getClient, match, history, channel_id }) => {
  useEffect(() => {
    getClient(match.params.id);
  }, []);
  const tabs = [
    {
      id: "basic_data",
      name: "Podstawowe dane",
    },
    {
      id: "addresses",
      name: "Adresy",
    },
    {
      id: "orders",
      name: "Zamówienia",
    },
  ];

  const renderPanels = () => {
    switch (match?.params?.tab) {
      case "basic_data":
        return <BasicData />;
      case "addresses":
        return <Addresses />;
      case "orders":
        return <Orders />;
      default:
        return <Redirect match={match} client={client} history={history} channel_id={channel_id} />;
    }
  };
  if (client.status === "invalid" || client.status === "loading") return <PageLoader />;
  if (client.status === "failure") {
    return <p>Error</p>;
  }
  const { data } = client;
  const path = `/platforms/${match.params?.platform_id}/channels/${channel_id}/clients`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Klienci",
          path,
        },
        { name: `${data.first_name} ${data.last_name}` },
      ]}
    >
      <DashboardTabs
        data={tabs.map(item => ({
          ...item,
          onClick: () => history.push(`${path}/${client.data.id}/${item.id}`),
          active: item.id === match.params.tab,
        }))}
      />
      {renderPanels()}
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getClient: id => dispatch(getClient(id)),
});

export default connect(
  ({ channel, client }) => ({
    channel_id: channel.data.id,
    client,
  }),
  mapDispatchToProps
)(ClientEdit);
