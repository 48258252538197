import React, { useState } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Dashboard } from "components";
import { updateOrder } from "actions/orders";
import Select from "react-select";
import { order_statuses } from "utils/statuses";

const StatusSelect = ({ onChange, value, options = {}, disabled }) => {
  const customStyles = {
    control: styles => ({
      ...styles,
      border: "none",
      background: disabled ? "rgba(162, 165, 185, .1)" : "#F0F3FF",
      minHeight: 30,
      boxShadow: "none",
      cursor: "pointer",
    }),

    option: (provided, state) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "4px 10px",
      color: state.isSelected ? "#fff" : "#48465b",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "0 10px",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      lineHeight: "15px",
      padding: "0px",
      color: "#93A2DD",
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    menu: provided => ({
      ...provided,
      background: "#F0F3FF",
    }),
  };
  return (
    <div className='status-select'>
      <Select
        isDisabled={disabled}
        onChange={onChange}
        styles={customStyles}
        options={options}
        placeholder={"Wybierz..."}
        defaultValue={value}
        isSearchable={false}
      />
    </div>
  );
};

const StatusesPanel = ({
  updateOrder,
  id,
  handleSubmit,
  submiting,
  pristine,
  dispatch,
  initialValues,
  change,
}) => {
  const [is_enabled, enable] = useState(false);
  const onSubmit = values => {
    updateOrder(id, values);
  };
  const handleCancel = () => {
    dispatch(reset("statuses-form"));
    enable(false);
  };
  return (
    <Dashboard
      header={{
        title: "Status zamówienia",
        icon: "order_status",
        aside: !is_enabled ? (
          <button className='btn btn-note' type='button' onClick={() => enable(true)}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
              <g stroke='none' strokeWidth='1' fill='none'>
                <rect x='0' y='0' width='24' height='24' />
                <path
                  d='M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z'
                  fill='#91a0da'
                  transform='translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) '
                />
                <path
                  d='M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z'
                  fill='#91a0da'
                  opacity='0.3'
                />
              </g>
            </svg>
            <span>Edytuj</span>
          </button>
        ) : (
          <div style={{ display: "flex" }}>
            <Button type='cancel' onClick={handleCancel} style={{ marginRight: 12 }} />
            <Button
              disabled={pristine || submiting}
              type='save'
              text='Zapisz'
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        ),
      }}
    >
      <div className='row-info'>
        <span className='row-info__label'>Status zamówienia</span>
        <Field
          component={({ input }) => (
            <StatusSelect
              options={order_statuses}
              disabled={!is_enabled}
              onChange={({ value }) => change("status", value)}
              value={order_statuses.find(({ value }) => value === input.value)}
            />
          )}
          name='status'
        />
      </div>
    </Dashboard>
  );
};

const mapStateToProps = ({ order }) => ({
  id: order?.data?.id,
  initialValues: {
    status: order?.data?.status,
  },
});

const mapDispatchToProps = dispatch => ({
  updateOrder: (id, data) => dispatch(updateOrder(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "statuses-form", enableReinitialize: true })(StatusesPanel));
