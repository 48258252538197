import React, { useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import Editor from "react-markdown-editor-lite";
import { Dashboard } from "components";
import { updatePage } from "actions/pages";

const InputField = props => <Input {...props} />;

const Content = ({ updatePage, handleCancel, handleSubmit, pristine, change, data }) => {
  const [text, setText] = useState(data?.content);
  const submit = values =>
    updatePage(data.id, values).catch(err => {
      throw new SubmissionError(err);
    });
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Content", icon: "edit" }}
        footer={{
          buttons: (
            <>
              <Button type='cancel' onClick={handleCancel} />
              <Button
                disabled={pristine}
                type='save'
                text='Zapisz'
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form'>
          <Field component={InputField} type='hidden' name='content' />
          <Editor
            plugins={["header", "fonts", "link"]}
            value={text}
            config={{
              view: {
                menu: true,
                md: true,
                html: false,
                fullScreen: false,
                hideMenu: false,
              },
            }}
            style={{ height: "600px" }}
            onChange={el => {
              change("content", text);
              setText(el.text);
            }}
          />
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  ({ page }) => ({
    data: page?.data,
    initialValues: { content: page?.data?.content },
  }),
  dispatch => ({
    updatePage: (id, data) => dispatch(updatePage(id, data)),
  })
)(reduxForm({ form: "edit-page-content", enableReinitialize: true })(Content));
