import React from "react";
import { Dashboard } from "components";
import { connect } from "react-redux";

const Input = ({ label, value }) => (
  <div className='form-group'>
    <div className='inp-wrapper'>
      <label className='label-form'>{label}</label>
      <div className='inp-group'>
        <input type='text' value={value} readOnly />
      </div>
    </div>
  </div>
);

const ChannelProductBasicData = ({ channel_product }) => {
  return (
    <Dashboard header={{ icon: "info", title: "Podstawowe informacje" }}>
      <div className='dashboard__form'>
        <div className='row'>
          <div className='box-6'>
            <Input label='Nazwa produktu' value={channel_product?.name} />
            <div className='form-row grid-2'>
              <Input label='Sku' value={channel_product?.sku} />
              <Input label='Kategoria' value={"brak"} />
            </div>
            <div className='form-row grid-2'>
              <Input label='Producent' value={channel_product?.product?.producer?.name} />
              <Input label='Cena' value={channel_product?.price} />
            </div>
          </div>
          <div className='box-6'>
            <Input label='Bulletpoint 1' value={channel_product?.bullet_point_1} />
            <Input label='Bulletpoint 2' value={channel_product?.bullet_point_2} />
            <Input label='Bulletpoint 3' value={channel_product?.bullet_point_3} />
            <Input label='Bulletpoint 4' value={channel_product?.bullet_point_4} />
            <Input label='Bulletpoint 5' value={channel_product?.bullet_point_5} />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(({ channel_product }) => ({ channel_product: channel_product.data }))(
  ChannelProductBasicData
);
