import React from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";
import { client } from "utils/api";
import selectStyles from "theme/selectStyles";

const loadOptions = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        data: { data },
      } = await client.get(
        `/channel_taxons?page=1&per_page=20${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      resolve(
        data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

const AsyncTaxons = ({ change, input, initValue, label }) => {
  return (
    <div className={`form-group`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          styles={selectStyles}
          defaultOptions
          onChange={(item) => {
            change(input.name, item.value);
          }}
          cacheOptions
          placeholder={"Wybierz..."}
          loadOptions={debounce((a, b) => loadOptions(a, b), 500, {
            leading: false,
            accumulate: false,
          })}
          defaultValue={initValue}
        />
      </div>
    </div>
  );
};

export default AsyncTaxons;
