import React, { PureComponent } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, SubmissionError, formValueSelector } from "redux-form";
import { Button, Textarea, ImageUploader, Input, Checkbox } from "expano-components";
import Editor from "react-markdown-editor-lite";
import { Main, DatePicker, AsyncSelect, CreatableSelect } from "components";
import { createArticle } from "actions/articles";
import { client } from "utils/api";
import { getDate, toBase64 } from "utils/helpers";

const selector = formValueSelector("create-article");

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;
const SelectField = props => <AsyncSelect {...props} />;
const CheckField = props => <Checkbox {...props} />;

class ArticleNew extends PureComponent {
  state = {
    text: "",
    image: [],
  };
  submit = values => {
    const {
      history,
      match: { params },
    } = this.props;
    return createArticle(values)
      .then(({ data }) => {
        history.push(
          `/platforms/${params.platform_id}/channels/${params.channel_id}/articles/${data.id}`
        );
      })
      .catch(err => {
        console.log(err);
        throw new SubmissionError(err);
      });
  };
  loadOptionsAuthor = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const authors = await client.get(
          `authors?page=1&per_page=20${q ? `&q[first_name_or_last_name_matches]=%25${q}%25` : ""}`
        );
        const data = authors.data.data.map(({ id, attributes }) => ({
          label: `${attributes.first_name} ${attributes.last_name}`,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };

  handleUpload = file =>
    new Promise(async (resolve, reject) => {
      const file_data = await toBase64(file);
      this.setState({ image: [{ path: file_data }] });
      this.props.change("image_data", file_data);
      resolve();
    });

  componentDidMount = () => {
    this.props.change("meta_created_at", getDate(new Date()));
    this.props.change("meta_updated_at", getDate(new Date()));
  };

  render() {
    const {
      handleSubmit,
      pristine,
      history,
      change,
      channel_id,
      meta_created_at,
      meta_updated_at,
      match: { params },
    } = this.props;
    const { text, image } = this.state;
    return (
      <Main
        breadcrumbs={[
          {
            name: "Wpisy",
            path: `/platforms/${params.platform_id}/channels/${channel_id}/articles`,
            icon: "article",
          },
          { name: "Dodaj wpis" },
        ]}
      >
        <Helmet>
          <style>{`.image-uploader__box{ width: 100%; height: 200px; } .image-uploader{padding: 0;} .image-uploader__box{margin: 0; transition: none;}`}</style>
        </Helmet>
        <div className='dashboard'>
          <header className='dashboard__header'>
            <div className='icon__container'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                width='24px'
                height='24px'
                viewBox='0 0 24 24'
                version='1.1'
                className='kt-svg-icon'
              >
                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <rect x='0' y='0' width='24' height='24' />
                  <rect
                    className='svg-rect'
                    fill='#494B74'
                    x='4'
                    y='4'
                    width='7'
                    height='7'
                    rx='1.5'
                  />
                  <path
                    d='M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z'
                    fill='#494B74'
                    opacity='0.3'
                  />
                </g>
              </svg>
            </div>
            <h2 className='dashboard__title heading'>
              Dodaj wpis <span className='heading__text'>Nowy wpis</span>
            </h2>
            <Button
              type='cancel'
              text='Anuluj'
              style={{ marginRight: 20 }}
              onClick={() =>
                history.push(`/platforms/${params.platform_id}/channels/${channel_id}/articles`)
              }
            />
            <Button
              type='save'
              text='Zapisz'
              disabled={pristine}
              onClick={handleSubmit(this.submit)}
            />
          </header>
        </div>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <div className='dashboard-wrapper-flex'>
            <div className='dashboard'>
              <header className='dashboard__header dashboard__header--edit'>
                <div className='icon__container'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                    className='kt-svg-icon'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24' />
                      <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                      <rect
                        className='svg-rect'
                        fill='#000000'
                        x='11'
                        y='10'
                        width='2'
                        height='7'
                        rx='1'
                      />
                      <rect
                        className='svg-rect'
                        fill='#000000'
                        x='11'
                        y='7'
                        width='2'
                        height='2'
                        rx='1'
                      />
                    </g>
                  </svg>
                </div>
                <h2 className='dashboard__title heading'>Podstawowe informacje</h2>
              </header>
              <div className='dashboard__form'>
                <div className='dashboard-wrapper-flex'>
                  <div className='column-half'>
                    <Field name='title' label='Tytuł wpisu' component={InputField} type='text' />
                    <Field
                      label='Autor'
                      name='author_id'
                      component={SelectField}
                      getData={this.loadOptionsAuthor}
                      change={change}
                    />
                    <Field name='intro' label='Intro' component={TextareaField} minHeight={130} />
                  </div>
                  <div className='column-half'>
                    <DatePicker
                      value={meta_created_at ? new Date(meta_created_at) : null}
                      change={change}
                      name='meta_created_at'
                      label='Data publikacji'
                    />
                    <DatePicker
                      value={meta_updated_at ? new Date(meta_updated_at) : null}
                      change={change}
                      name='meta_updated_at'
                      label='Data aktualizacji'
                    />
                    <div className='form-group'>
                      <div className='inp-wrapper'>
                        <Field
                          name='image_attributes.file_data'
                          component={InputField}
                          type='hidden'
                        />
                        <label htmlFor='image_attributes.file_data'>Zdjęcie główne</label>
                        <ImageUploader
                          addText='dodaj grafikę'
                          url={""}
                          images={image}
                          limit={1}
                          handleUpload={this.handleUpload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='dashboard-wrapper-flex'>
            <div className='dashboard'>
              <header className='dashboard__header dashboard__header--edit'>
                <div className='icon__container'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                  >
                    <rect fill='none' width='20' height='20' />
                    <path
                      fill='#5d78ff'
                      d='M0,13.262V3.306a1.25,1.25,0,0,1,.375-.893L2.471.357A1.25,1.25,0,0,1,4.208.344l2.07,1.969a1.25,1.25,0,0,1,.389.906V13.262a1.25,1.25,0,0,1-1.25,1.25H1.25A1.25,1.25,0,0,1,0,13.262Z'
                      transform='translate(7.226 16.411) rotate(-135)'
                    />
                    <rect
                      fill='#5d78ff'
                      opacity='0.3'
                      width='13'
                      height='1'
                      rx='0.5'
                      transform='translate(4 17)'
                    />
                  </svg>
                </div>
                <h2 className='dashboard__title heading'>Edytor tekstu</h2>
              </header>
              <div className='dashboard__form'>
                <Field component={props => <Input {...props} />} type='hidden' name='content' />
                <Editor
                  plugins={["header", "fonts", "link"]}
                  value={text}
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  style={{ height: "280px" }}
                  onChange={({ text }) => {
                    change("content", text);
                    this.setState({ text });
                  }}
                />
              </div>
            </div>
          </div>

          <div className='dashboard-wrapper-flex'>
            <div className='dashboard'>
              <header className='dashboard__header dashboard__header--edit'>
                <div className='icon__container'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                    className='kt-svg-icon'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24' />
                      <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                      <rect
                        className='svg-rect'
                        fill='#000000'
                        x='11'
                        y='10'
                        width='2'
                        height='7'
                        rx='1'
                      />
                      <rect
                        className='svg-rect'
                        fill='#000000'
                        x='11'
                        y='7'
                        width='2'
                        height='2'
                        rx='1'
                      />
                    </g>
                  </svg>
                </div>
                <h2 className='dashboard__title heading'>SEO</h2>
              </header>
              <div className='dashboard__form'>
                <div className='form-row grid-2'>
                  <Field name='meta_title' label='Meta title' component={InputField} type='text' />
                  <Field name='slug' label='Slug URL' component={InputField} type='text' />
                </div>
                <Field name='meta_description' label='Meta description' component={TextareaField} />
                <Field name='keyword_list' component={InputField} type='hidden' />
                <Field name='tag_list' component={InputField} type='hidden' />

                <div className='form-row grid-2'>
                  <CreatableSelect
                    label='Keywords'
                    onChange={value => change("keyword_list", value)}
                  />
                  <CreatableSelect label='Tagi' onChange={value => change("tag_list", value)} />
                </div>
                <Field name='is_no_index' label='Article nieindeksowalny?' component={CheckField} />
              </div>
            </div>
          </div>
        </form>
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createArticle: data => dispatch(createArticle(data)),
});

export default connect(
  state => ({
    meta_created_at: selector(state, "meta_created_at"),
    meta_updated_at: selector(state, "meta_updated_at"),
    channel_id: state.channel?.data?.id,
  }),
  mapDispatchToProps
)(reduxForm({ form: "create-article", enableReinitialize: true })(withRouter(ArticleNew)));
