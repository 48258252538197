import React, { useState } from "react";
import Helmet from "react-helmet";
import { Dashboard } from "components";
import { ImageUploader } from "expano-components";

import { toBase64 } from "utils/helpers";

const Icon = ({ change }) => {
  const [images, setImage] = useState([]);

  const handleUploadImage = (file) => {
    return new Promise(async (resolve) => {
      const image_data = await toBase64(file);
      setImage([{ path: image_data }]);
      change("image_data", image_data);
      resolve();
    });
  };
  return (
    <Dashboard header={{ title: "Ikonka", icon: "info" }}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 130px; height: 130px; transition: none; margin: 0;}`}</style>
      </Helmet>
      <div className="dashboard__form">
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Ikona (100px x 100px)</label>
            <ImageUploader
              addText="dodaj ikonę"
              url={""}
              images={images}
              limit={1}
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Icon;
