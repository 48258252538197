const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANNEL_TAXON_RESET':
      return {
        status: 'invalid'
      }
    case 'CHANNEL_TAXON_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'CHANNEL_TAXON_SUCCESS':
      return {
        ...state,
        status: 'success',
        ...action.data
      }
    case 'CHANNEL_TAXON_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    default:
      return state
  }
}
