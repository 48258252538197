import React from "react";
import { Header, Footer } from "./components";
import StyledDashboard from "./styles";

const Dashboard = props => {
  return (
    <StyledDashboard no_margin={props.no_margin}>
      <Header {...props.header} />
      {props.children}
      {props?.footer && <Footer {...props.footer} />}
    </StyledDashboard>
  );
};

export default Dashboard;
