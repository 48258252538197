import React from "react";

const DiscountCodes = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M0,3.05V1A1,1,0,0,1,1,0H6V2A1,1,0,0,0,8,2V0H18a1,1,0,0,1,1,1V3.05a2.5,2.5,0,0,0,0,4.9V10a1,1,0,0,1-1,1H8V9A1,1,0,1,0,6,9v2H1a1,1,0,0,1-1-1V7.95A2.5,2.5,0,0,0,2,5.5,2.5,2.5,0,0,0,0,3.05ZM7,4A1,1,0,0,0,6,5V6A1,1,0,1,0,8,6V5A1,1,0,0,0,7,4Z'
      transform='translate(1.893 15.328) rotate(-45)'
    />
  </svg>
);
export default DiscountCodes;
