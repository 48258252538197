import { toastr } from "react-redux-toastr";
import { client_platform, apiFormatter, handleErrors } from "utils/api";

export const getPlatformTaxons = ({ page = 1, per_page = 10, query = null, loading = true }) => async dispatch => {
  loading && dispatch({ type: "PLATFORM_TAXONS_LOADING" });
  await client_platform
    .get(`/platform_taxons?q[ancestry_blank]=true`)
    .then(({ data }) => {
      dispatch({
        type: "PLATFORM_TAXONS_SUCCESS",
        data: { data: apiFormatter(data), meta: data.meta },
      });
    })
    .catch(() => {
      dispatch({ type: "PLATFORM_TAXONS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};
export const getPlatformTaxonByAncestry = ancestry => async dispatch => {
  return new Promise(async (resolve, reject) => {
    await client_platform
      .get(`/platform_taxons?q[ancestry_eq]=${ancestry}`)
      .then(({ data }) => {
        resolve(apiFormatter(data));
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });
};

export const getPlatformTaxon = id => async dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: "PLATFORM_TAXON_LOADING" });
    await client_platform
      .get(`/platform_taxons/${id}?include=parent`)
      .then(({ data }) => {
        dispatch({
          type: "PLATFORM_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(err => {
        dispatch({ type: "PLATFORM_TAXON_FAILURE" });
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });
};

export const createPlatformTaxon = data => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client_platform
      .post(`/platform_taxons`, data)
      .then(({ data }) => {
        dispatch({
          type: "PLATFORM_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        toastr.success("Sukces", "Dodano nową kategorie platformy");
        resolve();
      })
      .catch(err => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          toastr.error("Błąd", "Wystąpił błąd");

          console.log(error);
        }
      });
  });
};

export const updatePlatformTaxon = (id, data) => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client_platform
      .put(`/platform_taxons/${id}?include=parent`, data)
      .then(({ data }) => {
        dispatch({
          type: "PLATFORM_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        toastr.success("Sukces", "Zaktualizowano pomyślnie");
        resolve();
      })
      .catch(err => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          toastr.error("Błąd", "Wystąpił błąd");

          console.log(error);
        }
      });
  });
};

export const deletePlatformTaxon = id => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client_platform
      .delete(`/platform_taxons/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "PLATFORM_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        toastr.success("Sukces", "Usunięto pomyślnie");
        dispatch(getPlatformTaxons({}));
        resolve();
      })
      .catch(err => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          toastr.error("Błąd", "Wystąpił błąd");
          console.log(error);
        }
      });
  });
};

export const resetPlatformTaxonEdit = () => async dispatch => {
  dispatch({ type: "PLATFORM_TAXON_RESET" });
};
