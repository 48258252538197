import React from "react";

const Contact = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M17,2h1.676A2.514,2.514,0,0,1,21.19,4.514V16.246a2.514,2.514,0,0,1-2.514,2.514H17Z'
      transform='translate(-2.754 -0.324)'
    />
    <path
      fill='#5d78ff'
      d='M3.838,2H13.894a2.514,2.514,0,0,1,2.514,2.514V16.246a2.514,2.514,0,0,1-2.514,2.514H3.838A.838.838,0,0,1,3,17.922V2.838A.838.838,0,0,1,3.838,2ZM9.8,11.813a1.226,1.226,0,0,1-1.415.23,1.226,1.226,0,0,0-1.415.23L5.707,13.538a.419.419,0,0,0,.164.694l1.135.378a3.032,3.032,0,0,0,3.1-.732l3.351-3.351A2.451,2.451,0,0,0,13.919,7.7l-.53-1.06a.419.419,0,0,0-.671-.109L11.383,7.862a1.226,1.226,0,0,0-.23,1.415,1.226,1.226,0,0,1-.23,1.415Z'
      transform='translate(-0.486 -0.324)'
    />
  </svg>
);

export default Contact;
