import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Dashboard } from "components";
import { ImageUploader } from "expano-components";
import { updateCollection } from "actions/collections";
import { image_url } from "utils/api";
import { toBase64 } from "utils/helpers";

const Icon = ({ collection, data, updateCollection, handleSubmit, pristine, change, dispatch }) => {
  const init_image = collection?.icon_url ? [{ path: `${image_url}${collection.icon_url}` }] : [];
  const handleUploadImage = file => {
    return new Promise(async resolve => {
      const icon_data = await toBase64(file);
      await updateCollection(collection.id, { icon_data });
      resolve();
    });
  };

  return (
    <Dashboard header={{ title: "Ikonka", icon: "info" }}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 130px; height: 130px; transition: none; margin: 0;}`}</style>
      </Helmet>
      <div className='dashboard__form'>
        <div className='form-group'>
          <div className='inp-wrapper'>
            <label htmlFor=''>Ikona (100px x 100px)</label>
            <ImageUploader
              addText='dodaj ikonę'
              url={""}
              images={init_image}
              limit={1}
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

const mapDispatchToProps = dispatch => ({
  updateCollection: (id, data) => dispatch(updateCollection(id, data)),
});

export default connect(
  ({ collection }) => ({
    collection,
  }),
  mapDispatchToProps
)(Icon);
