import { toastr } from "react-redux-toastr";
import axios from "axios";
import { url } from "utils/api";
import { removeCookie, setCookie } from "utils/cookies";

export const handleLogin = ({ user: { email, password } }) => dispatch => {
  axios
    .post(`${url}/v1/users/sign_in`, { email, password }, { headers: { Token: "testing_token" } })
    .then(({ data: { auth_token } }) => {
      setCookie("token", auth_token).then(() => {
        window.location.pathname = "/";
      });
    })
    .catch(() => {
      toastr.error("Błąd", "Wystąpił błąd podczas logowania");
    });
};
export const handleLogout = () => {
  removeCookie("token").then(() => (window.location.pathname = "/"));
};
