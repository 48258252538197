import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import {
  getChannelProducers,
  filterChannelProducers,
  toggleFilters,
} from "actions/channel_producers";

const ChannelProducersList = ({
  channel_producers,
  toggleFilters,
  getChannelProducers,
  filterChannelProducers,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getChannelProducers({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getChannelProducers({ ...query, page: undefined });
    }
  }, [channel_producers.filters.query]);

  if (channel_producers.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = channel_producers;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_producers`;
  return (
    <Main breadcrumbs={[{ name: "Producenci" }]}>
      <Dashboard
        header={{
          icon: "producers",
          title: "Producenci",
        }}
      >
        <MainTable
          is_loading={
            channel_producers.status === "invalid" || channel_producers.status === "loading"
          }
          is_searching={channel_producers.status === "searching"}
          filters_open={channel_producers.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { name: "image", style: { width: 200 }, sortable: false },
            { label: "ID", name: "id" },
            { label: "Producent", name: "producer_name", sortable: false },
            { label: "Slug", name: "slug" },
            { label: "Status", name: "status" },
          ]}
          renderRow={({ id, status, producer, slug }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>
                <img
                  src={producer?.image_url}
                  alt=''
                  style={{ width: 50, height: 50, margin: "-10px 0 -15px" }}
                />
              </td>
              <td>{id}</td>
              <td>{producer?.name}</td>
              <td>{slug}</td>
              <td>{status === "active" ? "Aktywny" : "Nieaktywny"}</td>
            </tr>
          )}
          empty_text='Brak producentów'
          filtersAction={filterChannelProducers}
          filters={[
            {
              type: "input",
              label: "Nazwa producenta",
              name: "producer_name",
              search_type: "matches",
            },
            {
              type: "multi-select",
              label: "Status",
              search_type: "in",
              options: [
                { value: 0, label: "Aktywny" },
                { value: 1, label: "Nieaktywny" },
              ],
              name: "status",
            },
          ].map(item => ({ ...item, default_value: channel_producers.filters.values[item.name] }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getChannelProducers: data => dispatch(getChannelProducers(data)),
  filterChannelProducers: data => dispatch(filterChannelProducers(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ channel_producers }) => ({ channel_producers }),
  mapDispatchToProps
)(ChannelProducersList);
