import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Input, Button } from "expano-components";
import { Main, Dashboard } from "components";
import { createClient } from "actions/clients";

const InputField = props => <Input {...props} />;

const ClientNew = ({ handleSubmit, pristine, history, change, channel_id, match }) => {
  const submit = values => {
    return createClient(values)
      .then(({ data }) => {
        history.push(
          `/platforms/${match.params?.platform_id}/channels/${channel_id}/clients/${data.id}`
        );
      })
      .catch(err => {
        throw new SubmissionError(err);
      });
  };

  const handleCancel = () =>
    history.push(`/platforms/${match.params?.platform_id}/channels/${channel_id}/clients`);

  return (
    <Main
      breadcrumbs={[
        {
          name: "Klienci",
          path: `/platforms/${match.params?.platform_id}/channels/${channel_id}/clients`,
          icon: "producer",
        },
        { name: "Dodaj klienta" },
      ]}
    >
      <form onSubmit={handleSubmit(submit)} noValidate>
        <Dashboard
          header={{ title: "Podstawowe dane", icon: "client" }}
          footer={{
            buttons: (
              <>
                <Button type='cancel' onClick={handleCancel} />
                <Button type='save' onClick={handleSubmit(submit)} disabled={pristine} />
              </>
            ),
          }}
        >
          <div className='dashboard__form'>
            <div className='dashboard-wrapper-flex' style={{ alignItems: "center" }}>
              <div className='row'>
                <div className='box-3'>
                  <Field name='first_name' label='Imię' component={InputField} type='text' />
                </div>
                <div className='box-3'>
                  <Field name='last_name' label='Nazwisko' component={InputField} type='text' />
                </div>
                <div className='box-3'>
                  <Field name='email' label='Adres email' component={InputField} type='text' />
                </div>
                <div className='box-3'>
                  <Field name='password' label='Hasło' component={InputField} type='password' />
                </div>
              </div>
            </div>
          </div>
        </Dashboard>
      </form>
    </Main>
  );
};

export default connect(
  state => ({
    channel_id: state.channel.data.id,
  }),
  null
)(reduxForm({ form: "create-client", enableReinitialize: true })(ClientNew));
