import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "COLLECTIONS_TOGGLE_FILTERS" });
};

export const filterCollections = data => async dispatch => {
  dispatch({ type: "COLLECTIONS_SET_FILTERS_QUERY", data });
};

export const getCollections = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().collections;
  status === "invalid"
    ? dispatch({ type: "COLLECTIONS_LOADING" })
    : dispatch({ type: "COLLECTIONS_SEARCHING" });
  await client
    .get(`/collections?${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "COLLECTIONS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "COLLECTIONS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getCollection = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "COLLECTION_LOADING" });
    await client
      .get(`/collections/${id}?include=channel_products.channel_product_gallery_images`)
      .then(({ data }) => {
        dispatch({
          type: "COLLECTION_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "COLLECTION_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const updateCollection = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/collections/${id}?include=channel_products.channel_product_gallery_images`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano poroducenta");
        dispatch({
          type: "COLLECTION_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania");
        dispatch({ type: "COLLECTION_FAILURE" });
        reject();
      });
  });

export const createCollection = values =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/collections/`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Utworzono kolekcję");
        resolve(data);
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas tworzenia koleckji");
        reject();
      });
  });
