import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors } from "utils/api";

export const getSliders = () => async (dispatch) => {
  dispatch({ type: "SLIDERS_LOADING" });
  await client
    .get(`/sliders`)
    .then(({ data }) => {
      dispatch({
        type: "SLIDERS_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "SLIDERS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const deleteSlide = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/sliders/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "SLIDE_DELETE",
          id,
        });
        resolve();
      })
      .catch((err) => {
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};

export const updateSlide = (id, data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/sliders/${id}`, data)
      .then(({ data }) => {
        dispatch({
          type: "SLIDER_UPDATE",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          console.log(error);
        }
      });
  });
};

export const addSlide = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/sliders`, data)
      .then(({ data }) => {
        const formatted_data = apiFormatter(data);
        dispatch({
          type: "SLIDER_ADD",
          data: formatted_data,
        });
        resolve(formatted_data.id);
        toastr.success("Sukces", "Slide został dodany");
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          reject(handleErrors(err.response.data.errors));
        } else {
          reject(err);
        }
        toastr.error("Błąd", "Wystąpił błąd podczas dodawania");
      });
  });
};

export const moveSlide = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "SLIDERS_LOADING" });
    try {
      await client.put(`/sliders/${id}`, data);
      dispatch(getSliders());
      resolve();
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors));
    }
  });
