import React from "react";
import { connect } from "react-redux";
import { Button, Textarea, Input } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { Dashboard } from "components";
import { updateChannel } from "actions/channels";

const InputField = props => <Input {...props} />;
const TextAreaField = props => <Textarea {...props} />;

const DescForm = ({ pristine, channel, updateChannel, dispatch, handleSubmit }) => {
  const submit = values => updateChannel(values);
  const handleCancel = () => {
    dispatch(reset("contact-form"));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{ title: "Opis sklepu", icon: "description" }}
        footer={{
          buttons: (
            <>
              <Button type='cancel' onClick={handleCancel} />
              <Button
                type='save'
                disabled={pristine}
                text='Zapisz'
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form__submit'>
          <Field type='hidden' name='id' component={InputField} />
          <Field name='description' component={TextAreaField} />
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  ({ channel }) => ({
    initialValues: {
      id: channel?.data?.id,
      description: channel?.data?.description,
    },
  }),
  dispatch => ({
    updateChannel: data => dispatch(updateChannel(data)),
  })
)(
  reduxForm({
    form: "desc-form",
    enableReinitialize: true,
  })(DescForm)
);
