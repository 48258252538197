import React from "react";
import { connect } from "react-redux";
import { Button, Textarea, Input } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { Dashboard } from "components";
import { updateChannel } from "actions/channels";

const InputField = props => <Input {...props} />;
const TextAreaField = props => <Textarea {...props} />;

const ContactForm = ({ pristine, updateChannel, handleSubmit, dispatch }) => {
  const submit = values => updateChannel(values);
  const handleCancel = () => {
    dispatch(reset("contact-form"));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{ title: "Dane kontaktowe", icon: "contact" }}
        footer={{
          buttons: (
            <>
              <Button type='cancel' onClick={handleCancel} />
              <Button
                type='save'
                disabled={pristine}
                text='Zapisz'
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form__submit'>
          <Field type='hidden' name='id' component={InputField} />
          <Field type='text' label='Nazwa firmy' name='name' component={InputField} />
          <Field type='text' label='Adres e-mail' name='email' component={InputField} />
          <Field type='text' label='Telefon' name='phone' component={InputField} />
          <Field
            type='text'
            label='Infolinia czynna'
            name='helpline_hours'
            component={InputField}
          />
          <Field label='Adres sklepu' name='address' component={TextAreaField} />
          <Field type='text' label='Sklep czynny' name='opening_hours' component={InputField} />
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  ({ channel }) => ({
    initialValues: {
      id: channel?.data?.id,
      name: channel?.data?.name,
      email: channel?.data?.email,
      phone: channel?.data?.phone,
      opening_hours: channel?.data?.opening_hours,
      helpline_hours: channel?.data?.helpline_hours,
      address: channel?.data?.address,
    },
  }),
  dispatch => ({
    updateChannel: (id, data) => dispatch(updateChannel(id, data)),
  })
)(
  reduxForm({
    form: "contact-form",
    enableReinitialize: true,
  })(ContactForm)
);
