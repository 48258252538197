import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const Modal = ({ children, size = null }) => {
  const [is_active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 250);
  }, []);
  return ReactDOM.createPortal(
    <div className='popup-wrapper'>
      <div className={`popup ${size} ${is_active ? "active" : ""}`}>{children}</div>
    </div>,
    document.querySelector("#root")
  );
};

export default Modal;
