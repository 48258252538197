import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PageLoader, Main } from "components";
import { getCollection } from "actions/collections";
import { BasicData, ProductsList } from "./components";

const tabs = [
  { id: "basic_data", name: "Ogólne" },
  { id: "products", name: "Produkty" },
];

const CollectionEdit = ({
  getCollection,
  collection,
  history,
  match: { params },
}) => {
  useEffect(() => {
    getCollection(params.id);
  }, []);
  if (
    !collection ||
    collection.loading_status === "invalid" ||
    collection.loading_status === "loading"
  )
    return <PageLoader color='#2959F8' />;
  if (collection.loading_status === "failure") {
    return <p>Error</p>;
  }
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/collections`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Kolekcje",
          path,
        },
        {
          name: collection?.name,
        },
      ]}
    >
      <div className='dashboard-wrapper-flex'>
        <div className='dashboard dashboard--nav'>
          <ul className='dashboard__tabs'>
            {tabs.map(({ id, name }) => (
              <li
                key={name}
                onClick={() => history.push(`${path}/${params.id}/${id}`)}
                className={`tab dashboard-tab-text ${id === params.tab ? "active" : ""}`}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Switch>
        <Route
          exact={true}
          path='/platforms/:platform_id/channels/:channel_id/collections/:id/basic_data'
          component={BasicData}
        />
        <Route
          exact={true}
          path='/platforms/:platform_id/channels/:channel_id/collections/:id/products'
          component={ProductsList}
        />
        <Redirect to={`${path}/${params.id}/basic_data`} />
      </Switch>
    </Main>
  );
};

export default connect(
  ({ collection }) => ({
    collection,
  }),
  dispatch => ({
    getCollection: id => dispatch(getCollection(id)),
  })
)(CollectionEdit);
