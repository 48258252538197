import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors } from "utils/api";

export const getChannelTaxons = (
  page = 1,
  per_page = 10,
  query = null,
  loading = true
) => async (dispatch) => {
  loading && dispatch({ type: "CHANNEL_TAXONS_LOADING" });
  await client
    .get(`/channel_taxons?q[ancestry_blank]=true&per_page=100`)
    .then(({ data }) => {
      dispatch({
        type: "CHANNEL_TAXONS_SUCCESS",
        data: { data: apiFormatter(data), meta: data.meta },
      });
    })
    .catch(() => {
      dispatch({ type: "CHANNEL_TAXONS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};
export const getChannelTaxonByAncestry = (ancestry) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .get(`/channel_taxons?q[ancestry_eq]=${ancestry}&per_page=100`)
      .then(({ data }) => {
        resolve(apiFormatter(data));
      })
      .catch((err) => {
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });
};

export const getChannelTaxon = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: "CHANNEL_TAXON_LOADING" });
    await client
      .get(`/channel_taxons/${id}?include=parent`)
      .then(({ data }) => {
        dispatch({
          type: "CHANNEL_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch((err) => {
        dispatch({ type: "CHANNEL_TAXON_FAILURE" });
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });
};

export const updateChannelTaxon = (id, data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .patch(`/channel_taxons/${id}`, data)
      .then(({ data }) => {
        dispatch({
          type: "CHANNEL_TAXON_SUCCESS",
          data: apiFormatter(data),
        });
        toastr.success("Sukces", "Zaktualizowano pomyślnie");
        resolve();
      })
      .catch((err) => {
        console.log(err);
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          toastr.error("Błąd", "Wystąpił błąd");

          console.log(error);
        }
      });
  });
};

export const createChannelTaxon = (values) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/channel_taxons`, values)
      .then(({ data: { data } }) => {
        toastr.success("Sukces", "Kategoria została dodana");
        resolve(data.id);
      })
      .catch((err) => {
        try {
          reject(handleErrors(err.response.data.errors));
        } catch (error) {
          toastr.error("Błąd", "Wystąpił błąd");

          console.log(error);
        }
      });
  });
};

export const resetChannelTaxonEdit = () => async (dispatch) => {
  dispatch({ type: "CHANNEL_TAXON_RESET" });
};
