import React from "react";
import { connect } from "react-redux";
import { Button, Textarea, Input } from "expano-components";
import { Dashboard, CreatableSelect } from "components";
import { Field, reduxForm, reset } from "redux-form";
import { updateChannelTaxon } from "actions/channel_taxons";

const InputField = (props) => <Input {...props} />;
const TextareaField = (props) => <Textarea {...props} />;

const Seo = ({
  channel_taxon,
  pristine,
  handleSubmit,
  updateChannelTaxon,
  dispatch,
  initialValues,
  change,
}) => {
  const submit = (values) => updateChannelTaxon(channel_taxon.id, values);
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "SEO", icon: "info" }}
        footer={{
          buttons: (
            <>
              {!pristine && (
                <Button
                  type="cancel"
                  onClick={() => dispatch(reset("edit-channel-taxon-seo"))}
                />
              )}
              <Button
                type="save"
                text="Zapisz"
                disabled={pristine}
                onClick={handleSubmit(submit)}
              />
              ,
            </>
          ),
        }}>
        <div className="dashboard__form">
          <Field
            name="meta_title"
            label="Meta title"
            component={InputField}
            type="text"
          />
          <Field
            name="meta_description"
            label="Meta description"
            component={TextareaField}
          />
          <Field
            name="slug"
            label="Slug URL"
            component={InputField}
            type="text"
          />
          <Field name="keyword_list" component={InputField} type="hidden" />
          <Field name="tag_list" component={InputField} type="hidden" />

          <CreatableSelect
            label="Keywords"
            onChange={(value) => change("keyword_list", value)}
            defaultValue={
              initialValues.keyword_list?.length > 0
                ? initialValues.keyword_list.map((item) => ({
                    label: item,
                    value: item,
                  }))
                : []
            }
          />
          <CreatableSelect
            label="Tagi"
            onChange={(value) => change("tag_list", value)}
            defaultValue={
              initialValues.tag_list?.length > 0
                ? initialValues.tag_list.map((item) => ({
                    label: item,
                    value: item,
                  }))
                : []
            }
          />
        </div>
      </Dashboard>
    </form>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updateChannelTaxon: (id, data) =>
    dispatch(updateChannelTaxon(id, data)),
});
export default connect(
  ({ channel_taxon }) => ({
    channel_taxon,
    initialValues: {
      slug: channel_taxon?.slug,
      meta_title: channel_taxon?.meta_title,
      meta_description: channel_taxon?.meta_description,
      is_no_index: channel_taxon?.is_no_index,
      tag_list: channel_taxon.tag_list,
      keyword_list: channel_taxon?.keyword_list,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-channel-taxon-seo",
    enableReinitialize: true,
  })(Seo)
);
