import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { Dashboard, MainTable, ProductSaleStatus } from "components";
import { Button, ModalDelete } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getCollectionProducts,
  filterCollectionProducts,
  toggleFilters,
  deleteCollectionProduct,
} from "actions/collection_products";
import ProductsModal from "./components/ProductsModal";

const CollectionProductsProductsList = ({
  collection_products,
  getCollectionProducts,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [is_shown_products_modal, handleShowProductsModal] = useState(false);
  const [
    is_shown_delete_products_modal,
    handleShowDeleteProductsModal,
  ] = useState(false);
  const [products_checked, setProductChecked] = useState([]);

  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getCollectionProducts({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getCollectionProducts({ ...query, page: undefined }, params.id);
    }
  }, [collection_products.filters.query]);

  const handleReload = () =>
    getCollectionProducts({ ...query, page: undefined }, params.id);

  const handleDeleteCollections = () => {
    const promises = products_checked.map((id) => deleteCollectionProduct(id));
    Promise.all(promises).then(() => {
      setProductChecked([]);
      toastr.success("Sukces", "Produkty zostały usunięte");
      handleReload();
      handleShowDeleteProductsModal(false);
    });
  };

  if (collection_products.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = collection_products;
  return (
    <Dashboard
      header={{
        icon: "products",
        title: "Produkty",
      }}>
      {is_shown_products_modal && (
        <ProductsModal
          handleClose={() => handleShowProductsModal(false)}
          handleReload={handleReload}
        />
      )}
      {is_shown_delete_products_modal && (
        <ModalDelete
          name="zaznaczone produkty"
          handleClose={() => handleShowDeleteProductsModal(false)}
          handleDelete={handleDeleteCollections}
        />
      )}
      <MainTable
        is_loading={
          collection_products.status === "invalid" ||
          collection_products.status === "loading"
        }
        is_searching={collection_products.status === "searching"}
        filters_open={collection_products.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        buttons={
          <>
            {products_checked.length > 0 && (
              <Button
                type="delete"
                text="Usuń zaznaczone"
                onClick={() => handleShowDeleteProductsModal(true)}
              />
            )}
            <Button
              type="add"
              text="Dodaj produkt"
              onClick={() => handleShowProductsModal(true)}
            />
          </>
        }
        head={[
          {
            label: "check_all",
            name: "check_all",
            checked:
              data?.length > 0
                ? data?.length === products_checked.length
                : false,
            onChange: ({ target: { checked } }) =>
              checked
                ? setProductChecked(data.map(({ id }) => id))
                : setProductChecked([]),
          },
          { label: "", name: "image", sortable: false, style: { width: 70 } },
          { label: "ID", name: "channel_products_id", sortable: false },
          { label: "SKU", name: "sku", sortable: false },
          { label: "Nazwa", name: "name", sortable: false },
          { label: "Producent", name: "producer", sortable: false },
          { label: "Status", name: "sales_status", sortable: false },
        ]}
        renderRow={({
          id,
          channel_product: {
            id: channel_product_id,
            name,
            sku,
            sales_status,
            product,
            channel_product_gallery_images,
          },
        }) => (
          <tr
            key={id}
            onClick={() => {
              const checked = products_checked.find((item) => item === id);
              !checked
                ? setProductChecked([...products_checked, id])
                : setProductChecked(
                    products_checked.filter((item) => item !== id)
                  );
            }}>
            <td>
              <div className="check-group" style={{ margin: 0 }}>
                <input
                  id={`channel_product_collections_${id}`}
                  type="checkbox"
                  checked={!!products_checked.find((item) => item === id)}
                />
                <label
                  htmlFor={`gdn_${id}`}
                  className="label-form"
                  style={{ minHeight: 16, margin: 0, display: "block" }}
                />
              </div>
            </td>
            <td>
              <img
                src={channel_product_gallery_images?.[0]?.url}
                alt={name}
                style={{ width: 50, height: 50, margin: "-10px 0 -15px" }}
              />
            </td>
            <td>{channel_product_id}</td>
            <td>{sku || product?.sku}</td>
            <td>{name || product?.name}</td>
            <td>{product?.producer?.name || "-"}</td>
            <td>
              <ProductSaleStatus status={sales_status} />
            </td>
          </tr>
        )}
        empty_text="Brak produktów"
      />
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCollectionProducts: (data, id) =>
    dispatch(getCollectionProducts(data, id)),
  filterCollectionProducts: (data) => dispatch(filterCollectionProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ collection_products }) => ({
    collection_products,
  }),
  mapDispatchToProps
)(withRouter(CollectionProductsProductsList));
