import axios from "axios";
import Jsona from "jsona";
import queryString from "query-string";
import { getCookie } from "utils/cookies";
import { handleLogout } from "actions/account";
const token = getCookie("token");
axios.defaults.headers.common["Authorization"] = token;

const is_production = process.env.NODE_ENV === "production";

const dataFormatter = new Jsona();
export const url = is_production ? "https://api.wsf.tradesk.pl" : "http://localhost:3000";

export const image_url = is_production ? "" : "http://localhost:3000";

const api_url = `${url}/v1/admin/`;
export let client = null;
export let client_platform = null;

export const apiFormatter = data => {
  return dataFormatter.deserialize(data);
};

export const handleErrors = data => {
  try {
    const errors = {};
    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[pointer.split("/")[pointer.split("/").length - 1]] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const authorizeUser = token => {
  client = axios.create({ baseURL: api_url });
  client.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      errorResponseHandler(error);
      return Promise.reject(error);
    }
  );
  client_platform = axios.create({ baseURL: api_url });
};

export const parseQuery = data => queryString.parse(data, { arrayFormat: "bracket" });
export const stringifyQuery = data => queryString.stringify(data, { arrayFormat: "bracket" });

const errorResponseHandler = error => {
  const status_code = error?.response?.status;
  if (status_code === 401) {
    handleLogout();
  }
};
