import { toastr } from "react-redux-toastr";
import axios from "axios";
import { handleLogout } from "actions/account";
import { client, url, client_platform, apiFormatter } from "utils/api";

export const getPrimaryData = ({ params }) => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "APP_LOADING" });
    const { platform_id, channel_id } = params;
    try {
      const { data } = await axios.get(`${url}/v1/admin/platforms?include=channels`);
      const platforms = apiFormatter(data);

      let channel = null;
      let platform = null;
      let redirect = false;
      const platform_by_id = platforms.find(({ id }) => id === platform_id);
      if (platform_by_id) {
        platform = platform_by_id;
      } else if (platforms?.length > 0) {
        redirect = true;
        platform = platforms[0];
      }

      if (platform) {
        const channel_by_id = platform.channels.find(({ id }) => id === channel_id);
        if (channel_by_id) {
          channel = channel_by_id;
        } else if (platform?.channels?.length > 0) {
          redirect = true;
          channel = platform.channels[0];
        }
      }
      client.interceptors.request.use(
        config => {
          config.baseURL = `${url}/v1/admin/channels/${channel.id}`;
          return config;
        },
        error => Promise.reject(error)
      );
      client_platform.interceptors.request.use(
        config => {
          config.baseURL = `${url}/v1/admin/platforms/${platform_id}`;
          return config;
        },
        error => Promise.reject(error)
      );
      dispatch({
        type: "CHANNELS_SUCCESS",
        data: platform?.channels,
      });
      dispatch({
        type: "CHANNEL_SUCCESS",
        data: channel,
      });
      dispatch({
        type: "PLATFORM_SUCCESS",
        data: platform,
      });
      dispatch({
        type: "PLATFORMS_SUCCESS",
        data: platforms,
      });
      dispatch({
        type: "APP_SUCCESS",
      });
      resolve({ redirect, channel_id: channel?.id, platform_id: platform?.id });
    } catch (error) {
      const status_code = error?.response?.status;
      dispatch({ type: "APP_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      reject(error);
      if (status_code === 401) {
        handleLogout();
      }
    }
  });
