import React from "react";
import { Main } from "components";
import BasicData from "./components/BasicData";

const DiscountNew = ({ match: { params } }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discounts`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Promocje",
          path,
        },
        {
          name: "Dodaj promocję",
        },
      ]}
    >
      <BasicData path={path} />
    </Main>
  );
};

export default DiscountNew;
