import React from "react";

const Client = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.255'
    height='20.255'
    viewBox='0 0 20.255 20.255'
  >
    <path
      fill='#5d78f9'
      opacity='0.3'
      d='M11.376,9.752a3.376,3.376,0,1,1,3.376-3.376A3.376,3.376,0,0,1,11.376,9.752Z'
      transform='translate(-1.248 -0.468)'
    />
    <path
      fill='#5d78f9'
      d='M3,19.076C3.328,15.048,6.6,13,10.582,13c4.041,0,7.361,1.935,7.608,6.077a.58.58,0,0,1-.634.675H3.614A.934.934,0,0,1,3,19.076Z'
      transform='translate(-0.468 -2.028)'
    />
  </svg>
);

export default Client;
