const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PLATFORM_TAXON_RESET':
      return {
        status: 'invalid'
      }
    case 'PLATFORM_TAXON_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'PLATFORM_TAXON_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    case 'PLATFORM_TAXON_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    default:
      return state
  }
}
