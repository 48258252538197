import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";

const methods = {
  "ShippingMethod::PickupPoint": "Odbiór w punkcie",
  "ShippingMethod::DomesticCourier": "Kurier krajowy",
  "ShippingMethod::ForeignCourier": "Kurier zagraniczny",
};
const ShipmentPanel = ({ shipping_method, shipping_status, order }) => {
  return (
    <Dashboard header={{ title: "Dostawa", icon: "shipment" }}>
      <div className='row-info'>
        <span className='row-info__label'>
          {shipping_method?.type ? methods[shipping_method.type] : "-"}
        </span>
      </div>
      <div className='row-info'>
        <span className='box row-info__label bold'>Kurier</span>
        <span className='box row-info__label bold'>Stawka</span>
        <span className='box row-info__label bold'>Czas dostawy (dni)</span>
      </div>
      <div className='row-info'>
        <span className='box row-info__label'>{order?.data?.shipping_method?.speditor?.name}</span>
        <span className='box row-info__label'>{shipping_method?.price}</span>
        <span className='box row-info__label'>{shipping_method?.delivery_days || "-"}</span>
      </div>
    </Dashboard>
  );
};

export default connect(({ order }) => ({
  order,
  shipping_method: order?.data?.shipping_method,
  shipping_status: order?.data?.shipping_status,
}))(ShipmentPanel);
