import { createGlobalStyle } from "styled-components";

const spaces = [
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100,
];
const sides = ["top", "bottom", "left", "right"];

const GlobalStyles = createGlobalStyle`
.wrapper {
  display: flex;
  &.jc-sb {
    justify-content: space-between;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
  &.jc-fe {
    justify-content: flex-end;
  }
  &.ai-c {
    align-items: center;
  }
}
.block {
  padding: 20px 30px;
  flex: 1;
  &.flex-2{
    flex:2;
  }
}
.row {
  display: flex;
  width: calc(100% + 20px);
  margin-left: -10px;
  [class^="box"] {
    padding: 0 10px;
  }
  .box-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .box-11{
   flex-basis: 91.666667%;
    max-width: 91.666667%;
  }
  .box-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .box-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .box-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .box-7 {
    flex-basis: 58.333333%;
    max-width: 58.333333%;
  }
  .box-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .box-5 {
    flex-basis: 41.666667%;
    max-width: 41.666667%;
  }
  .box-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .box-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .box-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .box-1 {
    flex-basis: 8.333333%;
    max-width: 8.333333%;
  }
}
.text-link{
  color: rgb(93,120,255);
  &:hover{
    text-decoration: underline;
  }
}
${spaces.map((space) =>
  sides.map(
    (side) => `   
    .m-${side.charAt(0)}-${space} {
      margin-${side}: ${space}px !important;
    }
    .m-${space} {
      margin: ${space}px !important;
    }
    .p-${side.charAt(0)}-${space} {
      padding-${side}: ${space}px !important;
    }
    .p-${space} {
      padding: ${space}px !important;
    }`
  )
)}

.form-group:not(:last-child){
  margin-bottom: 25px;
}

//typography

.text-default{
  font-size: 13px;
  line-height: 20px;
  color: #595D6E;
}
.text-muted{
  color: rgba(72, 70, 91, 0.5);
}
`;

export default GlobalStyles;
