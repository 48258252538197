import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Label } from "expano-components";
import { PageLoader, Dashboard, Main } from "components";
import {
  ClientPanel,
  ShipmentPanel,
  PaymentPanel,
  NotePanel,
  StatusesPanel,
  LogsPanel,
  SummaryPanel,
  ProductsPanel,
} from "./components";
import { getOrder } from "actions/orders";
import { getStatus } from "utils/statuses";

const OrderEdit = ({ order, match: { params }, getOrder, channel_id }) => {
  useEffect(() => {
    getOrder(params?.id);
  }, []);
  if (order.status === "invalid" || order.status === "loading") return <PageLoader />;
  if (order.status === "failure") {
    return <p>Error</p>;
  }
  const { data } = order;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/orders`;

  return (
    <Main breadcrumbs={[{ name: "Zamówienia", path }, { name: data.number }]}>
      <Dashboard header={{ title: "Edycja zamówienia", icon: "orders" }}>
        <div className='order-details'>
          <div className="order-details__date-group">
            <p className='order-details__date'>Utworzono: {data.created_at}</p>
            <p className='order-details__date'>Zamówiono: {data.ordered_at}</p>
          </div>
          <Label
            type='fill'
            color={
              data.status === "canceled"
                ? "#fd397a"
                : data.status === "cart" || data.status === "checkout"
                ? "#FFB82B"
                : "#0ABBBB"
            }
            text={getStatus(data.status)}
          />
        </div>
      </Dashboard>

      <div className='order-columns'>
        <div className='column-left'>
          <StatusesPanel />
          <ProductsPanel />
          <SummaryPanel />
          <LogsPanel />
        </div>
        <div className='column-right'>
          <ClientPanel />
          <ShipmentPanel />
          <PaymentPanel />
          <NotePanel />
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ order, channel }) => ({
  channel_id: channel.data.id,
  order,
});

const mapDispatchToProps = dispatch => ({
  getOrder: id => dispatch(getOrder(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderEdit);
