const getStatus = (status) => {
  switch (status) {
    case "active":
      return { name: "Aktywny", color: "success" };
    case "inactive":
      return { name: "Nieaktywny", color: "danger" };
    default:
      return null;
  }
};

export default getStatus;
