const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SLIDERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "SLIDERS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "SLIDERS_FAILURE":
      return {
        ...state,
        status: "failure",
      };

    case "SLIDER_ADD": {
      return {
        ...state,
        data: [...state.data, action.data],
      };
    }
    case "SLIDER_UPDATE":
      try {
        const new_data = [...state.data];
        const index = new_data.indexOf(new_data.find(({ id }) => id === action.data.id));
        new_data[index] = action.data;
        return {
          ...state,
          data: new_data,
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    case "SLIDE_DELETE":
      try {
        return {
          ...state,
          data: state.data.filter(({ id }) => id !== action.id),
        };
      } catch (error) {
        console.log(error);
        return state;
      }

    default:
      return state;
  }
};
