import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dashboard, MainTable } from "components";
import { getClientOrders } from "actions/clients";
import { getStatus } from "utils/statuses";
import { parseQuery } from "utils/api";

const ClientOrders = ({
  client,
  client_orders,
  author_posts,
  getClientOrders,
  history,
  location,
  match: { params },
  channel_id,
}) => {
  useEffect(() => {
    getClientOrders({ ...query, ...sort }, client.data.id);
  }, [location.search]);
  const query = parseQuery(location.search);

  const { data, meta } = client_orders;
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };
  return (
    <Dashboard header={{ title: "Historia zamówień", icon: "orders_history" }}>
      <MainTable
        is_loading={client_orders.status === "invalid" || client_orders.status === "loading"}
        is_searching={client_orders.status === "searching"}
        data={data}
        sort={sort}
        meta={meta}
        head={[
          { label: "Numer zamówienia", name: "id" },
          { label: "Data", name: "created_at" },
          { label: "Status realizacji", name: "status" },
          { label: "Kwota", name: "total_price" },
        ]}
        renderRow={({ id, total_price, payment_status, number, status, ordered_at }) => (
          <tr
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/platforms/${params.platform_id}/channels/${channel_id}/orders/${id}`)
            }
          >
            <td>{number || "-"}</td>
            <td>{ordered_at || "-"}</td>
            <td>{getStatus("payment", payment_status)}</td>
            <td>{getStatus(status)}</td>
            <td>{`${total_price} zł` || "-"}</td>
          </tr>
        )}
        empty_text='Brak zamówień'
      />
    </Dashboard>
  );
};

const mapStateToProps = ({ client, channel, client_orders }) => ({
  channel_id: channel.data.id,
  client_orders,
  client,
});

const mapDispatchToProps = dispatch => ({
  getClientOrders: (page, per_page, id) => dispatch(getClientOrders(page, per_page, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientOrders));
