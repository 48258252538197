const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "BANNERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "BANNERS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "BANNERS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "BANNER_ADD": {
      return {
        ...state,
        data: [...state.data, action.data],
      };
    }
    case "BANNER_UPDATE":
      try {
        const new_data = [...state.data];
        const index = new_data.indexOf(new_data.find(({ id }) => id === action.data.id));
        new_data[index] = action.data;
        return {
          ...state,
          data: new_data,
        };
      } catch (error) {
        console.log(error);
        return state;
      }

    default:
      return state;
  }
};
