import React from "react";

const Wallet = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <ellipse
      fill='#5d78f9'
      opacity='0.3'
      cx='1'
      cy='1.5'
      rx='1'
      ry='1.5'
      transform='translate(16 9)'
    />
    <rect
      fill='#5d78f9'
      opacity='0.3'
      width='15'
      height='5.833'
      rx='1'
      transform='matrix(0.966, -0.259, 0.259, 0.966, 2.001, 4.541)'
    />
    <path
      fill='#5d78f9'
      d='M18.667,8.447a2.917,2.917,0,1,0,0,5.272v1.947A1.667,1.667,0,0,1,17,17.333H3.667A1.667,1.667,0,0,1,2,15.667v-10A1.667,1.667,0,0,1,3.667,4H17a1.667,1.667,0,0,1,1.667,1.667Z'
      transform='translate(-0.333 -0.667)'
    />
  </svg>
);

export default Wallet;
