import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Textarea, Input } from "expano-components";
import Editor from "react-markdown-editor-lite";
import { Dashboard, Main, ActionTop } from "components";
import { createPage } from "actions/pages";
import { Page } from "icons";
const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

const PageNew = ({ change, pristine, handleSubmit, history, match: { params } }) => {
  const [text, setText] = useState("");
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/pages`;

  const handleCancel = () => history.push(path);
  const submit = values =>
    createPage({ ...values })
      .then(({ data }) => {
        history.push(`${path}/${data.id}`);
      })
      .catch(err => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      breadcrumbs={[
        {
          name: "Strony statyczne",
          path,
          icon: "static_pages",
        },
        { name: "Dodaj stronę" },
      ]}
    >
      <ActionTop
        icon={<Page />}
        title='Dodaj stronę'
        subtitle='Nowa strona statyczna'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit(submit)}
        disabled={pristine}
      />
      <form onSubmit={handleSubmit(submit)} noValidate>
        <Dashboard header={{ title: "Podstawowe informacje", icon: "info" }}>
          <div className='dashboard__form'>
            <Field
              name='title'
              label='Nazwa strony statycznej'
              component={InputField}
              type='text'
            />
            <Field name='intro' label='Intro' component={InputField} type='text' />
          </div>
        </Dashboard>

        <div className='dashboard-wrapper-flex'>
          <div className='column-2'>
            <Dashboard header={{ title: "Content", icon: "edit" }}>
              <div className='dashboard__form'>
                <Field component={props => <Input {...props} />} type='hidden' name='content' />
                <Editor
                  plugins={["header", "fonts", "link"]}
                  value={text}
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  style={{ height: "280px" }}
                  onChange={el => {
                    change("content", text);
                    setText(el.text);
                  }}
                />
              </div>
            </Dashboard>
          </div>
          <div className='column-1'>
            <Dashboard header={{ title: "SEO", icon: "info" }}>
              <div className='dashboard__form'>
                <Field name='meta_title' label='Meta title' component={InputField} type='text' />
                <Field name='slug' label='Slug URL' component={InputField} type='text' />
                <Field name='meta_description' label='Meta description' component={TextareaField} />
              </div>
            </Dashboard>
          </div>
        </div>
      </form>
    </Main>
  );
};

export default connect(
  ({ channel }) => ({ channel_id: channel.data.id }),
  null
)(reduxForm({ form: "create-page", enableReinitialize: true })(withRouter(PageNew)));
