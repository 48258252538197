import React, { useState } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { toastr } from "react-redux-toastr";
import { Button, ImageUploader, Input } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { updateBanner } from "actions/banners";
import { toBase64 } from "utils/helpers";
import { image_url } from "utils/api";

const InputField = (props) => <Input {...props} input={{ ...props.input }} />;

const Form = ({
  pristine,
  change,
  submitting,
  handleSubmit,
  updateBanner,
  id,
  data,
  dispatch,
  form,
}) => {
  const init_image = data?.image_url
    ? [{ path: `${image_url}${data.image_url}` }]
    : [];
  const [image, setImage] = useState(init_image);
  const submit = async (data) => {
    updateBanner(id, data)
      .then(() => {
        toastr.success("Sukces", "Zaktualizowano pomyślnie");
        dispatch(reset(form));
      })
      .catch(() => toastr.error("Error", "Wystąpił błąd"));
  };

  const handleCancel = () => {
    setImage(init_image);
    dispatch(reset(form));
  };

  const handleUploadImage = (file) => {
    return new Promise(async (resolve) => {
      const file_data = await toBase64(file);
      setImage([{ path: file_data }]);
      change("image_data", file_data);
      resolve();
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 300px; height: 180px; transition: none; margin: 0;}`}</style>
      </Helmet>
      <div className="dashboard__form__submit">
        <Field type="text" label="URL" name="url" component={InputField} />
        <div className="dashboard-wrapper-flex" style={{ paddingTop: 20 }}>
          <div className="column-half">
            <Field
              label="Nazwa"
              type="text"
              name="name"
              component={InputField}
            />
            <Field type="hidden" name="image_data" component={InputField} />
          </div>
          <div className="column-half">
            <div className="form-group">
              <div className="inp-wrapper">
                <label htmlFor="">Grafika</label>
                <ImageUploader
                  addText="dodaj logo"
                  url={""}
                  images={image}
                  limit={1}
                  handleUpload={handleUploadImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard__form__footer">
        {!pristine && (
          <Button
            type="cancel"
            style={{ marginRight: 15 }}
            onClick={handleCancel}
          />
        )}
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || submitting}
          onClick={handleSubmit(submit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateBanner: (id, data) => dispatch(updateBanner(id, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    enableReinitialize: true,
  })(Form)
);
