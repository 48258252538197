const initialState = {
  status: "invalid",
  filters: {
    open: true,
    values: {},
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "COLLECTIONS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open },
      };
    case "COLLECTIONS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, ...action.data },
      };
    case "COLLECTIONS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "COLLECTIONS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "COLLECTIONS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "COLLECTIONS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
