import React from "react";
import { Main } from "components";
import BasicData from "./components/BasicData";

const DiscountCodeNew = ({ match: { params } }) => {
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discount_codes`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Kody rabatowe",
          path,
        },
        {
          name: "Dodaj kod rabatowy",
        },
      ]}
    >
      <BasicData path={path} />
    </Main>
  );
};

export default DiscountCodeNew;
