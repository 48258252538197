import React from "react";

const OrderStatus = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M7.392,3v.424A1.272,1.272,0,0,0,8.663,4.7H12.9a1.272,1.272,0,0,0,1.272-1.272V3h1.7a1.7,1.7,0,0,1,1.7,1.7V18.262a1.7,1.7,0,0,1-1.7,1.7H5.7a1.7,1.7,0,0,1-1.7-1.7V4.7A1.7,1.7,0,0,1,5.7,3Z'
      transform='translate(-0.608 -0.456)'
    />
    <path
      fill='#5d78ff'
      d='M10.438,14.875a.8.8,0,0,1-.569-.244L8.244,13.006a.8.8,0,0,1,1.138-1.138l1.056,1.056,2.681-2.681a.8.8,0,1,1,1.138,1.138l-3.25,3.25A.8.8,0,0,1,10.438,14.875Z'
      transform='translate(-1.217 -1.521)'
    />
    <path
      fill='#5d78ff'
      d='M10.7,1.848a.848.848,0,1,1,1.7,0h1.272a.424.424,0,0,1,.424.424V3.12a.424.424,0,0,1-.424.424H9.424A.424.424,0,0,1,9,3.12V2.272a.424.424,0,0,1,.424-.424Z'
      transform='translate(-1.369 -0.152)'
    />
  </svg>
);

export default OrderStatus;
