import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "ORDERS_TOGGLE_FILTERS" });
};

export const filterOrders = data => async dispatch => {
  dispatch({ type: "ORDERS_SET_FILTERS_QUERY", data });
};

export const getOrders = ({ page = 1, per_page = 10, ...rest }, statuses) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().orders;
  status === "invalid"
    ? dispatch({ type: "ORDERS_LOADING" })
    : dispatch({ type: "ORDERS_SEARCHING" });
  await client
    .get(
      `/orders?include=client&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}&${statuses.map(item => `q[status_in][]=${item}`).join("&")}`
    )
    .then(({ data }) => {
      dispatch({
        type: "ORDERS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "ORDERS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getOrder = id => async dispatch => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    const { data } = await client.get(
      `/orders/${id}?include=discount_code,client.client_billing_address,client.client_shipping_address,payment_method,shipping_method.speditor,order_lines.channel_product.channel_product_gallery_images`
    );
    dispatch({
      type: "ORDER_SUCCESS",
      data: apiFormatter(data),
    });
  } catch (error) {
    dispatch({ type: "ORDER_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
  }
};
export const updateOrder = (id, values) => async dispatch => {
  try {
    const { data } = await client.patch(
      `/orders/${id}?include=client.client_billing_address,client.client_shipping_address,payment_method,shipping_method.speditor,order_lines.channel_product.channel_product_gallery_images`,
      values
    );
    dispatch({
      type: "ORDER_SUCCESS",
      data: apiFormatter(data),
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania");
  }
};
