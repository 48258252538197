import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const PriceHistory = ({
  channel_product_daily_prices,
  daily_lowest_price,
}) => {
  return (
    <div className="dashboard-wrapper-flex">
      <div className="column-half">
        <div className="dashboard">
          <header className="dashboard__header dashboard__header--edit">
            <h2 className="dashboard__title heading">
              Historia cen
            </h2>
            <p>
              Najnisza cena:{" "}
              <strong>{daily_lowest_price}</strong>
            </p>
          </header>
          <table className="table">
            <thead className="table__header table-head">
              <tr>
                <th>Data</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody className="table__body table-body">
              {channel_product_daily_prices?.length > 0 &&
                channel_product_daily_prices.map(
                  ({ id, date, price }) => (
                    <tr key={id}>
                      <td>{date? moment(date).format('YYYY-MM-DD') :'-'}</td>
                      <td>{price}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    channel_product: {
      data: {
        daily_lowest_price,
        channel_product_daily_prices,
      },
    },
  }) => ({
    daily_lowest_price,
    channel_product_daily_prices,
  })
)(PriceHistory);
