import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Dashboard } from "components";

const Icon = ({ channel_producer }) => {
  return (
    <Dashboard header={{ title: "Logo", icon: "info" }}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 130px; height: 130px; transition: none; margin: 0;}`}</style>
      </Helmet>
      <div className="dashboard__form">
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Pobrane z roota</label>
            <div class="image-uploader">
              <div class="image-uploader__box">
                <img
                  alt={`${channel_producer?.producer?.name}`}
                  src={channel_producer?.producer?.image_url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(({ channel_producer }) => ({
  channel_producer,
}))(Icon);
