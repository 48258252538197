import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { MainTable, ProductSaleStatus } from "components";
import { Button, ModalDelete } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getDiscountProducts,
  filterDiscountProducts,
  toggleFilters,
  deleteDiscountProduct,
} from "actions/discount_products";
import ProductsModal from "./components/ProductsModal";
import DiscountReferenceTypeSelect from "../DiscountReferenceTypeSelect";

const DiscountProductsList = ({
  discount_products,
  getDiscountProducts,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [is_shown_products_modal, handleShowProductsModal] = useState(false);
  const [
    is_shown_delete_products_modal,
    handleShowDeleteProductsModal,
  ] = useState(false);
  const [products_checked, setProductChecked] = useState([]);

  const sort = {
    "sort[column]": query["sort[column]"] || "channel_product_id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscountProducts({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscountProducts({ ...query, page: undefined }, params.id);
    }
  }, [discount_products.filters.query]);

  const handleReload = () =>
    getDiscountProducts({ ...query, ...sort, page: undefined }, params.id);

  const handleDeleteProducts = () => {
    const promises = products_checked.map((id) => deleteDiscountProduct(id));
    Promise.all(promises).then(() => {
      setProductChecked([]);
      toastr.success("Sukces", "Produkty zostały usunięte");
      handleReload();
      handleShowDeleteProductsModal(false);
    });
  };

  if (discount_products.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = discount_products;
  return (
    <>
      {is_shown_products_modal && (
        <ProductsModal
          handleClose={() => handleShowProductsModal(false)}
          handleReload={handleReload}
        />
      )}
      {is_shown_delete_products_modal && (
        <ModalDelete
          name="zaznaczone produkty"
          handleClose={() => handleShowDeleteProductsModal(false)}
          handleDelete={handleDeleteProducts}
        />
      )}
      <MainTable
        is_loading={
          discount_products.status === "invalid" ||
          discount_products.status === "loading"
        }
        is_searching={discount_products.status === "searching"}
        filters_open={discount_products.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        buttons={
          <div className="wrapper jc-sb al-fe" style={{ width: "100%" }}>
            <div style={{ flexBasis: 230, maxWidth: 230 }}>
              <DiscountReferenceTypeSelect />
            </div>
            <div className="wrapper">
              {products_checked.length > 0 && (
                <Button
                  type="delete"
                  text="Usuń zaznaczone"
                  onClick={() => handleShowDeleteProductsModal(true)}
                />
              )}
              <Button
                type="add"
                text="Dodaj produkt"
                onClick={() => handleShowProductsModal(true)}
              />
            </div>
          </div>
        }
        head={[
          {
            label: "check_all",
            name: "check_all",
            checked:
              data?.length > 0
                ? data?.length === products_checked.length
                : false,
            onChange: ({ target: { checked } }) =>
              checked
                ? setProductChecked(data.map(({ id }) => id))
                : setProductChecked([]),
          },
          { label: "", name: "image", sortable: false, style: { width: 70 } },
          { label: "ID", name: "channel_product_id", sortable: false },
          { label: "SKU", name: "sku", sortable: false },
          { label: "Nazwa", name: "name", sortable: false },
          { label: "Producent", name: "producer", sortable: false },
          { label: "Status", name: "sales_status", sortable: false },
        ]}
        renderRow={({
          id,
          channel_product: {
            id: channel_product_id,
            name,
            sku,
            sales_status,
            product,
            channel_product_gallery_images,
          },
        }) => (
          <tr
            key={id}
            onClick={() => {
              const checked = products_checked.find((item) => item === id);
              !checked
                ? setProductChecked([...products_checked, id])
                : setProductChecked(
                    products_checked.filter((item) => item !== id)
                  );
            }}>
            <td>
              <div className="check-group" style={{ margin: 0 }}>
                <input
                  id={`channel_product_discounts_${id}`}
                  type="checkbox"
                  checked={!!products_checked.find((item) => item === id)}
                  readOnly={true}
                />
                <label
                  htmlFor={`gdn_${id}`}
                  className="label-form"
                  style={{ minHeight: 16, margin: 0, display: "block" }}
                />
              </div>
            </td>
            <td>
              <img
                src={channel_product_gallery_images?.[0]?.url}
                alt={name}
                style={{ width: 50, height: 50, margin: "-10px 0 -15px" }}
              />
            </td>
            <td>{channel_product_id}</td>
            <td>{sku || product?.sku}</td>
            <td>{name || product?.name}</td>
            <td>{product?.producer?.name}</td>
            <td>
              <ProductSaleStatus status={sales_status} />
            </td>
          </tr>
        )}
        empty_text="Brak produktów"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDiscountProducts: (data, id) => dispatch(getDiscountProducts(data, id)),
  filterDiscountProducts: (data) => dispatch(filterDiscountProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ discount_products }) => ({
    discount_products,
  }),
  mapDispatchToProps
)(withRouter(DiscountProductsList));
