import React, { useState, useEffect } from "react";
import { Button } from "expano-components";
import { connect } from "react-redux";
import { Dashboard, MainTable } from "components";
import { getHomeSections } from "actions/home_sections";
import SectionModal from "./components/SectionModal";
import Item from "./components/Item";

const HomeSections = ({ home_sections, getHomeSections }) => {
  const [is_open, handleOpen] = useState(false);
  useEffect(() => {
    getHomeSections();
  }, []);

  const { data } = home_sections;
  return (
    <Dashboard
      header={{
        title: "Sekcje",
        icon: "info",
        aside: (
          <Button
            type="add"
            text="Dodaj sekcje"
            onClick={() => handleOpen(true)}
          />
        ),
      }}>
      {is_open && <SectionModal handleClose={() => handleOpen(false)} />}
      <MainTable
        is_loading={
          home_sections.status === "invalid" ||
          home_sections.status === "loading"
        }
        is_searching={home_sections.status === "searching"}
        data={data}
        head={[
          { label: "ID", name: "id", sortable: false },
          { label: "Nazwa", name: "name", sortable: false },
          { label: "Typ", name: "type", sortable: false },
          { label: "", name: "order", sortable: false, style: { width: 100 } },
          { label: "", name: "delete", sortable: false, style: { width: 100 } },
        ]}
        renderRow={(item) => (
          <Item {...item} count={data.length} key={item.id} />
        )}
        empty_text="Brak sekcji"
      />
    </Dashboard>
  );
};

export default connect(
  ({ home_sections }) => ({ home_sections }),
  (dispatch) => ({
    getHomeSections: () => dispatch(getHomeSections()),
  })
)(HomeSections);
