import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { Calendar } from "icons";
const DatePickerComponent = ({ data, handleChange }) => {
  const [value, setValue] = useState(null);

  return (
    <div className='inp-wrapper'>
      {data.label && (
        <label htmlFor={data.name} className='label-form'>
          {data.label}
        </label>
      )}
      <div className='calendar-group'>
        <DatePicker
          calendarIcon={<Calendar />}
          onChange={val => {
            setValue(val);
            handleChange(val);
          }}
          value={value}
          locale='pl-PL'
          format='y-MM-dd'
        />
      </div>
    </div>
  );
};

export default DatePickerComponent;
