import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import Editor from "react-markdown-editor-lite";
import { Button, Textarea, Select, Input, ImageUploader } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { createCollection } from "actions/collections";
import { CreatableSelect, Dashboard } from "components";
import { toBase64 } from "utils/helpers";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

const status_options = [
  { label: "Aktywny", value: "active" },
  { label: "Nieaktywny", value: "inactive" },
];

const BasicData = ({ history, path, submitting, pristine, handleSubmit, change }) => {
  const [banner_image, setBannerImage] = useState([]);
  const [icon_image, setIconImage] = useState([]);

  const [text, setText] = useState("");

  const submit = values =>
    createCollection(values).then(({ data: { id } }) => history.push(`${path}/${id}`));

  const handleUploadBannerImage = file => {
    return new Promise(async resolve => {
      const banner_data = await toBase64(file);
      setBannerImage([{ path: banner_data }]);
      change("banner_data", banner_data);
      resolve();
    });
  };

  const handleUploadIconImage = file => {
    return new Promise(async resolve => {
      const icon_data = await toBase64(file);
      setIconImage([{ path: icon_data }]);
      change("icon_data", icon_data);
      resolve();
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} 
        .banner .image-uploader__box{ width: 100%; height: 70px; transition: none; padding: 0; margin: 0;} 
        .icon .image-uploader__box{ width: 130px; height: 130px; transition: none; margin: 0;}`}</style>
      </Helmet>
      <Dashboard
        header={{
          title: "Podstawowe informacje",
          icon: "info",
          aside: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div noValidate className='dashboard__form'>
          <div className='form-row grid-2'>
            <Field label='Nazwa' name='name' component={InputField} type='text' />
            <Field name='status' component={props => <Input {...props} type='hidden' />} />
            <Select
              label='Status'
              name='status'
              options={status_options}
              handleChange={value => change("status", value)}
            />
          </div>
        </div>
      </Dashboard>

      <div className='dashboard-wrapper-flex'>
        <div className='column-half'>
          <Dashboard header={{ title: "Ikonka", icon: "info" }}>
            <div className='dashboard__form icon'>
              <div className='form-group'>
                <div className='inp-wrapper'>
                  <label htmlFor=''>Ikona (100px x 100px)</label>
                  <ImageUploader
                    addText='dodaj ikonę'
                    url={""}
                    images={icon_image}
                    limit={1}
                    handleUpload={handleUploadIconImage}
                  />
                </div>
              </div>
            </div>
          </Dashboard>
        </div>
        <div className='column-half'>
          <Dashboard header={{ title: "Banner", icon: "info" }}>
            <div className='dashboard__form banner'>
              <div className='form-group'>
                <div className='inp-wrapper'>
                  <label htmlFor=''>Banner (1600px x 200px)</label>
                  <ImageUploader
                    addText='dodaj banner'
                    url={""}
                    images={banner_image}
                    limit={1}
                    handleUpload={handleUploadBannerImage}
                  />
                </div>
              </div>
            </div>
          </Dashboard>
        </div>
      </div>
      <div className='dashboard-wrapper-flex'>
        <div className='column-2'>
          <Dashboard header={{ title: "Content", icon: "edit" }}>
            <div className='dashboard__form'>
              <Field component={props => <Input {...props} />} type='hidden' name='description' />
              <Editor
                plugins={["header", "fonts", "link"]}
                value={text}
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                    fullScreen: false,
                    hideMenu: false,
                  },
                }}
                style={{ height: "600px" }}
                onChange={({ text }) => {
                  change("description", text);
                  setText(text);
                }}
              />
            </div>
          </Dashboard>
        </div>
        <div className='column-1'>
          <Dashboard header={{ title: "SEO", icon: "info" }}>
            <div className='dashboard__form'>
              <Field name='meta_title' label='Meta title' component={InputField} type='text' />
              <Field name='meta_description' label='Meta description' component={TextareaField} />
              <Field name='slug' label='Slug URL' component={InputField} type='text' />
              <Field name='keyword_list' component={InputField} type='hidden' />
              <Field name='tag_list' component={InputField} type='hidden' />
              <CreatableSelect label='Keywords' onChange={value => change("keyword_list", value)} />
              <CreatableSelect label='Tagi' onChange={value => change("tag_list", value)} />
            </div>
          </Dashboard>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "create-collection",
  enableReinitialize: true,
})(withRouter(BasicData));
