import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "DISCOUNT_TAXONS_TOGGLE_FILTERS" });
};

export const filterDiscountTaxons = data => async dispatch => {
  dispatch({ type: "DISCOUNT_TAXONS_SET_FILTERS_QUERY", data });
};

export const getDiscountTaxons = ({ page = 1, per_page = 10, ...rest }, discount_id) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().discount_taxons;
  status === "invalid"
    ? dispatch({ type: "DISCOUNT_TAXONS_LOADING" })
    : dispatch({ type: "DISCOUNT_TAXONS_SEARCHING" });
  await client
    .get(
      `/channel_taxon_discounts?include=channel_taxon&q[discount_id_eq]=${discount_id}&${stringifyQuery(
        {
          ...rest,
          page,
          per_page,
          ...query,
        }
      )}`
    )
    .then(({ data }) => {
      dispatch({
        type: "DISCOUNT_TAXONS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "DISCOUNT_TAXONS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const deleteDiscountTaxon = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/channel_taxon_discounts/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createDiscountTaxon = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/channel_taxon_discounts`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano kategorię do promocji");
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });
