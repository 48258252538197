import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "DISCOUNT_PRODUCERS_TOGGLE_FILTERS" });
};

export const filterDiscountProducers = data => async dispatch => {
  dispatch({ type: "DISCOUNT_PRODUCERS_SET_FILTERS_QUERY", data });
};

export const getDiscountProducers = ({ page = 1, per_page = 10, ...rest }, discount_id) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().discount_producers;
  status === "invalid"
    ? dispatch({ type: "DISCOUNT_PRODUCERS_LOADING" })
    : dispatch({ type: "DISCOUNT_PRODUCERS_SEARCHING" });
  await client
    .get(
      `/channel_producer_discounts?include=channel_producer.producer&q[discount_id_eq]=${discount_id}&${stringifyQuery(
        {
          ...rest,
          page,
          per_page,
          ...query,
        }
      )}`
    )
    .then(({ data }) => {
      dispatch({
        type: "DISCOUNT_PRODUCERS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "DISCOUNT_PRODUCERS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const deleteDiscountProducer = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/channel_producer_discounts/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createDiscountProducer = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/channel_producer_discounts`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano producenta do promocji");
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });
