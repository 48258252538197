import React from "react";
import { connect } from "react-redux";
import { Modal } from "components";
import { Button } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { createDiscountProducer} from "actions/discount_producers";

import AsyncProducers from "./AsyncProducers";

const SelectField = props => <AsyncProducers isMulti={true} {...props} />;

const ProducersModal = ({
  change,
  discount,
  handleReload,
  handleSubmit,
  handleClose,
  initialValues,
  data,
}) => {
  const submit = ({ channel_producer_id }) =>
    createDiscountProducer({ discount_id: discount.id, channel_producer_id }).then(() => {
      handleClose();
      handleReload();
    });
  return (
    <Modal size='lg'>
      <form onSubmit={handleSubmit(submit)}>
        <header className='popup__header'>
          <div className='icon__container'>
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <path
                  d='M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z'
                  fill='#5D78FF'
                />
                <path
                  d='M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z'
                  fill='#5D78FF'
                  opacity='0.3'
                />
              </g>
            </svg>
          </div>
          <h2 className='dashboard__title heading'>Dodaj producenta</h2>
          <Button type='close' onClick={handleClose} />
        </header>
        <div className='popup__body' style={{ padding: 30 }}>
          <Field
            name='channel_producer_id'
            component={SelectField}
            change={change}
          />
        </div>

        <div className='popup__footer'>
          <Button type='cancel' onClick={handleClose} />
          <Button type='add-green' text='Dodaj' onClick={handleSubmit(submit)} />
        </div>
      </form>
    </Modal>
  );
};

export default connect(({ discount }) => ({
  discount,
}))(
  reduxForm({
    form: "add-discount-producer-form",
    enableReinitialize: true,
  })(ProducersModal)
);
