import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";


export const toggleFilters = () => dispatch => {
  dispatch({ type: "CHANNEL_PRODUCTS_TOGGLE_FILTERS" });
};

export const filterChannelProducts = data => async dispatch => {
  dispatch({ type: "CHANNEL_PRODUCTS_SET_FILTERS_QUERY", data });
};

export const getChannelProducts = ({ page = 1, per_page = 10, ...rest }) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().channel_products;
  status === "invalid"
    ? dispatch({ type: "CHANNEL_PRODUCTS_LOADING" })
    : dispatch({ type: "CHANNEL_PRODUCTS_SEARCHING" });
  await client
    .get(`/channel_products?include=channel_product_gallery_images,product.producer&${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "CHANNEL_PRODUCTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "CHANNEL_PRODUCTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getChannelProduct = id => async dispatch => {
  dispatch({ type: "CHANNEL_PRODUCT_LOADING" });
  try {
    const { data } = await client.get(
      `/channel_products/${id}?include=channel_product360_images,channel_product_daily_prices,channel_product_gallery_images,product.producer,product.product_properties.property,product.product_properties.property_option,product.product_group.product_group_properties.property,product.product_group.product_group_properties.property_option,channel_product_description_sections,channel_product_group`
    );
    dispatch({
      type: "CHANNEL_PRODUCT_SUCCESS",
      data: apiFormatter(data),
    });
  } catch (error) {
    dispatch({ type: "CHANNEL_PRODUCT_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
  }
};
