import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "CHANNEL_PRODUCERS_TOGGLE_FILTERS" });
};

export const filterChannelProducers = data => async dispatch => {
  dispatch({ type: "CHANNEL_PRODUCERS_SET_FILTERS_QUERY", data });
};

export const getChannelProducers = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().channel_producers;
  status === "invalid"
    ? dispatch({ type: "CHANNEL_PRODUCERS_LOADING" })
    : dispatch({ type: "CHANNEL_PRODUCERS_SEARCHING" });
  await client
    .get(
      `/channel_producers?include=producer&${stringifyQuery({ ...rest, page, per_page, ...query })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "CHANNEL_PRODUCERS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "CHANNEL_PRODUCERS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getChannelProducer = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "CHANNEL_PRODUCER_LOADING" });
    await client
      .get(`/channel_producers/${id}?include=producer`)
      .then(({ data }) => {
        dispatch({
          type: "CHANNEL_PRODUCER_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "CHANNEL_PRODUCER_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const updateChannelProducer = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/channel_producers/${id}?include=producer`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Zaktualizowano poroducenta");
        dispatch({
          type: "CHANNEL_PRODUCER_SUCCESS",
          data: apiFormatter(data),
        });
        resolve()
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania");
        dispatch({ type: "CHANNEL_PRODUCER_FAILURE" });
        reject();
      });
  });
