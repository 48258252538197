import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPlatformTaxon } from "actions/platform_taxons";
import { Main, PageLoader } from "components";
import { BasicData } from "./components";

const PlatformTaxonEdit = ({ platform_taxon, getPlatformTaxon, match }) => {
  useEffect(() => {
    getPlatformTaxon(match?.params?.platform_taxon_id);
  }, []);
  if (!platform_taxon || platform_taxon.status === "invalid" || platform_taxon.status === "loading") return <PageLoader color='#2959F8' />;

  if (platform_taxon.status === "failure") return <p>Error</p>;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Kategorie produktowe",
          path: `/platforms/1/platform_taxons`,
          icon: "product_categories",
        },
        {
          name: "Dodaj",
        },
      ]}
    >
      <div className='dashboard-wrapper-flex'>
        <div className='dashboard dashboard--nav'>
          <header className='dashboard__header dashboard__header--edit dashboard__header--edit--nav'>
            <div className='icon__container'>
              <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <rect x='0' y='0' width='24' height='24'></rect>
                  <path
                    d='M5,5 L19,5 C19.5522847,5 20,5.44771525 20,6 C20,6.55228475 19.5522847,7 19,7 L5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 Z M5,13 L19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 L5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 Z'
                    fill='#494B74'
                    opacity='0.3'
                  ></path>
                  <path
                    d='M5,9 L19,9 C19.5522847,9 20,9.44771525 20,10 C20,10.5522847 19.5522847,11 19,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L19,17 C19.5522847,17 20,17.4477153 20,18 C20,18.5522847 19.5522847,19 19,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z'
                    fill='#494B74'
                  ></path>
                </g>
              </svg>
            </div>
            <h2 className='dashboard__title heading dashboard__title--nav'>Dodaj kategorie platformy</h2>
          </header>
        </div>
      </div>
      <BasicData />
    </Main>
  );
};
const mapDispatchToProps = dispatch => ({
  getPlatformTaxon: id => dispatch(getPlatformTaxon(id)),
});

export default connect(({ platform_taxon }) => ({ platform_taxon }), mapDispatchToProps)(withRouter(PlatformTaxonEdit));
