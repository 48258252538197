import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { Button, Textarea, Input, Checkbox } from "expano-components";
import { Dashboard, CreatableSelect } from "components";
import { updateArticle } from "actions/articles";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;
const CheckField = props => <Checkbox {...props} />;

const Seo = ({
  article,
  updateArticle,
  handleSubmit,
  pristine,
  change,
  dispatch,
  initialValues,
}) => {
  const submit = values =>
    updateArticle(article.id, values).catch(err => {
      console.log(err);
      throw new SubmissionError(err);
    });
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Seo", icon: "info" }}
        footer={{
          buttons: [
            !pristine && (
              <Button type='cancel' onClick={() => dispatch(reset("edit-article-seo"))} />
            ),
            <Button type='save' text='Zapisz' disabled={pristine} onClick={handleSubmit(submit)} />,
          ],
        }}
      >
        <div className='dashboard__form'>
          <Field name='meta_title' label='Meta title' component={InputField} type='text' />
          <Field name='meta_description' label='Meta description' component={TextareaField} />
          <Field name='slug' label='Slug URL' component={InputField} type='text' />
          <Field name='keyword_list' component={InputField} type='hidden' />
          <Field name='tag_list' component={InputField} type='hidden' />

          <CreatableSelect
            label='Keywords'
            onChange={value => change("keyword_list", value)}
            defaultValue={
              initialValues.keyword_list?.length > 0
                ? initialValues.keyword_list.map(item => ({ label: item, value: item }))
                : []
            }
          />
          <CreatableSelect
            label='Tagi'
            onChange={value => change("tag_list", value)}
            defaultValue={
              initialValues.tag_list?.length > 0
                ? initialValues.tag_list.map(item => ({ label: item, value: item }))
                : []
            }
          />
          <Field name='is_no_index' label='Article nieindeksowalny?' component={CheckField} />
        </div>
      </Dashboard>
    </form>
  );
};

const mapStateToProps = state => ({
  article: state.article,
  initialValues: {
    slug: state.article?.slug,
    meta_title: state.article?.meta_title,
    meta_description: state.article?.meta_description,
    is_no_index: state.article?.is_no_index,
    tag_list: state.article?.tag_list,
    keyword_list: state.article?.keyword_list,
  },
});

const mapDispatchToProps = dispatch => ({
  updateArticle: (id, values) => dispatch(updateArticle(id, values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "edit-article-seo", enableReinitialize: true })(Seo));
