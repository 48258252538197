import React from "react";

const renderIcon = icon => {
  switch (icon) {
    case "delete":
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
          <path
            fill='#fd397a'
            d='M6,8H16l-.745,9.679a1.25,1.25,0,0,1-1.246,1.154H7.991a1.25,1.25,0,0,1-1.246-1.154Z'
            transform='translate(-1 -1.333)'
          />
          <path
            fill='#fd397a'
            opacity='0.3'
            d='M12.5,4.25V3.417A.417.417,0,0,0,12.083,3h-2.5a.417.417,0,0,0-.417.417V4.25H5.417A.417.417,0,0,0,5,4.667v.417a.417.417,0,0,0,.417.417H16.25a.417.417,0,0,0,.417-.417V4.667a.417.417,0,0,0-.417-.417Z'
            transform='translate(-0.833 -0.5)'
          />
        </svg>
      );
    default:
      return null;
  }
};

const TopLineActions = ({ data }) => {
  if (data.length < 1) return null;
  return (
    <div className='actions-container'>
      <button type='button' className='btn-actions'>
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
      </button>
      <ul className='actions-dropdown'>
        {data.map((item, index) => (
          <li key={index}>
            <button className='actions-dropdown__item' onClick={item?.action}>
              {renderIcon(item?.icon)} <span>{item?.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopLineActions;
