import React from "react";
import { BasicForm, Content, Seo, Icon, Banner } from "./components";

const BasicData = ({ change }) => {
  return (
    <>
      <BasicForm change={change} />
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Icon change={change} />
        </div>
        <div className="column-half">
          <Banner change={change} />
        </div>
      </div>
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Content change={change} />
        </div>
        <div className="column-1">
          <Seo change={change} />
        </div>
      </div>
    </>
  );
};

export default BasicData;
