import React from "react";

const Discounts = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M12,20.022,8.473,21.729l-1.6-3.574-3.8-.959,1.068-3.769L1.846,10.249l3.241-2.2.285-3.907,3.9.4L12,1.729l2.73,2.81,3.9-.4.285,3.907,3.241,2.2-2.293,3.176,1.068,3.769-3.8.959-1.6,3.574Z'
      transform='translate(0)'
    />
    <path
      fill='#5d78ff'
      d='M14.089,8.6,8.361,14.7l1.348,1.348,5.728-6.1Z'
      transform='translate(0 0)'
    />
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M10.854,9.175A1.718,1.718,0,1,1,9.136,7.457a1.718,1.718,0,0,1,1.718,1.718'
      transform='translate(0 0)'
    />
    <path
      fill='#5d78ff'
      opacity='0.3'
      d='M14.864,16.622A1.719,1.719,0,1,1,16.583,14.9,1.719,1.719,0,0,1,14.864,16.622Z'
      transform='translate(0 0)'
    />
  </svg>
);

export default Discounts;
