import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ImageUploader, Input } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { addSlide } from "actions/sliders";
import { toBase64 } from "utils/helpers";
import Helmet from "react-helmet";

const InputField = (props) => <Input {...props} input={{ ...props.input }} />;

const FormNew = ({
  pristine,
  submitting,
  handleSubmit,
  addSlide,
  handleChangeTab,
  change,
}) => {
  const [image, setImage] = useState([]);
  const [mobile_image, setMobileImage] = useState([]);
  const submit = async (data) => {
    addSlide(data)
      .then((id) => handleChangeTab(id))
      .catch((err) => console.log(err));
  };

  const handleUpload = (file) =>
    new Promise(async (resolve, reject) => {
      const file_data = await toBase64(file);
      setImage([{ path: file_data }]);
      change("image_data", file_data);
      resolve();
    });

  const handleUploadMobileImage = (file) =>
    new Promise(async (resolve, reject) => {
      const file_data = await toBase64(file);
      setMobileImage([{ path: file_data }]);
      change("mobile_image_data", file_data);
      resolve();
    });
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 100%; height: 180px; margin: 0; transition: none; }`}</style>
      </Helmet>
      <div className="dashboard__form__submit">
        <Field label="URL" type="text" name="url" component={InputField} />
        <Field
          label="Nazwa slide'u"
          type="text"
          name="name"
          component={InputField}
        />
        <Field type="hidden" name="image_data" component={InputField} />
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Grafika duża</label>
            <Field type="hidden" name="image" component={InputField} />
            <ImageUploader
              addText="dodaj grafikę"
              url={""}
              images={image}
              limit={1}
              handleUpload={handleUpload}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Grafika mała {"(< 1060px)"}</label>
            <Field type="hidden" name="mobile_image" component={InputField} />
            <ImageUploader
              addText="dodaj grafikę"
              url={""}
              images={mobile_image}
              limit={1}
              handleUpload={handleUploadMobileImage}
            />
          </div>
        </div>
      </div>
      <div className="dashboard__form__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || submitting}
          onClick={handleSubmit(submit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addSlide: (data) => dispatch(addSlide(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "sliders_new",
    enableReinitialize: true,
  })(FormNew)
);
