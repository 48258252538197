const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#E2E5EC",
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
    padding: "4px",
  }),
  multiValue: (styles) => ({
    ...styles,
    borderRadius: "4px",
    backgroundColor: "rgba(93, 120, 249, .3)",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "rgba(93, 120, 249, 1)",
    maxWidth: "200px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "rgba(93, 120, 249, 1)",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
};

export default selectStyles;
