import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";

const Summary = ({ order }) => {
  const has_discount_code = Boolean(
    order?.discount_code?.code
  );
  return (
    <Dashboard
      header={{
        title: "Podsumowanie",
        icon: "order_summary",
      }}>
      <div className="summary">
        <div className="summary-details">
          <div className="row">
            <span>Łączna kwota produktów</span>
            <span>
              {order?.products_price
                ? `${Number(order.products_price)
                    .toFixed(2)
                    .split(".")
                    .join(",")} zł`
                : "-"}
            </span>
          </div>
          {has_discount_code && (
            <div className="row discount">
              <span>Kod rabatowy</span>
              <span>
                {order?.products_price
                  ? `-${Number(
                      order.regular_products_price -
                        order.products_price
                    )
                      .toFixed(2)
                      .split(".")
                      .join(",")} zł`
                  : "-"}
              </span>
            </div>
          )}
          <div className="row">
            <span>Wysyłka</span>
            <span>
              {order?.shipping_price
                ? `${Number(order.shipping_price)
                    .toFixed(2)
                    .split(".")
                    .join(",")} zł`
                : "-"}
            </span>
          </div>
          <div className="row">
            <span>Podatek VAT</span>
            <span>-</span>
          </div>
        </div>
        <div className="summary-total">
          <div className="row">
            <span>Suma</span>
            <span>
              {order?.total_price
                ? `${Number(order.total_price)
                    .toFixed(2)
                    .split(".")
                    .join(",")} zł`
                : ""}
            </span>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

const mapStateToProps = ({ order }) => ({
  order: order?.data,
});

export default connect(mapStateToProps)(Summary);
