import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { MainTable } from "components";
import { Button, ModalDelete } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getDiscountProducers,
  filterDiscountProducers,
  toggleFilters,
  deleteDiscountProducer,
} from "actions/discount_producers";
import ProducersModal from "./components/ProducersModal";
import DiscountReferenceTypeSelect from "../DiscountReferenceTypeSelect";

const DiscountProducersList = ({
  discount_producers,
  getDiscountProducers,
  updateProducers,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [is_shown_producers_modal, handleShowProducersModal] = useState(false);
  const [is_shown_delete_producers_modal, handleShowDeleteProducersModal] = useState(false);
  const [producers_checked, setProducerChecked] = useState([]);

  const sort = {
    "sort[column]": query["sort[column]"] || "channel_producer_id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscountProducers({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscountProducers({ ...query, page: undefined }, params.id);
    }
  }, [discount_producers.filters.query]);

  const handleReload = () =>
    getDiscountProducers({ ...query, ...sort, page: undefined }, params.id);

  const handleDeleteProducers = () => {
    const promises = producers_checked.map(id => deleteDiscountProducer(id));
    Promise.all(promises).then(() => {
      setProducerChecked([]);
      toastr.success("Sukces", "Producenci zostali usunięci");
      handleReload();
      handleShowDeleteProducersModal(false);
    });
  };

  if (discount_producers.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = discount_producers;
  return (
    <>
      {is_shown_producers_modal && (
        <ProducersModal
          handleClose={() => handleShowProducersModal(false)}
          handleReload={handleReload}
        />
      )}
      {is_shown_delete_producers_modal && (
        <ModalDelete
          name='zaznaczonych producentów'
          handleClose={() => handleShowDeleteProducersModal(false)}
          handleDelete={handleDeleteProducers}
        />
      )}
      <MainTable
        is_loading={
          discount_producers.status === "invalid" || discount_producers.status === "loading"
        }
        is_searching={discount_producers.status === "searching"}
        filters_open={discount_producers.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        buttons={
          <div className='wrapper jc-sb al-fe' style={{ width: "100%" }}>
            <div style={{ flexBasis: 230, maxWidth: 230 }}>
              <DiscountReferenceTypeSelect />
            </div>
            <div className='wrapper'>
              {producers_checked.length > 0 && (
                <Button
                  type='delete'
                  text='Usuń zaznaczone'
                  onClick={() => handleShowDeleteProducersModal(true)}
                />
              )}
              <Button
                type='add'
                text='Dodaj producenta'
                onClick={() => handleShowProducersModal(true)}
              />
            </div>
          </div>
        }
        head={[
          {
            label: "check_all",
            name: "check_all",
            checked: data?.length > 0 ? data?.length === producers_checked.length : false,
            onChange: ({ target: { checked } }) =>
              checked ? setProducerChecked(data.map(({ id }) => id)) : setProducerChecked([]),
          },
          { label: "ID", name: "channel_producer_id", sortable: false, style: { width: 140 } },
          { label: "Nazwa", name: "name", sortable: false },
        ]}
        renderRow={({ id, channel_producer: { id: producer_id, producer } }) => (
          <tr
            key={id}
            onClick={() => {
              const checked = producers_checked.find(item => item === id);
              !checked
                ? setProducerChecked([...producers_checked, id])
                : setProducerChecked(producers_checked.filter(item => item !== id));
            }}
          >
            <td>
              <div className='check-group' style={{ margin: 0 }}>
                <input
                  id={`channel_producer_discounts_${id}`}
                  type='checkbox'
                  checked={!!producers_checked.find(item => item === id)}
                  readOnly
                />
                <label
                  htmlFor={`gdn_${id}`}
                  className='label-form'
                  style={{ minHeight: 16, margin: 0, display: "block" }}
                />
              </div>
            </td>
            <td>{producer_id}</td>
            <td>{producer?.name}</td>
          </tr>
        )}
        empty_text='Brak producentów'
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getDiscountProducers: (data, id) => dispatch(getDiscountProducers(data, id)),
  filterDiscountProducers: data => dispatch(filterDiscountProducers(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ discount_producers }) => ({
    discount_producers,
  }),
  mapDispatchToProps
)(withRouter(DiscountProducersList));
