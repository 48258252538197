import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { MainTable } from "components";
import { Button, ModalDelete } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getDiscountTaxons,
  filterDiscountTaxons,
  toggleFilters,
  deleteDiscountTaxon,
} from "actions/discount_taxons";
import TaxonsModal from "./components/TaxonsModal";
import DiscountReferenceTypeSelect from "../DiscountReferenceTypeSelect";

const DiscountTaxonsList = ({
  discount_taxons,
  getDiscountTaxons,
  updateTaxons,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [is_shown_taxons_modal, handleShowTaxonsModal] = useState(false);
  const [is_shown_delete_taxons_modal, handleShowDeleteTaxonsModal] = useState(false);
  const [taxons_checked, setTaxonChecked] = useState([]);

  const sort = {
    "sort[column]": query["sort[column]"] || "channel_taxon_id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscountTaxons({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscountTaxons({ ...query, page: undefined }, params.id);
    }
  }, [discount_taxons.filters.query]);

  const handleReload = () => getDiscountTaxons({ ...query, ...sort, page: undefined }, params.id);

  const handleDeleteTaxons = () => {
    const promises = taxons_checked.map(id => deleteDiscountTaxon(id));
    Promise.all(promises).then(() => {
      setTaxonChecked([]);
      toastr.success("Sukces", "Kategorie zostały usunięte");
      handleReload();
      handleShowDeleteTaxonsModal(false);
    });
  };

  if (discount_taxons.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = discount_taxons;
  return (
    <>
      {is_shown_taxons_modal && (
        <TaxonsModal handleClose={() => handleShowTaxonsModal(false)} handleReload={handleReload} />
      )}
      {is_shown_delete_taxons_modal && (
        <ModalDelete
          name='zaznaczone kategorie'
          handleClose={() => handleShowDeleteTaxonsModal(false)}
          handleDelete={handleDeleteTaxons}
        />
      )}
      <MainTable
        is_loading={discount_taxons.status === "invalid" || discount_taxons.status === "loading"}
        is_searching={discount_taxons.status === "searching"}
        filters_open={discount_taxons.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        buttons={
          <div className='wrapper jc-sb al-fe' style={{ width: "100%" }}>
            <div style={{ flexBasis: 230, maxWidth: 230 }}>
              <DiscountReferenceTypeSelect />
            </div>
            <div className='wrapper'>
              {taxons_checked.length > 0 && (
                <Button
                  type='delete'
                  text='Usuń zaznaczone'
                  onClick={() => handleShowDeleteTaxonsModal(true)}
                />
              )}
              <Button
                type='add'
                text='Dodaj kategorię'
                onClick={() => handleShowTaxonsModal(true)}
              />
            </div>
          </div>
        }
        head={[
          {
            label: "check_all",
            name: "check_all",
            checked: data?.length > 0 ? data?.length === taxons_checked.length : false,
            onChange: ({ target: { checked } }) =>
              checked ? setTaxonChecked(data.map(({ id }) => id)) : setTaxonChecked([]),
          },
          { label: "ID", name: "channel_taxon_id", sortable: false, style: { width: 140 } },
          { label: "Nazwa", name: "name", sortable: false },
        ]}
        renderRow={({ id, channel_taxon: { id: taxon_id, name } }) => (
          <tr
            key={id}
            onClick={() => {
              const checked = taxons_checked.find(item => item === id);
              !checked
                ? setTaxonChecked([...taxons_checked, id])
                : setTaxonChecked(taxons_checked.filter(item => item !== id));
            }}
          >
            <td>
              <div className='check-group' style={{ margin: 0 }}>
                <input
                  id={`channel_taxon_discounts_${id}`}
                  type='checkbox'
                  checked={!!taxons_checked.find(item => item === id)}
                  readOnly
                />
                <label
                  htmlFor={`gdn_${id}`}
                  className='label-form'
                  style={{ minHeight: 16, margin: 0, display: "block" }}
                />
              </div>
            </td>
            <td>{taxon_id}</td>
            <td>{name}</td>
          </tr>
        )}
        empty_text='Brak kategorii'
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getDiscountTaxons: (data, id) => dispatch(getDiscountTaxons(data, id)),
  filterDiscountTaxons: data => dispatch(filterDiscountTaxons(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ discount_taxons }) => ({
    discount_taxons,
  }),
  mapDispatchToProps
)(withRouter(DiscountTaxonsList));
