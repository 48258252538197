import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Select, Checkbox, Input } from "expano-components";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { updateDiscount } from "actions/discounts";
import { DatePicker, Dashboard } from "components";
import discount_type_options from "../../../const/discount_type_options";

const InputField = props => <Input {...props} />;

const form_name = "edit-discount";
const selector = formValueSelector(form_name);

const BasicData = ({
  discount,
  updateDiscount,
  date_start,
  date_end,
  discount_type,
  is_unlimited,
  path,
  submitting,
  pristine,
  handleSubmit,
  initialValues,
  change,
}) => {
  useEffect(() => {
    if (is_unlimited) {
      change("date_end", null);
    }
  }, [is_unlimited]);
  useEffect(() => {
    if (!!date_end) {
      change("is_unlimited", false);
    }
  }, [date_end]);
  const submit = values => updateDiscount(discount.id, values);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{
          title: "Podstawowe informacje",
          icon: "info",
        }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div noValidate className='dashboard__form'>
          <div className='form-row grid-2'>
            <Field label='Nazwa promocji' name='name' component={InputField} type='text' />
            <DatePicker
              change={change}
              value={date_start ? new Date(date_start) : null}
              name='date_start'
              label='Data rozpoczęcia'
            />
          </div>
          <div className='wrapper jc-sb'>
            <div className='column-half'>
              <div className='wrapper jc-sb'>
                <div className='column-half'>
                  <Select
                    label='Typ'
                    name='discount_type'
                    options={discount_type_options}
                    defaultValue={discount_type_options.find(({value}) => value === initialValues.discount_type)}
                    handleChange={value => change("discount_type", value)}
                  />
                </div>
                <div className='column-half'>
                  <Field
                    postfix={
                      discount_type === "percentage"
                        ? "%"
                        : discount_type === "quantitative"
                        ? "szt"
                        : null
                    }
                    label='Wartość'
                    name='discount_value'
                    component={InputField}
                    type='text'
                  />
                </div>
              </div>
            </div>
            <div className='column-half'>
              <DatePicker
                change={change}
                value={date_end ? new Date(date_end) : null}
                name='date_end'
                label='Data zakończenia'
              />
              <div style={{ marginTop: "-14px" }}>
                <Field
                  label='Nie wygasa'
                  name='is_unlimited'
                  component={props => (
                    <Checkbox {...props} input={{ ...props.input, value: is_unlimited }} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(
  state => ({
    date_start: selector(state, "date_start"),
    date_end: selector(state, "date_end"),
    discount_type: selector(state, "discount_type"),
    is_unlimited: selector(state, "is_unlimited"),
    discount: state.discount,
    initialValues: {
      is_unlimited: state.discount.is_unlimited,
      name: state.discount.name,
      discount_value: state.discount.discount_value,
      discount_type: state.discount.discount_type,
      date_end: state.discount.date_end,
      date_start: state.discount.date_start,
    },
  }),
  dispatch => ({ updateDiscount: (id, data) => dispatch(updateDiscount(id, data)) })
)(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  })(withRouter(BasicData))
);
