import axios from "axios";
import { getCookie } from "utils/cookies";
import { url, apiFormatter } from "utils/api";

export const getCurrentUser = () => dispatch =>
  new Promise(async (resolve, reject) => {
    const token = await getCookie("token");
    dispatch({ type: "CURRENT_USER_LOADING" });
    axios
      .get(`${url}/v1/users/my_account?include=company`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => dispatch({ type: "CURRENT_USER_SUCCESS", data: apiFormatter(data) }))
      .catch(err => {
        dispatch({ type: "CURRENT_USER_FAILURE", err });
      });
  });
