import React from "react";

const Address = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <path
        fill='#5d78ff'
        d='M3.8,7.649l6.307-3.854a.838.838,0,0,1,.874,0L17.29,7.649a1.677,1.677,0,0,1,.8,1.431v7.444A1.677,1.677,0,0,1,16.416,18.2H4.677A1.677,1.677,0,0,1,3,16.524V9.08A1.677,1.677,0,0,1,3.8,7.649Zm5.067,3.844a.838.838,0,0,0-.838.838v2.515a.838.838,0,0,0,.838.838h3.354a.838.838,0,0,0,.838-.838V12.332a.838.838,0,0,0-.838-.838Z'
        transform='translate(-0.485 -0.593)'
      />
    </svg>
  );
};

export default Address;
