import styled from "styled-components";

const StyledDashboardTabs = styled.ul`
  display: flex;
  margin-bottom: 20px;
  .tab-item {
    cursor: pointer;
    flex: 1;
    padding: 8px 20px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    background-color: #ebecf3;
    transition: 0.2s;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 100%;
      height: 2px;
      background-color: #5d78ff;
      transition: 0.2s;
    }
    &.active {
      background-color: #fff;
      &::after {
        right: 0;
      }
    }
  }
`;
export default StyledDashboardTabs;
