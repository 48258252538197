import React from "react";
import { NotFound, PlatformTaxonsList, PlatformTaxonsEdit, PlatformTaxonsNew } from "pages";
import { Switch, Route } from "react-router-dom";
const path_prefix = "/platforms/:platform_id/platform_taxons";
const Router = () => {
  return (
    <Switch>
      <Route exact={true} path={`${path_prefix}`} component={PlatformTaxonsList} />
      <Route exact={true} path={`${path_prefix}/new`} component={PlatformTaxonsNew} />
      <Route exact={true} path={`${path_prefix}/:platform_taxon_id`} component={PlatformTaxonsEdit} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
