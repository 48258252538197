import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Select, Checkbox, Input } from "expano-components";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { createDiscountCode } from "actions/discount_codes";
import { DatePicker, Dashboard } from "components";
import discount_code_type_options from "../../../const/discount_code_type_options";
import count_type_options from "../../../const/count_type_options";
import generatePassword from "../../../const/generatePassword";

const InputField = props => <Input {...props} />;

const form_name = "create-discount_code";
const selector = formValueSelector(form_name);

const BasicData = ({
  history,
  date_start,
  date_end,
  discount_code_type,
  count_type,
  is_unlimited,
  path,
  submitting,
  pristine,
  handleSubmit,
  change,
}) => {
  useEffect(() => {
    if (is_unlimited) {
      change("date_end", null);
    }
  }, [is_unlimited]);
  useEffect(() => {
    if (!!date_end) {
      change("is_unlimited", false);
    }
  }, [date_end]);
  const submit = values =>
    createDiscountCode(values).then(({ data: { id } }) => history.push(`${path}/${id}`));
  return (
    <form noValidate onSubmit={handleSubmit(submit)}>
      <Dashboard
        header={{
          title: "Podstawowe informacje",
          icon: "info",
        }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div className='dashboard__form'>
          <div className='form-row grid-2'>
            <Field label='Nazwa kody rabatowego' name='name' component={InputField} type='text' />
            <DatePicker
              change={change}
              value={date_start ? new Date(date_start) : null}
              name='date_start'
              label='Data rozpoczęcia'
            />
          </div>
          <div className='wrapper jc-sb'>
            <div className='column-half'>
              <Field label='Kod' name='code' component={InputField} type='text' />
              <div style={{ marginTop: "-18px" }}>
                <button
                  style={{ fontSize: "11px", lineHeight: "20px", color: "#5D78FF" }}
                  type='button'
                  onClick={() => change("code", generatePassword(10))}
                >
                  Generuj unikalny kod
                </button>
              </div>
            </div>
            <div className='column-half'>
              <DatePicker
                change={change}
                minDate={new Date()}
                value={date_end ? new Date(date_end) : null}
                name='date_end'
                label='Data zakończenia'
              />
              <div style={{ marginTop: "-14px" }}>
                <Field
                  label='Nie wygasa'
                  name='is_unlimited'
                  component={props => (
                    <Checkbox {...props} input={{ ...props.input, value: is_unlimited }} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='wrapper jc-sb m-t-20'>
            <div className='column-half'>
              <div className='wrapper jc-sb al-fe'>
                <div className='column-half'>
                  <Select
                    label='Typ'
                    name='discount_code_type'
                    options={discount_code_type_options}
                    handleChange={value => change("discount_code_type", value)}
                  />
                </div>
                <div className='column-half'>
                  <Field
                    postfix={
                      discount_code_type === "percentage"
                        ? "%"
                        : discount_code_type === "quantitative"
                        ? "szt"
                        : null
                    }
                    name='discount_code_type_value'
                    component={InputField}
                    type='text'
                  />
                </div>
              </div>
            </div>
            <div className='column-half'>
              <div className='wrapper jc-sb al-fe'>
                <div className='column-half'>
                  <Select
                    label='Ilość wykorzystań'
                    name='count_type'
                    options={count_type_options}
                    handleChange={value => change("count_type", value)}
                  />
                </div>
                <div className='column-half'>
                  <Field
                    postfix='razy'
                    disabled={count_type === "unlimited"}
                    name='available_count'
                    component={InputField}
                    type='text'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};

export default connect(state => ({
  date_start: selector(state, "date_start"),
  date_end: selector(state, "date_end"),
  discount_code_type: selector(state, "discount_code_type"),
  count_type: selector(state, "count_type"),
  is_unlimited: selector(state, "is_unlimited"),
  initialValues: { is_unlimited: false},
}))(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  })(withRouter(BasicData))
);
