import React from "react";
import { Input, Checkbox } from "expano-components";
import { Field } from "redux-form";
import { AsyncSelect, Dashboard } from "components";
import getCategories from "../../../../const/getCategories";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <AsyncSelect {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const BasicForm = ({ change }) => {
  return (
    <Dashboard header={{ title: "Podstawowe informacje", icon: "info" }}>
      <div noValidate className="dashboard__form">
        <Field
          name="name"
          component={InputField}
          label="Nazwa kategorii"
          type="text"
        />

        <div className="form-row grid-2">
          <Field
            name="name"
            component={InputField}
            label="Nazwa kategorii"
            type="hidden"
          />
          <Field
            label="Kategoria nadrzędna"
            name="parent_id"
            component={SelectField}
            getData={getCategories}
            change={change}
          />
          <div class="form-group new-checkbox">
            <div class="inp-wrapper">
              <p class="label-form">Mega menu</p>
              <Field
                component={CheckField}
                name="show_in_mega_menu"
                label="Wyświetl"
              />
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default BasicForm;
