import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  DashboardTabs,
  Dashboard,
  MainTable,
  Main,
} from "components";
import { parseQuery } from "utils/api";
import {
  getOrders,
  filterOrders,
  toggleFilters,
} from "actions/orders";
import { getStatus } from "utils/statuses";

const status_query = {
  confirmed: [1, 2, 3],
  lost: [4],
  cart: [0],
};

const getChannelDomain = (channel_id) => {
  switch (channel_id) {
    case "1":
      return "https://instalguru.pl";
    case "3":
      return "https://domee.pl";
    case "4":
      return "https://naclay.pl";
    case "5":
      return "https://instalguru.de";
    case "6":
      return "https://domowezoo.pl/";
    case "7":
      return "https://milto.pl/";
    default:
      return "";
  }
};

const OrdersList = ({
  orders,
  toggleFilters,
  getOrders,
  filterOrders,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [active_status, setStatus] = useState("confirmed");
  const [statuses, setStatusQuery] = useState(
    status_query.confirmed
  );
  const tabs = [
    {
      name: "Zrealizowane",
      id: "confirmed",
    },
    {
      name: "Porzucone",
      id: "lost",
    },
    {
      name: "Koszyk",
      id: "cart",
    },
  ];
  const sort = {
    "sort[column]": query["sort[column]"] || "created_at",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    getOrders({ ...query, ...sort }, statuses);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getOrders({ ...query, page: undefined }, statuses);
    }
  }, [orders.filters.query, statuses]);

  if (orders.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = orders;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/orders`;

  return (
    <Main breadcrumbs={[{ name: "Zamówienia" }]}>
      <Dashboard
        no_margin
        header={{
          icon: "orders",
          title: "Zamówienia",
        }}
      />
      <DashboardTabs
        data={tabs.map((item) => ({
          ...item,
          active: item.id === active_status,
          onClick: () => {
            setStatus(item.id);
            setStatusQuery(status_query[item.id]);
          },
        }))}
      />
      <Dashboard>
        <MainTable
          is_loading={
            orders.status === "invalid" ||
            orders.status === "loading"
          }
          is_searching={orders.status === "searching"}
          filters_open={orders.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Numer zamówienia", name: "id" },
            { label: "Data", name: "created_at" },
            {
              label: "Adres e-mail",
              name: "client",
              sortable: false,
            },
            { label: "Status realizacji", name: "status" },
            { label: "Kwota", name: "total_price" },
          ]}
          renderRow={({
            id,
            iid,
            token,
            step,
            number,
            ordered_at,
            created_at,
            status,
            total_price,
            shipping_email,
            shipping_first_name,
            shipping_phone,
            shipping_last_name,
            billing_first_name,
            billing__last_name,
            billing_phone,
            billing_email,
          }) => (
            <tr
              key={id}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}>
              <td>
                <p>iid: {iid}</p>
                <p className="text-muted">
                  {" "}
                  number: {number || "-"}
                </p>
              </td>
              <td>
                <p>Zamówiono: {ordered_at || "-"}</p>
                <p className="text-muted">
                  {" "}
                  Utworzono: {created_at || "-"}
                </p>
              </td>
              <td>
                <p>
                  {shipping_first_name ||
                    billing_first_name}{" "}
                  {shipping_last_name || billing__last_name}
                </p>
                <p className="text-muted">
                  {shipping_email || billing_email || "-"}
                </p>
                {(shipping_phone || billing_phone) && (
                  <p className="text-muted">
                    Tel: {shipping_phone || billing_phone}
                  </p>
                )}
              </td>
              <td>{getStatus(status)}</td>
              <td>{`${total_price} zł` || "-"}</td>
              <td>
                {step === "confirmed" && (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={`${getChannelDomain(
                      params.channel_id
                    )}/orders/${id}?order_token=${token}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 488.85 488.85">
                      <path
                        fill="#587DFA"
                        d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
                            s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
                            c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
                            C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
                            c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                      />
                    </svg>
                  </a>
                )}
              </td>
            </tr>
          )}
          empty_text="Brak zamówień"
          filtersAction={filterOrders}
          filters={[
            {
              type: "input",
              label: "Numer zamówienia",
              name: "iid",
              search_type: "eq",
            },
            {
              type: "input",
              label: "Imię i nazwisko",
              name:
                "shipping_first_name_or_billing_first_name_or_shipping_last_name_or_billing_last_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Email",
              name: "shipping_email_or_billing_email",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Numer telefonu",
              name: "shipping_phone_or_billing_phone",
              search_type: "matches",
            },
            {
              type: "date",
              label: "Data zamówienia",
              name: "ordered_at",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data utworzenia",
              name: "created_at",
              search_type: "gt_eq",
            },
          ].map((item) => ({
            ...item,
            default_value: orders.filters.values[item.name],
          }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapStateToProps = ({ orders, channel }) => ({
  orders,
  channel_id: channel.data.id,
});

const mapDispatchToProps = (dispatch) => ({
  getOrders: (data, statuses) =>
    dispatch(getOrders(data, statuses)),
  filterOrders: (data) => dispatch(filterOrders(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersList);
