import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import {
  getDiscounts,
  filterDiscounts,
  toggleFilters,
} from "actions/discounts";
import discount_reference_type_options from "../const/discount_reference_type_options";
import getDiscountStatus from "../const/getDiscountStatus";

const DiscountsList = ({
  discounts,
  toggleFilters,
  getDiscounts,
  filterDiscounts,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscounts({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscounts({ ...query, page: undefined });
    }
  }, [discounts.filters.query]);

  if (discounts.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = discounts;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/discounts`;
  return (
    <Main breadcrumbs={[{ name: "Promocje" }]}>
      <Dashboard
        header={{
          icon: "discounts",
          title: "Promocje",
          aside: (
            <Button type="add" text="Dodaj promocję" path={`${path}/new`} />
          ),
        }}>
        <MainTable
          is_loading={
            discounts.status === "invalid" || discounts.status === "loading"
          }
          is_searching={discounts.status === "searching"}
          filters_open={discounts.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id", style: { width: 100 } },
            { label: "Nazwa Promocji", name: "name" },
            { label: "Data rozpoczęcia", name: "date_start" },
            { label: "Data zakończenia", name: "date_end" },
            { label: "Wartość", name: "discount_value" },
            { label: "Zakres", name: "discount_reference_type" },
            { label: "Status", name: "status", sortable: false },
          ]}
          renderRow={({
            id,
            name,
            date_start,
            date_end,
            discount_type,
            discount_value,
            discount_reference_type,
            is_unlimited,
          }) => (
            <tr
              key={id}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                {date_start
                  ? moment(date_start).format("DD.MM.YYYY HH:mm:ss")
                  : "-"}
              </td>
              <td>
                {date_end
                  ? moment(date_end).format("DD.MM.YYYY HH:mm:ss")
                  : "-"}
              </td>
              <td>
                {discount_value}
                {discount_type === "percentage"
                  ? "%"
                  : discount_type === "quantitative"
                  ? " szt."
                  : ""}
              </td>
              <td>
                {
                  discount_reference_type_options.find(
                    ({ value }) => value === discount_reference_type
                  )?.label
                }
              </td>
              <td>{getDiscountStatus(date_start, date_end, is_unlimited)}</td>
            </tr>
          )}
          empty_text="Brak promocji"
          filtersAction={filterDiscounts}
          filters={[
            {
              type: "input",
              label: "Nazwa promocji",
              name: "name",
              search_type: "matches",
            },
            {
              type: "select",
              label: "Status",
              name: "discount_status",
              search_type: "discount_status",
              options: [
                { value: "all", label: "Wszystkie" },
                { value: "planned", label: "Zaplanowana" },
                { value: "active", label: "Aktywna" },
                { value: "unlimited", label: "Nie wygasa" },
                { value: "finished", label: "Zakończona" },
              ],
            },
            {
              type: "select",
              label: "Zakres",
              search_type: "in",
              name: "discount_reference_type",
              options: [
                { value: 0, label: "Brak" },
                { value: 1, label: "Produkty" },
                { value: 2, label: "Kolekcje" },
                { value: 3, label: "Kategorie" },
                { value: 4, label: "Producenci" },
              ],
            },
            {
              type: "date",
              label: "Data rozpoczęcia",
              name: "date_start",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data zakończenia",
              name: "date_end",
              search_type: "lt_eq",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDiscounts: (data) => dispatch(getDiscounts(data)),
  filterDiscounts: (data) => dispatch(filterDiscounts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ discounts }) => ({ discounts }),
  mapDispatchToProps
)(DiscountsList);
