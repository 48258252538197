import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tree from "./components/Tree";
import { PageLoader, Button, Main } from "components";
import { getPlatformTaxons } from "actions/platform_taxons";

const PlatformTaxonsList = ({ getPlatformTaxons, platform_taxons, history, match: { params } }) => {
  useEffect(() => {
    getPlatformTaxons({});
  }, []);
  if (!platform_taxons || platform_taxons.status === "invalid" || platform_taxons.status === "loading") return <PageLoader color='#2959F8' />;

  if (platform_taxons.status === "failure") {
    return <p>Error</p>;
  }
  const { data } = platform_taxons;
  return (
    <Main breadcrumbs={[{ name: "Kategorie platformy" }]}>
      <div className='dashboard'>
        <header className='dashboard__header'>
          <div className='icon__container'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
              <defs></defs>
              <rect fill='none' width='20' height='20' />
              <path
                fill='#5d78ff'
                fillRule='evenodd'
                opacity='0.3'
                d='M4.833,5H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,5Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z'
                transform='translate(-0.667 -0.833)'
              />
              <path
                fill='#5d78ff'
                fillRule='evenodd'
                d='M4.833,9H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,9Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,1,1,0-1.667Z'
                transform='translate(-0.667 -1.5)'
              />
            </svg>
          </div>
          <h2 className='dashboard__title heading'>Kategorie platformy</h2>
          <Button type='add' text='Dodaj kategorię platformy' path={`/platforms/${params.platform_id}/platform_taxons/new`} />
        </header>
        {data?.length > 0 && (
          <Tree
            history={history}
            head={["Nazwa", "ID", "Slug", "Działania"]}
            data={data}
            row_data={["id", "slug"]}
            path={`/platforms/${params.platform_id}/platform_taxons`}
          />
        )}
      </div>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getPlatformTaxons: data => dispatch(getPlatformTaxons(data)),
});
export default connect(({ platform_taxons }) => ({ platform_taxons }), mapDispatchToProps)(withRouter(PlatformTaxonsList));
