import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Main, PageLoader } from "components";
import { getPage } from "actions/pages";
import { BasicData, Content, Seo } from "./components";

const PageEdit = ({
  getPage,
  handleSubmit,
  pristine,
  history,
  change,
  match: { params },
  page,
}) => {
  useEffect(() => {
    getPage(params.id);
  }, []);
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/pages`;
  const handleCancel = () => history.push(path);
  if (!page || page.status === "invalid" || page.status === "loading") return <PageLoader />;

  if (page.status === "failure") {
    return <p>Error</p>;
  }
  return (
    <Main breadcrumbs={[{ name: "Strony statyczne", path }, { name: page.data?.title }]}>
      <BasicData handleCancel={handleCancel} />
      <div className='dashboard-wrapper-flex'>
        <div className='column-2'>
          <Content handleCancel={handleCancel} />
        </div>
        <div className='column-1'>
          <Seo handleCancel={handleCancel} />
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ page }) => ({
  page,
});

const mapDispatchToProps = dispatch => ({
  getPage: id => dispatch(getPage(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageEdit);
