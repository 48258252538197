export const transformUpdateDate = text => {
  if (!text) return "";
  const date = text
    .split("T")[0]
    .split("-")
    .reverse()
    .join("-");
  const time = text
    .split("T")[1]
    .split(":")
    .slice(0, 2)
    .join(".");
  return `${date}, ${time}`;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const getDate = val => {
  const year = val.getFullYear();
  const month = val.getMonth() + 1 < 10 ? `0${val.getMonth() + 1}` : val.getMonth() + 1;
  const day = val.getDate() < 10 ? `0${val.getDate()}` : val.getDate();
  const hours = val.getHours() < 10 ? `0${val.getHours()}` : val.getHours();
  const minutes = val.getMinutes() < 10 ? `0${val.getMinutes()}` : val.getMinutes();
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
