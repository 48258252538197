import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "AUTHORS_TOGGLE_FILTERS" });
};

export const filterAuthors = data => async dispatch => {
  dispatch({ type: "AUTHORS_SET_FILTERS_QUERY", data });
};

export const getAuthors = ({ page = 1, per_page = 10, ...rest }) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().authors;
  status === "invalid" ? dispatch({ type: "AUTHORS_LOADING" }) : dispatch({ type: "AUTHORS_SEARCHING" });
  await client
    .get(`/authors?${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "AUTHORS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "AUTHORS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getBlogAuthorPosts = ({ page = 1, per_page = 10, ...rest }) => async dispatch => {
  dispatch({ type: "AUTHOR_POSTS_LOADING" });
  await client
    .get(`/articles?${stringifyQuery({ ...rest, page, per_page })}&q[author_id_eq]=${rest.author_id}`)
    .then(({ data }) => {
      dispatch({
        type: "AUTHOR_POSTS_SUCCESS",
        data: { data: apiFormatter(data), meta: data.meta },
      });
    })
    .catch(() => {
      dispatch({ type: "AUTHOR_POSTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getAuthor = id => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "AUTHOR_LOADING" });
    await client
      .get(`/authors/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "AUTHOR_SUCCESS",
          data: apiFormatter(data),
        });
        resolve(apiFormatter(data));
      })
      .catch(() => {
        dispatch({ type: "AUTHOR_FAILURE" });
        reject();
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      });
  });

export const deleteAuthor = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/authors/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createAuthor = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/authors`, data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updateAuthor = (id, author) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/authors/${id}`, author)
      .then(({ data }) => {
        dispatch({
          type: "AUTHOR_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(() => {
        dispatch({ type: "AUTHOR_FAILURE" });
        reject();
      });
  });
