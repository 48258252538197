import React from "react";

const Edit = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='#5d78ff'
      d='M0,13.262V3.306a1.25,1.25,0,0,1,.375-.893L2.471.357A1.25,1.25,0,0,1,4.208.344l2.07,1.969a1.25,1.25,0,0,1,.389.906V13.262a1.25,1.25,0,0,1-1.25,1.25H1.25A1.25,1.25,0,0,1,0,13.262Z'
      transform='translate(7.226 16.411) rotate(-135)'
    />
    <rect fill='#5d78ff' opacity='0.3' width='13' height='1' rx='0.5' transform='translate(4 17)' />
  </svg>
);
export default Edit;
