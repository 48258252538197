import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { handleLogout } from "actions/account";
import { getPrimaryData } from "actions/app";
import { Topbar, removeTab } from "expano-components";
import { PageLoader, Sidebar } from "components";
import Router from "./Router";
const ProtectedView = ({
  app,
  channels,
  channel,
  appInfo,
  tabs,
  addTab,
  removeTab,
  sidebar,
  history,
  match,
  getPrimaryData,
}) => {
  useEffect(() => {
    getPrimaryData(match);
  }, []);

  if (app.status === "invalid" || app.status === "loading") {
    return <PageLoader />;
  }
  if (app.status === "failure") {
    return <p>Błąd pobierania danych</p>;
  }
  return (
    <>
      <Sidebar appInfo={appInfo} />
      <main className='main-container'>
        <Topbar
          appInfo={appInfo}
          handleLogout={handleLogout}
          tabs={tabs}
          history={history}
          removeTab={removeTab}
        />
        <Router />
      </main>
    </>
  );
};

const mapStateToProps = ({ app, tabs, channels, channel }) => ({
  app,
  channels,
  channel,
  tabs,
});

const mapDispatchToProps = dispatch => ({
  getPrimaryData: match => dispatch(getPrimaryData(match)),
  removeTab: (tab, history) => dispatch(removeTab(tab, history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProtectedView));
