import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dashboard, MainTable, Main, ProductSaleStatus } from "components";
import { parseQuery } from "utils/api";
import {
  getChannelProducts,
  filterChannelProducts,
  toggleFilters,
} from "actions/channel_products";

const ChannelProductsList = ({
  channel_products,
  toggleFilters,
  getChannelProducts,
  filterChannelProducts,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getChannelProducts({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getChannelProducts({ ...query, page: undefined });
    }
  }, [channel_products.filters.query]);

  if (channel_products.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = channel_products;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_products`;
  return (
    <Main breadcrumbs={[{ name: "Produkty" }]}>
      <Dashboard
        header={{
          icon: "products",
          title: "Produkty",
        }}>
        <MainTable
          is_loading={
            channel_products.status === "invalid" ||
            channel_products.status === "loading"
          }
          is_searching={channel_products.status === "searching"}
          filters_open={channel_products.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "", name: "image", sortable: false, style: { width: 70 } },
            { label: "ID", name: "id" },
            { label: "SKU", name: "sku" },
            { label: "Nazwa", name: "name" },
            { label: "Producent", name: "producer", sortable: false },
            { label: "Status", name: "sales_status" },
          ]}
          renderRow={({
            id,
            sales_status,
            name,
            sku,
            product,
            channel_product_gallery_images,
          }) => (
            <tr
              key={id}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}>
              <td>
                <img
                  src={channel_product_gallery_images?.[0]?.url}
                  alt={name}
                  style={{ width: 50, height: 50, margin: "-10px 0 -15px" }}
                />
              </td>
              <td>{id}</td>
              <td>{sku || product?.sku}</td>
              <td>{name || product?.name}</td>
              <td>{product?.producer?.name}</td>
              <td>
                <ProductSaleStatus status={sales_status} />
              </td>
            </tr>
          )}
          empty_text="Brak produktów"
          filtersAction={filterChannelProducts}
          filters={[
            {
              type: "input",
              label: "Sku lub nazwa produktu",
              name: "sku_or_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Producent",
              name: "product_producer_name",
              search_type: "matches",
            },
          ].map((item) => ({
            ...item,
            default_value: channel_products.filters.values[item.name],
          }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapStateToProps = ({ channel_products, channel }) => ({
  channel_products,
  channel_id: channel.data.id,
});

const mapDispatchToProps = (dispatch) => ({
  getChannelProducts: (page, per_page, query, loading) =>
    dispatch(getChannelProducts(page, per_page, query, loading)),
  filterChannelProducts: (data) => dispatch(filterChannelProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelProductsList);
