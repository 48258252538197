import React from "react";
import { connect } from "react-redux";

const Input = ({ label, value }) => (
  <div className='form-group'>
    <div className='inp-wrapper'>
      <label className='label-form'>{label}</label>
      <div className='inp-group'>
        <input type='text' value={value} readOnly />
      </div>
    </div>
  </div>
);

const ProductSpecification = ({ channel_product }) => {
  return (
    <div className='dashboard-wrapper-flex'>
      <div className='column-half'>
        <div className='dashboard'>
          <header className='dashboard__header dashboard__header--edit'>
            <div className='icon__container'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                <rect style={{ fill: "none" }} width='20' height='20' />
                <circle fill='#5d78f9' opacity='0.3' cx='7.5' cy='7.5' r='7.5' transform='translate(2.5 2.5)' />
                <path
                  fill='#5d78f9'
                  opacity='0.3'
                  d='M10.28,18A7.5,7.5,0,0,1,3,10.5q0-.1,0-.207c.549.053,1.544.172,1.591.558.065.534,0,1.2.455,1.4s.584-.8.974-.534,1.428.524,1.428,1-.195,1.206,0,1.339a5.282,5.282,0,0,1,.974,1.2c0,.133.274.812.193,1.072a3.676,3.676,0,0,1-.742,1.187,7.285,7.285,0,0,0,2.4.485ZM7.4,3.668A7.508,7.508,0,0,1,17.2,7.12a3.815,3.815,0,0,0,.166,1.407c.731,1.023-1.781,1.726-1.4,3.273.168.682-1.67.466-2.119,1.1s-1.547.21-1.8.087-1.229.286-1.144-.409.678-.737,1.314-1.023,1.553-1.237,1.1-1.362A4.494,4.494,0,0,1,10.47,7.565c-.127-.025-.1-1.448-.646-1.489a7.36,7.36,0,0,1-1.653,0c-.806-.164-.636-.7-.763-1.841A2.121,2.121,0,0,1,7.4,3.668ZM18,10.4q0,.051,0,.1A7.5,7.5,0,0,1,10.575,18,7.614,7.614,0,0,0,18,10.4Z'
                  transform='translate(-0.5 -0.5)'
                />
              </svg>
            </div>
            <h2 className='dashboard__title heading'>Właściwości globalne</h2>
          </header>
          {channel_product?.product?.product_group?.product_group_properties?.length > 0 && (
            <div className='dashboard__form'>
              {channel_product?.product?.product_group?.product_group_properties.map(({ id, property, property_option }) => (
                <Input key={id} label={property?.external_name?.pl} value={property_option?.value?.pl} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='column-half'>
        <div className='dashboard'>
          <header className='dashboard__header dashboard__header--edit'>
            <div className='icon__container'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                <path style={{ fill: "none" }} d='M0,0H20V20H0Z' />
                <path
                  fill='#5d78f9'
                  d='M10.606,15.71,7.2,17.5a.833.833,0,0,1-1.209-.878l.65-3.79L3.89,10.147a.833.833,0,0,1,.462-1.421l3.805-.553,1.7-3.448a.833.833,0,0,1,1.495,0l1.7,3.448,3.805.553a.833.833,0,0,1,.462,1.421l-2.754,2.684.65,3.79a.833.833,0,0,1-1.209.878Z'
                  transform='translate(-0.606 -0.71)'
                />
              </svg>
            </div>
            <h2 className='dashboard__title heading'>Właściwości różnicujące</h2>
          </header>
          {channel_product?.product?.product_properties?.length > 0 && (
            <div className='dashboard__form'>
              {channel_product?.product?.product_properties.map(({ id, property, property_option }) => (
                <Input key={id} label={property?.external_name?.pl} value={property_option?.value?.pl} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(({ channel_product }) => ({
  channel_product: channel_product?.data,
}))(ProductSpecification);
