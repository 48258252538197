import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "expano-components";
import { withRouter } from "react-router-dom";
import SidebarItem from "./components/Item";
import Logotype from "./components/Logotype";
import SidebarSelect from "./components/SidebarSelect";
import nav_data from "./nav_data";

const Sidebar = ({ appInfo, sidebar, channel, channels, match, toggleSidebar, platform, platforms, location }) => {
  return (
    <aside className={`sidebar ${!sidebar.isOpen ? "collapsed" : ""}`}>
      <header className='sidebar__header'>
        <Logotype name={appInfo.name} />
        <button className='sidebar__button' onClick={toggleSidebar}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='24px'
            height='24px'
            viewBox='0 0 24 24'
            version='1.1'
            className='kt-svg-icon'
          >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <polygon points='0 0 24 0 24 24 0 24' />
              <path
                className='arrow'
                d='M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z'
                fill='#494B74'
                fillRule='nonzero'
                transform='translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) '
              />
              <path
                className='arrow'
                d='M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z'
                fill='#494B74'
                fillRule='nonzero'
                opacity='0.3'
                transform='translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) '
              />
            </g>
          </svg>
        </button>
      </header>
      <p className='nav-category'>Platforma</p>
      <SidebarSelect
        onChange={({ value }) => {
          window.location = `/platforms/${value}/platform_taxons`;
        }}
        options={platforms.map(({ id, name }) => ({ label: name, value: id }))}
        value={{ label: platform.name, value: platform.id }}
      />
      <p className='nav-category'>Kanał</p>
      <SidebarSelect
        onChange={({ value }) => {
          window.location = `/platforms/${match.params.platform_id}/channels/${value}`;
        }}
        options={channels.map(({ id, name }) => ({ label: name, value: id }))}
        value={{ label: channel.name, value: channel.id }}
      />
      <nav>
        {nav_data({ platform_id: platform.id, channel_id: channel.id })?.channels.map(({ name, items }) => (
          <React.Fragment key={name}>
            {name && <p className='nav-category'>{name}</p>}
            <ul>
              {items.map((item, index) => (
                <SidebarItem key={item.id} {...item} location={location} />
              ))}
            </ul>
          </React.Fragment>
        ))}
      </nav>
    </aside>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});
export default connect(
  ({ sidebar, channels, channel, platform, platforms }) => ({
    platforms: platforms.data,
    platform: platform.data,
    channels: channels.data,
    channel: channel.data,
    sidebar,
  }),
  mapDispatchToProps
)(withRouter(Sidebar));
