import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getClients, filterClients, toggleFilters } from "actions/clients";

const ClientsList = ({
  clients,
  toggleFilters,
  getClients,
  filterClients,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "created_at",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    getClients({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getClients({ ...query, page: undefined });
    }
  }, [clients.filters.query]);

  if (clients.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = clients;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/clients`;
  return (
    <Main breadcrumbs={[{ name: "Klienci" }]}>
      <Dashboard
        header={{
          icon: "clients",
          title: "Klienci",
          aside: <Button type='add' text='Dodaj klienta' path={`${path}/new`} />,
        }}
      >
        <MainTable
          is_loading={clients.status === "invalid" || clients.status === "loading"}
          is_searching={clients.status === "searching"}
          filters_open={clients.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Imię", name: "first_name" },
            { label: "Nazwsiko", name: "last_name" },
            { label: "Adres e-mail", name: "email" },
            { label: "Miasto", name: "city", sortable: false },
          ]}
          renderRow={({ id, first_name, last_name, email, client_shipping_address }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{first_name}</td>
              <td>{last_name}</td>
              <td>{email}</td>
              <td>{client_shipping_address?.city}</td>
            </tr>
          )}
          empty_text='Brak klientów'
          filtersAction={filterClients}
          filters={[
            {
              type: "input",
              label: "Imię",
              name: "first_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Nazwisko",
              name: "last_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Adres e-mail",
              name: "email",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Miasto",
              name: "client_shipping_address_city",
              search_type: "matches",
            },
          ].map(item => ({ ...item, default_value: clients.filters.values[item.name] }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapStateToProps = ({ clients, channel }) => ({ clients, channel_id: channel.data.id });

const mapDispatchToProps = dispatch => ({
  getClients: (page, per_page, query, loading) =>
    dispatch(getClients(page, per_page, query, loading)),
  filterClients: data => dispatch(filterClients(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
