import React from "react";
import { connect } from "react-redux";
import { Button, Textarea, Input } from "expano-components";
import { Dashboard, CreatableSelect } from "components";
import { Field, reduxForm, reset } from "redux-form";
import { updateCollection } from "actions/collections";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

const Seo = ({
  collection,
  submitting,
  pristine,
  handleSubmit,
  updateCollection,
  dispatch,
  initialValues,
  change,
}) => {
  const submit = values => updateCollection(collection.id, values);
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "SEO", icon: "info" }}
        footer={{
          buttons: (
            <>
              {!pristine && (
                <Button
                  type='cancel'
                  onClick={() => dispatch(reset("edit-channel-producer-seo"))}
                />
              )}
              <Button
                type='save'
                text='Zapisz'
                disabled={pristine}
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}
      >
        <div className='dashboard__form'>
          <Field name='meta_title' label='Meta title' component={InputField} type='text' />
          <Field name='meta_description' label='Meta description' component={TextareaField} />
          <Field name='slug' label='Slug URL' component={InputField} type='text' />
          <Field name='keyword_list' component={InputField} type='hidden' />
          <Field name='tag_list' component={InputField} type='hidden' />

          <CreatableSelect
            label='Keywords'
            onChange={value => change("keyword_list", value)}
            defaultValue={
              initialValues.keyword_list?.length > 0
                ? initialValues.keyword_list.map(item => ({ label: item, value: item }))
                : []
            }
          />
          <CreatableSelect
            label='Tagi'
            onChange={value => change("tag_list", value)}
            defaultValue={
              initialValues.tag_list?.length > 0
                ? initialValues.tag_list.map(item => ({ label: item, value: item }))
                : []
            }
          />
        </div>
      </Dashboard>
    </form>
  );
};
const mapDispatchToProps = dispatch => ({
  updateCollection: (id, data) => dispatch(updateCollection(id, data)),
});
export default connect(
  ({ collection }) => ({
    collection,
    initialValues: {
      slug: collection?.slug,
      meta_title: collection?.meta_title,
      meta_description: collection?.meta_description,
      is_no_index: collection?.is_no_index,
      tag_list: collection.tag_list,
      keyword_list: collection?.keyword_list,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-channel-producer-seo",
    enableReinitialize: true,
  })(Seo)
);
