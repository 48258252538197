import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";
import { sidebarReducer } from "expano-components";
import current_user from "./current_user";
import pages from "./pages";
import page from "./page";
import channel from "./channel";
import channels from "./channels";
import platform from "./platform";
import platforms from "./platforms";
import shipping_methods from "./shipping_methods";
import article from "./article";
import articles from "./articles";
import author from "./author";
import author_posts from "./author_posts";
import authors from "./authors";
import clients from "./clients";
import client from "./client";
import client_orders from "./client_orders";
import channel_products from "./channel_products";
import channel_product from "./channel_product";
import orders from "./orders";
import order from "./order";
import payment_methods from "./payment_methods";
import channel_taxon_products from "./channel_taxon_products";
import platform_taxons from "./platform_taxons";
import platform_taxon from "./platform_taxon";
import channel_taxons from "./channel_taxons";
import channel_taxon from "./channel_taxon";
import channel_producers from "./channel_producers";
import channel_producer from "./channel_producer";
import channel_producer_products from "./channel_producer_products";

import collections from "./collections";
import collection from "./collection";
import collection_products from "./collection_products";

import discounts from "./discounts";
import discount from "./discount";
import discount_collections from "./discount_collections";
import discount_products from "./discount_products";
import discount_producers from "./discount_producers";
import discount_taxons from "./discount_taxons";

import discount_codes from "./discount_codes";
import discount_code from "./discount_code";
import discount_code_collections from "./discount_code_collections";
import discount_code_products from "./discount_code_products";
import discount_code_producers from "./discount_code_producers";
import discount_code_taxons from "./discount_code_taxons";

import banners from "./banners";
import sliders from "./sliders";
import home_discounts from "./home_discounts";
import home_sections from "./home_sections";

import app from "./app";

export default combineReducers({
  current_user,
  banners,
  app,
  article,
  articles,
  author_posts,
  author,
  authors,
  channel_taxons,
  channel_taxon,
  channel_taxon_products,
  channel_products,
  channel_product,
  channel_producers,
  channel_producer_products,
  channel_producer,
  collections,
  collection,
  collection_products,
  channel,
  channels,
  client,
  client_orders,
  clients,
  discounts,
  discount,
  discount_collections,
  discount_products,
  discount_producers,
  discount_taxons,
  discount_codes,
  discount_code,
  discount_code_collections,
  discount_code_products,
  discount_code_producers,
  discount_code_taxons,
  home_discounts,
  page,
  form,
  order,
  orders,
  pages,
  payment_methods,
  platform,
  platforms,
  platform_taxon,
  platform_taxons,
  home_sections,
  sidebar: sidebarReducer,
  shipping_methods,
  sliders,
  toastr,
});
