import React, { useState, useEffect } from "react";
import { Field, reduxForm, SubmissionError, formValueSelector, reset } from "redux-form";
import { connect } from "react-redux";
import { Button, Textarea, ImageUploader, Input } from "expano-components";
import { Dashboard, DatePicker, AsyncSelect } from "components";
import { updateArticle } from "actions/articles";
import { getDate, toBase64 } from "utils/helpers";
import getAuthors from "./const/getAuthors";

const selector = formValueSelector("edit-article-basic-info");

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;
const SelectField = props => <AsyncSelect {...props} />;

const BasicInfo = ({
  article,
  updateArticle,
  handleSubmit,
  pristine,
  change,
  meta_created_at,
  meta_updated_at,
  dispatch,
}) => {
  const [image, setImage] = useState(!!article?.image_url ? [{ path: article.image_url }] : []);

  useEffect(() => {
    if (!!!article?.meta_created_at) {
      change("meta_created_at", getDate(new Date()));
    }
    if (!!!article?.meta_updated_at) {
      change("meta_updated_at", getDate(new Date()));
    }
  }, []);

  const submit = values =>
    updateArticle(article.id, values).catch(err => {
      console.log(err);
      throw new SubmissionError(err);
    });

  const handleUploadImage = file => {
    return new Promise(async (resolve, reject) => {
      const file_data = await toBase64(file);
      setImage([{ path: file_data }]);
      change("image_data", file_data);
      resolve();
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Podstawowe informacje", icon: "info" }}
        footer={{
          buttons: [
            !pristine && (
              <Button
                type='cancel'
                onClick={() => dispatch(reset("edit-article-basic-info"))}
              />
            ),
            <Button type='save' text='Zapisz' disabled={pristine} onClick={handleSubmit(submit)} />,
          ],
        }}
      >
        <div className='dashboard__form'>
          <div className='dashboard-wrapper-flex'>
            <div className='column-half'>
              <Field name='title' label='Tytuł wpisu' component={InputField} type='text' />
              <Field
                label='Autor'
                name='author_id'
                component={SelectField}
                getData={getAuthors}
                change={change}
                initValue={
                  article?.author
                    ? {
                        label: `${article.author.first_name} ${article.author.last_name}`,
                        value: article.author.id,
                      }
                    : null
                }
              />
              <Field name='intro' label='Intro' component={TextareaField} minHeight={130} />
            </div>
            <div className='column-half'>
              <DatePicker
                value={meta_created_at ? new Date(meta_created_at) : null}
                change={change}
                name='meta_created_at'
                label='Data publikacji'
              />
              <DatePicker
                value={meta_updated_at ? new Date(meta_updated_at) : null}
                change={change}
                name='meta_updated_at'
                label='Data aktualizacji'
              />
              <div className='form-group'>
                <div className='inp-wrapper'>
                  <label htmlFor='main_image'>Zdjęcie główne</label>
                  <ImageUploader
                    addText='dodaj grafikę'
                    url={""}
                    images={image}
                    limit={1}
                    handleUpload={handleUploadImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </form>
  );
};

const mapStateToProps = state => ({
  article: state.article,
  channel_id: state.channel.data.id,
  initialValues: {
    author_id: state.article?.author?.id,
    title: state.article?.title,
    intro: state.article?.intro,
    meta_created_at: state.article?.meta_created_at,
    meta_updated_at: state.article?.meta_updated_at,
    meta_title: state.article?.meta_title,
  },
  meta_created_at: selector(state, "meta_created_at"),
  meta_updated_at: selector(state, "meta_updated_at"),
});

const mapDispatchToProps = dispatch => ({
  updateArticle: (id, data) => dispatch(updateArticle(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "edit-article-basic-info", enableReinitialize: true })(BasicInfo));
