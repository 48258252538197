import React, { useState } from "react";
import { Field } from "redux-form";
import Editor from "react-markdown-editor-lite";
import { Input } from "expano-components";
import { Dashboard } from "components";

const Content = ({ change }) => {
  const [text, setText] = useState("");

  return (
    <Dashboard header={{ title: "Content", icon: "edit" }}>
      <div className="dashboard__form">
        <Field
          component={(props) => <Input {...props} />}
          type="hidden"
          name="content"
        />
        <Editor
          plugins={["header", "fonts", "link"]}
          value={text}
          config={{
            view: {
              menu: true,
              md: true,
              html: false,
              fullScreen: false,
              hideMenu: false,
            },
          }}
          style={{ height: "600px" }}
          onChange={({ text }) => {
            change("content", text);
            setText(text);
          }}
        />
      </div>
    </Dashboard>
  );
};

export default Content;
