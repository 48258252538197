import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { MainTable } from "components";
import { Button, ModalDelete } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getDiscountCollections,
  filterDiscountCollections,
  toggleFilters,
  deleteDiscountCollectionProduct,
} from "actions/discount_collections";
import CollectionsModal from "./components/CollectionsModal";
import DiscountReferenceTypeSelect from "../DiscountReferenceTypeSelect";

const DiscountCollectionsList = ({
  discount_collections,
  getDiscountCollections,
  updateCollectionProducts,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [is_shown_collections_modal, handleShowCollectionsModal] = useState(false);
  const [is_shown_delete_collections_modal, handleShowDeleteCollectionsModal] = useState(false);
  const [collections_checked, setCollectionChecked] = useState([]);

  const sort = {
    "sort[column]": query["sort[column]"] || "collection_id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getDiscountCollections({ ...query, ...sort }, params.id);
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getDiscountCollections({ ...query, page: undefined }, params.id);
    }
  }, [discount_collections.filters.query]);

  const handleReload = () =>
    getDiscountCollections({ ...query, ...sort, page: undefined }, params.id);

  const handleDeleteCollections = () => {
    const promises = collections_checked.map(id => deleteDiscountCollectionProduct(id));
    Promise.all(promises).then(() => {
      setCollectionChecked([]);
      toastr.success("Sukces", "Kolekcje zostały usunięte");
      handleReload();
      handleShowDeleteCollectionsModal(false);
    });
  };

  if (discount_collections.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = discount_collections;
  return (
    <>
      {is_shown_collections_modal && (
        <CollectionsModal
          handleClose={() => handleShowCollectionsModal(false)}
          handleReload={handleReload}
        />
      )}
      {is_shown_delete_collections_modal && (
        <ModalDelete
          name='zaznaczone kolekcje'
          handleClose={() => handleShowDeleteCollectionsModal(false)}
          handleDelete={handleDeleteCollections}
        />
      )}
      <MainTable
        is_loading={
          discount_collections.status === "invalid" || discount_collections.status === "loading"
        }
        is_searching={discount_collections.status === "searching"}
        filters_open={discount_collections.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        buttons={
          <div className='wrapper jc-sb al-fe' style={{ width: "100%" }}>
            <div style={{ flexBasis: 230, maxWidth: 230 }}>
              <DiscountReferenceTypeSelect />
            </div>
            <div className='wrapper'>
              {collections_checked.length > 0 && (
                <Button
                  type='delete'
                  text='Usuń zaznaczone'
                  onClick={() => handleShowDeleteCollectionsModal(true)}
                />
              )}
              <Button
                type='add'
                text='Dodaj kolekcję'
                onClick={() => handleShowCollectionsModal(true)}
              />
            </div>
          </div>
        }
        head={[
          {
            label: "check_all",
            name: "check_all",
            checked: data?.length > 0 ? data?.length === collections_checked.length : false,
            onChange: ({ target: { checked } }) =>
              checked ? setCollectionChecked(data.map(({ id }) => id)) : setCollectionChecked([]),
          },
          { label: "ID", name: "collection_id", style: { width: 140 } },
          { label: "Nazwa", name: "collection_name", sortable: false },
        ]}
        renderRow={({ id, collection: { id: collection_id, name } }) => (
          <tr
            key={id}
            onClick={() => {
              const checked = collections_checked.find(item => item === id);
              !checked
                ? setCollectionChecked([...collections_checked, id])
                : setCollectionChecked(collections_checked.filter(item => item !== id));
            }}
          >
            <td>
              <div className='check-group' style={{ margin: 0 }}>
                <input
                  id={`discount_collection_${id}`}
                  type='checkbox'
                  checked={!!collections_checked.find(item => item === id)}
                  readOnly
                />
                <label
                  htmlFor={`gdn_${id}`}
                  className='label-form'
                  style={{ minHeight: 16, margin: 0, display: "block" }}
                />
              </div>
            </td>
            <td>{collection_id}</td>
            <td>{name}</td>
          </tr>
        )}
        empty_text='Brak kolekcji'
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getDiscountCollections: (data, id) => dispatch(getDiscountCollections(data, id)),
  filterDiscountCollections: data => dispatch(filterDiscountCollections(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ discount_collections }) => ({
    discount_collections,
  }),
  mapDispatchToProps
)(withRouter(DiscountCollectionsList));
