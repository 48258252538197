import styled from "styled-components";

const StyledFilters = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid #ebedf2;
  .filters-grid {
    display: none;
    flex-flow: wrap;
    padding-bottom: 20px;
    &.open {
      display: flex;
    }
  }
  .filers-collapse {
    text-align: right;
    .btn-collapse {
      font-size: 12px;
      line-height: 20px;
      color: #a2a5b9;
      padding: 5px;
      svg {
        max-width: 20px;
        margin-left: 4px;
      }
    }
  }
  .form-group {
    padding-top: 10px;
    flex-basis: 25%;
    max-width: 25%;
    padding-right: 20px;
    min-width: 300px;
    margin-bottom: 0;
    .inp-group {
      input {
        padding: 9px;
        border-radius: 4px;
        border: 1px solid #e2e5ec;
        width: 100%;
        outline: none;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
  .calendar-group {
    & > div {
      width: 100%;
    }
    .react-date-picker,
    .react-datetime-picker {
      &__wrapper {
        width: 100%;
        padding: 0px;
        border-radius: 4px;
        border: none;
        border: 1px solid #e2e5ec;
        width: 100%;
        outline: none;
        transition: 0.2s;
      }
      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background: #f3f6f9;
        border-left: 1px solid #e2e5ec;
      }
      &__inputGroup {
        padding: 5px 10px;
      }
    }
  }
`;
export default StyledFilters;
