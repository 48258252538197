import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "CHANNEL_TAXON_PRODUCTS_TOGGLE_FILTERS" });
};

export const filterChannelTaxonProducts = data => async dispatch => {
  dispatch({ type: "CHANNEL_TAXON_PRODUCTS_SET_FILTERS_QUERY", data });
};

export const getChannelTaxonProducts = (
  { page = 1, per_page = 10, ...rest },
  category_id
) => async (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().channel_taxon_products;
  status === "invalid"
    ? dispatch({ type: "CHANNEL_TAXON_PRODUCTS_LOADING" })
    : dispatch({ type: "CHANNEL_TAXON_PRODUCTS_SEARCHING" });
  await client
    .get(
      `/channel_products?include=channel_product_gallery_images,product.producer&q[channel_taxon_id_eq]=${category_id}&${stringifyQuery(
        {
          ...rest,
          page,
          per_page,
          ...query,
        }
      )}`
    )
    .then(({ data }) => {
      dispatch({
        type: "CHANNEL_TAXON_PRODUCTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "CHANNEL_TAXON_PRODUCTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};
