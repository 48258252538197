import React, { useEffect } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import ReduxToastr from "react-redux-toastr";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { authorizeUser } from "utils/api";
import store, { history } from "./store";
import GlobalStyles from "./theme/globalStyles";
import App from "./app_main";
import AppPlatform from "./app_platform";
import { NotFound } from "pages";

import "sanitize.css/sanitize.css";
import "./styles/theme.scss";

const AppMain = () => {
  useEffect(() => {
    authorizeUser();
  }, []);
  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        <CookiesProvider>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            position='top-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
            closeOnToastrClick
          />
          <ConnectedRouter history={history}>
            <Switch>
              <Route path='/platforms/:platform_id/platform_taxons' component={AppPlatform} />
              <Route path='/platforms/:platform_id/channels/:channel_id' component={App} />
              <Redirect to='/platforms/1/channels/1' />
              <Route component={NotFound} />
            </Switch>
          </ConnectedRouter>
        </CookiesProvider>
      </Provider>
    </>
  );
};

render(<AppMain />, document.querySelector("#root"));
