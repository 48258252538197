import React, {useState} from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Dashboard } from "components";
import { ImageUploader } from "expano-components";
import { toBase64 } from "utils/helpers";

const Banner = ({ change }) => {
  const [images, setImage] = useState([]);
  const handleUploadImage = (file) => {
    return new Promise(async (resolve) => {
      const banner_data = await toBase64(file);
      setImage([{ path: banner_data }]);
      change("banner_data", banner_data);
      resolve();
    });
  };

  return (
    <Dashboard header={{ title: "Banner", icon: "info" }}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .banner .image-uploader__box{ width: 100%; height: 70px; transition: none; padding: 0; margin: 0;}`}</style>
      </Helmet>
      <div className="dashboard__form banner">
        <div classNamex="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Banner (1600px x 200px)</label>
            <ImageUploader
              addText="dodaj banner"
              url={""}
              images={images}
              limit={1}
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(({ channel_taxon }) => ({
  channel_taxon,
}))(Banner);
