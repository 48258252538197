import React from "react";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { getAuthor, updateAuthor } from "actions/authors";
import { ImageUploader, Dashboard } from "components";
import { toBase64 } from "utils/helpers";
import { image_url } from "utils/api";

const InputField = (props) => <Input {...props} />;

const AuthorEditBasicData = ({
  updateAuthor,
  match: {
    params: { id },
  },
  handleSubmit,
  pristine,
  author,
}) => {
  const submit = (values) => {
    return updateAuthor(id, { ...values })
      .then(() => {
        toastr.success("Sukces", "Zaktualizowano autora");
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Błąd", "Wystąpił błąd");
        throw new SubmissionError(err);
      });
  };

  const handleUploadImage = (file) => {
    return new Promise(async (resolve, reject) => {
      const image_data = await toBase64(file);
      updateAuthor(id, {
        image_data,
      })
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ icon: "info", title: "Podstawowe informacje" }}
        footer={{
          buttons: [
            <Button
              type="save"
              text="Zapisz"
              disabled={pristine}
              onClick={handleSubmit(submit)}
            />,
          ],
        }}>
        <div className="wrapper">
          <div className="block">
            <Field
              name="first_name"
              label="Imię"
              component={InputField}
              type="text"
            />
            <Field
              name="last_name"
              label="Nazwisko"
              component={InputField}
              type="text"
            />
            <Field
              name="position"
              label="Stanowisko"
              component={InputField}
              type="text"
            />
          </div>
          <div className="block">
            <ImageUploader
              title="Dodaj zdjęcie autora"
              image={
                !!author?.image_url
                  ? { path: `${image_url}${author.image_url}` }
                  : null
              }
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
      </Dashboard>
    </form>
  );
};
const mapStateToProps = ({ author }) => ({
  author,
  initialValues: {
    first_name: author?.first_name,
    last_name: author?.last_name,
    position: author?.position,
  },
});
const mapDispatchToProps = (dispatch) => ({
  getAuthor: (id) => dispatch(getAuthor(id)),
  updateAuthor: (id, data) => dispatch(updateAuthor(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: "edit-author", enableReinitialize: true })(
    withRouter(AuthorEditBasicData)
  )
);
