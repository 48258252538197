import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import discount_reference_type_options from "pages/discounts/const/discount_reference_type_options";
import getDiscountStatus from "pages/discounts/const/getDiscountStatus";
import { deleteHomeDiscount } from "actions/home_discounts";

const Item = ({ id, discount, deleteHomeDiscount }) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false);
  return (
    <tr>
      {is_open_delete_modal && (
        <ModalDelete
          name={discount.name}
          handleDelete={async () => {
            await deleteHomeDiscount(id);
          }}
          handleClose={() => handleOpenDeleteModal(false)}
        />
      )}
      <td>{id}</td>
      <td>{discount?.name}</td>
      <td>
        {discount_reference_type_options.find(
          ({ value }) => value === discount?.discount_reference_type
        )?.label || "brak"}
      </td>
      <td>
        {getDiscountStatus(
          discount?.date_start,
          discount?.date_end,
          discount?.is_unlimited
        )}
      </td>
      <td>
        <Button type="delete-bin" onClick={() => handleOpenDeleteModal(true)} />
      </td>
    </tr>
  );
};

export default connect(null, (dispatch) => ({
  deleteHomeDiscount: (id) => dispatch(deleteHomeDiscount(id)),
}))(Item);
