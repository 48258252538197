import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import { getAuthors, filterAuthors, toggleFilters } from "actions/authors";

const AuthorsList = ({
  authors,
  toggleFilters,
  getAuthors,
  filterAuthors,
  history,
  location: { search },
  match: { params },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getAuthors({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getAuthors({ ...query, page: undefined });
    }
  }, [authors.filters.query]);

  if (authors.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = authors;
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/authors`;
  return (
    <Main breadcrumbs={[{ name: "Autorzy" }]}>
      <Dashboard
        header={{
          icon: "authors",
          title: "Autorzy",
          aside: <Button type='add' text='Dodaj autora' path={`${path}/new`} />,
        }}
      >
        <MainTable
          is_loading={authors.status === "invalid" || authors.status === "loading"}
          is_searching={authors.status === "searching"}
          filters_open={authors.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Imię", name: "first_name" },
            { label: "Nazwisko", name: "last_name" },
            { label: "Stanowisko", name: "position" },
            { label: "Liczba wpisów", name: "posts", sortable: false },
          ]}
          renderRow={({ id, first_name, last_name, position }) => (
            <tr
              key={id}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`${path}/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`${path}/${id}`)}
            >
              <td>{first_name}</td>
              <td>{last_name}</td>
              <td>{position}</td>
              <td>-</td>
            </tr>
          )}
          empty_text='Brak autorów'
          filtersAction={filterAuthors}
          filters={[
            {
              type: "input",
              label: "Imię",
              name: "first_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Naziwsko",
              name: "last_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Stanowisko",
              name: "position",
              search_type: "matches",
            },
          ].map(item => ({ ...item, default_value: authors.filters.values[item.name] }))}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = dispatch => ({
  getAuthors: data => dispatch(getAuthors(data)),
  filterAuthors: data => dispatch(filterAuthors(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(({ authors }) => ({ authors }), mapDispatchToProps)(AuthorsList);
