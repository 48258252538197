import React from "react";
import { Textarea, Input } from "expano-components";
import { Dashboard, CreatableSelect } from "components";
import { Field } from "redux-form";

const InputField = (props) => <Input {...props} />;
const TextareaField = (props) => <Textarea {...props} />;

const Seo = ({ change }) => {
  return (
    <Dashboard header={{ title: "SEO", icon: "info" }}>
      <div className="dashboard__form">
        <Field
          name="meta_title"
          label="Meta title"
          component={InputField}
          type="text"
        />
        <Field
          name="meta_description"
          label="Meta description"
          component={TextareaField}
        />
        <Field
          name="slug"
          label="Slug URL"
          component={InputField}
          type="text"
        />
        <Field name="keyword_list" component={InputField} type="hidden" />
        <Field name="tag_list" component={InputField} type="hidden" />

        <CreatableSelect
          label="Keywords"
          onChange={(value) => change("keyword_list", value)}
        />
        <CreatableSelect
          label="Tagi"
          onChange={(value) => change("tag_list", value)}
        />
      </div>
    </Dashboard>
  );
};

export default Seo;
