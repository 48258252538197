import { toastr } from "react-redux-toastr";
import { client, apiFormatter, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "COLLECTION_PRODUCTS_TOGGLE_FILTERS" });
};

export const filterCollectionProducts = data => async dispatch => {
  dispatch({ type: "COLLECTION_PRODUCTS_SET_FILTERS_QUERY", data });
};

export const getCollectionProducts = ({ page = 1, per_page = 10, ...rest }, category_id) => async (
  dispatch,
  getState
) => {
  const {
    status,
    filters: { query },
  } = getState().collection_products;
  status === "invalid"
    ? dispatch({ type: "COLLECTION_PRODUCTS_LOADING" })
    : dispatch({ type: "COLLECTION_PRODUCTS_SEARCHING" });
  await client
    .get(
      `/channel_product_collections?include=channel_product.product.producer,channel_product.channel_product_gallery_images&q[collection_id_eq]=${category_id}&${stringifyQuery(
        {
          ...rest,
          page,
          per_page,
          ...query,
        }
      )}`
    )
    .then(({ data }) => {
      dispatch({
        type: "COLLECTION_PRODUCTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "COLLECTION_PRODUCTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const deleteCollectionProduct = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/channel_product_collections/${id}`);
      resolve();
    } catch (error) {
      reject();
    }
  });

export const createCollectionProduct = data =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/channel_product_collections`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Dodano produkt do kolekcji");
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd");
        reject();
      });
  });
