import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PageLoader, Main } from "components";
import { getChannelProducer } from "actions/channel_producers";
import { BasicData, ProductsList } from "./components";

const tabs = [
  { id: "basic_data", name: "Ogólne" },
  { id: "products", name: "Produkty" },
];

const ChannelProducerEdit = ({
  getChannelProducer,
  channel_producer,
  history,
  match: { params },
}) => {
  useEffect(() => {
    getChannelProducer(params.id);
  }, []);
  if (
    !channel_producer ||
    channel_producer.status === "invalid" ||
    channel_producer.status === "loading"
  )
    return <PageLoader color='#2959F8' />;

  if (channel_producer.status === "failure") {
    return <p>Error</p>;
  }
  const path = `/platforms/${params.platform_id}/channels/${params.channel_id}/channel_producers`;
  return (
    <Main
      breadcrumbs={[
        {
          name: "Producenci",
          path,
        },
        {
          name: channel_producer?.producer?.name,
        },
      ]}
    >
      <div className='dashboard-wrapper-flex'>
        <div className='dashboard dashboard--nav'>
          <ul className='dashboard__tabs'>
            {tabs.map(({ id, name }) => (
              <li
                key={name}
                onClick={() => history.push(`${path}/${params.id}/${id}`)}
                className={`tab dashboard-tab-text ${id === params.tab ? "active" : ""}`}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Switch>
        <Route
          exact={true}
          path='/platforms/:platform_id/channels/:channel_id/channel_producers/:id/basic_data'
          component={BasicData}
        />
        <Route
          exact={true}
          path='/platforms/:platform_id/channels/:channel_id/channel_producers/:id/products'
          component={ProductsList}
        />
        <Redirect to={`${path}/${params.id}/basic_data`} />
      </Switch>
    </Main>
  );
};

export default connect(
  ({ channel_producer }) => ({
    channel_producer,
  }),
  dispatch => ({
    getChannelProducer: id => dispatch(getChannelProducer(id)),
  })
)(ChannelProducerEdit);
