import React from "react";
import { renderIcons } from "expano-components";
import { Link } from "react-router-dom";

const SidebarItem = ({ name, path, icon, location }) => {
  const is_active = location.pathname.includes(path);
  return (
    <li className={`nav-item ${is_active ? "active" : ""}`}>
      <Link to={path} className='nav-item__trigger'>
        <div className='nav-item__icon'>{renderIcons(icon)}</div>
        <p className='nav-item__title'>{name}</p>
      </Link>
    </li>
  );
};

export default SidebarItem;
