import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";
import {
  DiscountReferenceTypeSelect,
  DiscountCollectionsList,
  DiscountProductsList,
  DiscountProducersList,
  DiscountTaxonsList,
} from "./components";
const renderTableList = type => {
  switch (type) {
    case "none":
      return (
        <div className='p-l-30 p-b-30 p-t-15' style={{ flexBasic: 230, maxWidth: 230 }}>
          <DiscountReferenceTypeSelect />
        </div>
      );
    case "collections":
      return <DiscountCollectionsList />;
    case "channel_products":
      return <DiscountProductsList />;
    case "channel_producers":
      return <DiscountProducersList />;
    case "channel_taxons":
      return <DiscountTaxonsList />;

    default:
      return null;
  }
};

const RelationTypes = ({ discount }) => (
  <Dashboard header={{ title: "Zakres", icon: "info" }}>
    {renderTableList(discount?.discount_reference_type)}
  </Dashboard>
);
export default connect(({ discount }) => ({ discount }))(RelationTypes);
