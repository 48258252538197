import React, { useEffect } from "react";
import { connect } from "react-redux";
import { handleLogin } from "actions/account";
import { Login } from "expano-components";
import { PageLoader } from "components";
import { getCurrentUser } from "actions/current_user";

import ProtectedView from "./ProtectedView";

const app_info = {
  name: "SHOP",
  version: "v. 1.03",
};

const App = ({ current_user, getCurrentUser, handleLogin }) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  if (current_user.status === "invalid" || current_user.status === "loading") {
    return <PageLoader />;
  }
  if (current_user.status === "failure") {
    return (
      <Login
        appInfo={app_info}
        handleLogin={data => {
          handleLogin({ user: { ...data } });
        }}
      />
    );
  }
  return <ProtectedView appInfo={app_info} />;
};

const mapDispatchToProps = dispatch => ({
  handleLogin: val => dispatch(handleLogin(val)),
  getCurrentUser: () => dispatch(getCurrentUser()),
});
export default connect(
  ({ current_user }) => ({
    current_user,
  }),
  mapDispatchToProps
)(App);
