import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PageLoader } from "components";
import DiscountsTable from "./components/DiscountsTable";
import { getHomeDiscounts } from "actions/home_discounts";

const Discounts = ({ home_discounts, getHomeDiscounts }) => {
  useEffect(() => {
    getHomeDiscounts();
  }, []);
  if (
    home_discounts.status === "invalid" ||
    home_discounts.status === "loading"
  )
    return <PageLoader />;
  if (home_discounts.status === "failure") {
    return <p>Error</p>;
  }

  const kind_discount = home_discounts.data.filter(
    ({ kind }) => kind === "featured"
  );
  const kind_deal = home_discounts.data.filter(({ kind }) => kind === "deal");
  
  return (
    <div className="dashboard-wrapper-flex">
      <div className="column-half">
        <DiscountsTable
          title="Sekcje promocyjne"
          kind="featured"
          data={kind_discount}
        />
      </div>
      <div className="column-half">
        <DiscountsTable title="Sekcja okazje" kind="deal" data={kind_deal} />
      </div>
    </div>
  );
};

export default connect(
  ({ home_discounts }) => ({ home_discounts }),
  (dispatch) => ({
    getHomeDiscounts: () => dispatch(getHomeDiscounts()),
  })
)(Discounts);
