import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";

export const getHomeSections = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "HOME_SECTIONS_LOADING" });
    await client
      .get(`/home_sections?include=sectionable&sort[order]=asc&sort[column]=position&per_page=100`)
      .then(({ data }) => {
        dispatch({
          type: "HOME_SECTIONS_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })

      .catch(() => {
        dispatch({ type: "HOME_SECTIONS_FAILURE" });
        toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
        reject();
      });
  });

export const addHomeSection = (data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/home_sections?include=sectionable`, data)
      .then(({ data }) => {
        dispatch({
          type: "HOME_SECTION_ADD",
          data: apiFormatter(data),
        });
        toastr.success("Sukces", "Sekcja została dodana");
        resolve();
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Błąd", "Wystąpił błąd dodawania sekcji");
        reject();
      });
  });

export const deleteHomeSection = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    await client
      .delete(`/home_sections/${id}`)
      .then(() => {
        toastr.success("Sukcjes", "Sekcja została usunięta");

        dispatch({
          type: "HOME_SECTION_DELETE",
          id,
        });
        resolve();
      })
      .catch((err) => {
        toastr.error("Error", "Wystąpił błąd");
        console.log(err);
        reject();
      });
  });

export const moveHomeSection = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: "HOME_SECTIONS_LOADING" });
    try {
      await client.put(`/home_sections/${id}`, data);
      dispatch(getHomeSections());
      resolve();
    } catch (error) {
      reject();
    }
  });
