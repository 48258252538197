import React from "react";
import { Main } from "components";
import { BankForm, ContactForm, DescForm } from "./components";

const ShopData = () => (
  <Main breadcrumbs={[{ name: "Dane sklepu" }]}>
    <div className='dashboard-wrapper-flex'>
      <div className='column-half'>
        <ContactForm />
      </div>
      <div className='column-half'>
        <BankForm />
        <DescForm />
      </div>
    </div>
  </Main>
);

export default ShopData;
