import React, { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { Button, Input, Checkbox } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { Dashboard, Main, PageLoader } from "components";
import {
  getShippingMethods,
  updateShippingMethod,
} from "actions/shipping_methods";
import ReactSelect from "react-select";

const Select = ({ label, options = [], onChange, value }) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#E2E5EC",
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "4px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group`}>
      <label className="label-form">{label}</label>
      <ReactSelect
        placeholder=""
        styles={customStyles}
        defaultValue={value}
        isSearchable={true}
        menuShouldScrollIntoView={true}
        options={options}
        onChange={({ value }) => onChange(value)}
      />
    </div>
  );
};
const InputField = (props) => (
  <Input {...props} input={{ ...props.input, min: 1, step: 0.1 }} />
);
const CheckField = (props) => <Checkbox {...props} />;

const delivery_time = Array(14)
  .fill(null)
  .map((item, i) => ({ label: i + 1, value: i + 1 }));

const ShippingMethods = ({
  shipping_methods,
  getShippingMethods,
  handleSubmit,
  pristine,
  change,
  dispatch,
  updateShippingMethod,
}) => {
  useEffect(() => {
    getShippingMethods();
  }, []);
  const onSubmit = (values) => {
    const promises = [];
    Object.keys(values).forEach((id) => {
      const method_id = id.replace("method_", "");
      const data = values[id];
      data.price = parseFloat(data.price);
      promises.push(updateShippingMethod(method_id, data));
    });
    Promise.all(promises)
      .then(() =>
        toastr.success("Sukces", "Metody dostawy zostały zaktualizowane")
      )
      .catch((error) => toastr.error("Błąd", JSON.stringify(error)));
  };
  const handleCancel = () => {
    dispatch(reset("update-shipping-methods"));
  };
  if (
    shipping_methods.status === "invalid" ||
    shipping_methods.status === "loading"
  )
    return <PageLoader />;

  if (shipping_methods.status === "failure") {
    return <p>Error</p>;
  }
  return (
    <Main breadcrumbs={[{ name: "Dostawa" }]}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Dashboard
          header={{
            icon: "shipping",
            title: "Dostawa",
            aside: (
              <>
                {!pristine && (
                  <Button
                    onClick={handleCancel}
                    type="cancel"
                    text="Anuluj"
                    style={{ marginRight: 20 }}
                  />
                )}
                <Button
                  disabled={pristine}
                  type="save"
                  text="Zapisz zmiany"
                  onClick={handleSubmit(onSubmit)}
                />
              </>
            ),
          }}
        />

        <div className="dashboard">
          <div className="delivery-table">
            <div className="delivery-table__header">
              <div className="box-sm">
                <p className="header-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20">
                    <rect fill="none" width="20" height="20" />
                    <path
                      fill="#5d78f9"
                      opacity="0.3"
                      d="M15.333,13.5V12.231a.417.417,0,0,1,.692-.313l2.446,2.147a.416.416,0,0,1,0,.627l-2.446,2.133a.417.417,0,0,1-.691-.314V15.167h-1.3a5,5,0,0,1-3.947-1.93L6.76,8.954A3.333,3.333,0,0,0,4.129,7.667H2V6H4.129A5,5,0,0,1,8.076,7.93l3.331,4.283A3.333,3.333,0,0,0,14.038,13.5Z"
                      transform="translate(-0.333 -1)"
                    />
                    <path
                      fill="#5d78f9"
                      d="M15.333,5.663V4.394a.417.417,0,0,1,.692-.313l2.446,2.147a.416.416,0,0,1,0,.627L16.024,8.988a.417.417,0,0,1-.691-.314V7.33h-1.3a3.333,3.333,0,0,0-2.631,1.287L8.076,12.9a5,5,0,0,1-3.947,1.93H2V13.163H4.129A3.333,3.333,0,0,0,6.76,11.876l3.331-4.283a5,5,0,0,1,3.947-1.93Z"
                      transform="translate(-0.333 -0.663)"
                    />
                  </svg>
                  <span>Spedytorzy</span>
                </p>
              </div>
              <div className="box-md">
                <p className="header-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20">
                    <rect fill="none" width="20" height="20" />
                    <path
                      fill="#5d78f9"
                      d="M5,9.18A5.839,5.839,0,0,1,11.18,3a6.193,6.193,0,0,1,6.18,6.18q0,2.883-5.38,7.918a1.236,1.236,0,0,1-1.643.041Q5,12.626,5,9.18Zm6.18,1.236a2.06,2.06,0,1,0-2.06-2.06A2.06,2.06,0,0,0,11.18,10.416Z"
                      transform="translate(-0.88 -0.528)"
                    />
                  </svg>
                  <span>Odbiór w punkcie</span>
                </p>
              </div>
              <div className="box-md">
                <p className="header-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20">
                    <rect style={{ fill: "none" }} width="20" height="20" />
                    <path
                      fill="#5d78ff"
                      opacity="0.3"
                      d="M17.648,6.159a.842.842,0,0,1,.5.769V14.3a.842.842,0,0,1-.433.736l-6.733,3.74a.842.842,0,0,1-.817,0l-6.733-3.74A.842.842,0,0,1,3,14.3V6.928a.842.842,0,0,1,.5-.769l6.733-2.992a.842.842,0,0,1,.684,0Z"
                      transform="translate(-0.475 -0.49)"
                    />
                    <path
                      fill="#5d78ff"
                      d="M13.8,4.224,7.6,7.669V11.53l1.683.842V8.659L15.725,5.08Z"
                      transform="translate(-1.204 -0.669)"
                    />
                  </svg>
                  <span>Kurier krajowy</span>
                </p>
              </div>
              <div className="box-md">
                <p className="header-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20">
                    <rect style={{ fill: "none" }} width="20" height="20" />
                    <path
                      fill="#5d78ff"
                      opacity="0.3"
                      d="M17.648,6.159a.842.842,0,0,1,.5.769V14.3a.842.842,0,0,1-.433.736l-6.733,3.74a.842.842,0,0,1-.817,0l-6.733-3.74A.842.842,0,0,1,3,14.3V6.928a.842.842,0,0,1,.5-.769l6.733-2.992a.842.842,0,0,1,.684,0Z"
                      transform="translate(-0.475 -0.49)"
                    />
                    <path
                      fill="#5d78ff"
                      d="M13.8,4.224,7.6,7.669V11.53l1.683.842V8.659L15.725,5.08Z"
                      transform="translate(-1.204 -0.669)"
                    />
                  </svg>
                  <span>Kurier zagraniczny</span>
                </p>
              </div>
            </div>
            <ul className="delivery-table__body">
              {shipping_methods?.speditors?.length > 0 &&
                shipping_methods.speditors.map((item) => {
                  const methods = shipping_methods.data.filter(
                    ({ speditor }) => speditor?.name === item
                  );
                  const pickup_at_point = methods.find(
                    ({ type }) => type === "ShippingMethod::PickupPoint"
                  );
                  const domestic_courier = methods.find(
                    ({ type }) => type === "ShippingMethod::DomesticCourier"
                  );
                  const foreign_courier = methods.find(
                    ({ type }) => type === "ShippingMethod::ForeignCourier"
                  );
                  return (
                    <li className="speditor-row" key={item}>
                      <div className="box box-sm">
                        <span className="speditor_name">{item}</span>
                      </div>
                      <div className="box box-md">
                        {pickup_at_point && (
                          <>
                            <div
                              style={{
                                maxWidth: 230,
                                margin: "0 auto 20px",
                              }}>
                              <Field
                                label="Nazwa"
                                name={`method_${pickup_at_point.id}.name`}
                                component={InputField}
                              />
                            </div>
                            <div className="box-form">
                              <div className="column">
                                <Field
                                  label="zł"
                                  labelAside
                                  type="number"
                                  name={`method_${pickup_at_point.id}.price`}
                                  component={InputField}
                                />
                                <div className="select-group">
                                  <Field
                                    label="zł"
                                    labelAside
                                    name={`method_${pickup_at_point.id}.delivery_days`}
                                    component={(props) => (
                                      <Select
                                        onChange={(val) =>
                                          change(props.input.name, val)
                                        }
                                        label="dni"
                                        options={delivery_time}
                                        value={{
                                          label: props.input.value,
                                          value: props.input.value,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="column">
                                <Field
                                  name={`method_${pickup_at_point.id}.is_active`}
                                  component={CheckField}
                                  label="Aktywny"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="box box-md">
                        {domestic_courier && (
                          <>
                            <div
                              style={{
                                maxWidth: 230,
                                margin: "0 auto 20px",
                              }}>
                              <Field
                                label="Nazwa"
                                name={`method_${domestic_courier.id}.name`}
                                component={InputField}
                              />
                            </div>
                            <div className="box-form">
                              <div className="column">
                                <Field
                                  label="zł"
                                  labelAside
                                  type="number"
                                  name={`method_${domestic_courier.id}.price`}
                                  component={InputField}
                                />
                                <div className="select-group">
                                  <Field
                                    label="zł"
                                    labelAside
                                    name={`method_${domestic_courier.id}.delivery_days`}
                                    component={(props) => (
                                      <Select
                                        onChange={(val) =>
                                          change(props.input.name, val)
                                        }
                                        label="dni"
                                        options={delivery_time}
                                        value={{
                                          label: props.input.value,
                                          value: props.input.value,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="column">
                                <Field
                                  name={`method_${domestic_courier.id}.is_active`}
                                  component={CheckField}
                                  label="Aktywny"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="box box-md">
                        {foreign_courier && (
                          <>
                            <div
                              style={{
                                maxWidth: 230,
                                margin: "0 auto 20px",
                              }}>
                              <Field
                                label="Nazwa"
                                name={`method_${foreign_courier.id}.name`}
                                component={InputField}
                              />
                            </div>
                            <div className="box-form">
                              <div className="column">
                                <Field
                                  label="zł"
                                  labelAside
                                  type="number"
                                  name={`method_${foreign_courier.id}.price`}
                                  component={InputField}
                                />
                                <div className="select-group">
                                  <Field
                                    label="zł"
                                    labelAside
                                    name={`method_${foreign_courier.id}.delivery_days`}
                                    component={(props) => (
                                      <Select
                                        onChange={(val) =>
                                          change(props.input.name, val)
                                        }
                                        label="dni"
                                        options={delivery_time}
                                        value={{
                                          label: props.input.value,
                                          value: props.input.value,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="column">
                                <Field
                                  name={`method_${foreign_courier.id}.is_active`}
                                  component={CheckField}
                                  label="Aktywny"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  shipping_methods: state.shipping_methods,
  initialValues: state.shipping_methods.initialValues,
});

const mapDispatchToProps = (dispatch) => ({
  getShippingMethods: () => dispatch(getShippingMethods()),
  updateShippingMethod: (id, data) => dispatch(updateShippingMethod(id, data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: "update-shipping-methods", enableReinitialize: true })(
    ShippingMethods
  )
);
