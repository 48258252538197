import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Dashboard } from "components";
import { Button, Input } from "expano-components";
import { updateClient } from "actions/clients";

const InputField = props => <Input {...props} />;

const BillingForm = ({ id, updateClient, handleSubmit, pristine }) => {
  const submit = values => updateClient(id, values);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Adres rozliczeniowy", icon: "address" }}
        footer={{
          buttons: (
            <Button disabled={pristine} type='save' text='Zapisz' onClick={handleSubmit(submit)} />
          ),
        }}
      >
        <div className='dashboard__form'>
          <Field
            name='client_billing_address_attributes.company_name'
            component={InputField}
            type='text'
            label='Nazwa firmy'
          />
          <Field
            name='client_billing_address_attributes.company_vat_id'
            component={InputField}
            type='text'
            label='NIP'
          />
          <div className='form-row grid-2'>
            <Field
              name='client_billing_address_attributes.first_name'
              component={InputField}
              type='text'
              label='Imię'
            />
            <Field
              name='client_billing_address_attributes.last_name'
              component={InputField}
              type='text'
              label='Naziwsko'
            />
          </div>
          <Field
            name='client_billing_address_attributes.street'
            component={InputField}
            type='text'
            label='Ulica i numer'
          />
          <div className='form-row gid-2'>
            <Field
              name='client_billing_address_attributes.zip_code'
              component={InputField}
              type='text'
              label='Kod pocztowy'
            />
            <Field
              name='client_billing_address_attributes.city'
              component={InputField}
              type='text'
              label='Miasto'
            />
          </div>
          <Field
            name='client_billing_address_attributes.phone'
            component={InputField}
            type='text'
            label='Telefon'
          />
        </div>
      </Dashboard>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  updateClient: (id, data) => dispatch(updateClient(id, data)),
});

export default connect(
  state => ({
    id: state.client.data.id,
    initialValues: {
      client_billing_address_attributes: state.client.data.client_billing_address,
    },
  }),
  mapDispatchToProps
)(reduxForm({ form: "client-billing-addresses", enableReinitialize: true })(BillingForm));
