const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ARTICLE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "ARTICLE_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action.data,
      };
    case "ARTICLE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "ADD_HREFLANG":
      return {
        ...state,
        status: "success",
        article_hreflangs: [...state.article_hreflangs, action.data],
      };
    case "REMOVE_HREFLANG":
      return {
        ...state,
        status: "success",
        article_hreflangs: state.article_hreflangs.filter(({ id }) => id !== action.id),
      };
    case "UPDATE_HREFLANG":
      let article_hreflangs = [...state.article_hreflangs];
      const index = article_hreflangs.indexOf(article_hreflangs.find(({ id }) => id === action.data.id));
      if (index > -1) {
        article_hreflangs[index] = action.data;
        return {
          ...state,
          status: "success",
          article_hreflangs,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
