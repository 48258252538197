import React, { useState } from "react";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import Editor from "react-markdown-editor-lite";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Dashboard } from "components";
import { updateArticle } from "actions/articles";

const Content = ({ article, updateArticle, handleSubmit, pristine, change, dispatch }) => {
  const [text, setText] = useState(article?.content || "");

  const submit = values =>
    updateArticle(article.id, values).catch(err => {
      console.log(err);
      throw new SubmissionError(err);
    });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Content", icon: "edit" }}
        footer={{
          buttons: [
            !pristine && (
              <Button
                type='cancel'
                onClick={() => {
                  dispatch(reset("edit-article-content"));
                  setText(article?.content || "");
                }}
              />
            ),
            <Button type='save' text='Zapisz' disabled={pristine} onClick={handleSubmit(submit)} />,
          ],
        }}
      >
        <div className='dashboard__form'>
          <Field component={props => <Input {...props} />} type='hidden' name='content' />
          <Editor
            plugins={["header", "fonts", "link"]}
            value={text}
            config={{
              view: {
                menu: true,
                md: true,
                html: false,
                fullScreen: false,
                hideMenu: false,
              },
            }}
            style={{ height: "600px" }}
            onChange={({ text }) => {
              change("content", text);
              setText(text);
            }}
          />
        </div>
      </Dashboard>
    </form>
  );
};

const mapStateToProps = ({ article }) => ({
  article,
  initialValues: {
    content: article?.content,
  },
});

const mapDispatchToProps = dispatch => ({
  updateArticle: (id, data) => dispatch(updateArticle(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "edit-article-content", enableReinitialize: true })(Content));
