import React from "react";

const Info = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle fill='#5d78ff' opacity='0.3' cx='12' cy='12' r='10' />
        <rect fill='#5d78ff' x='11' y='10' width='2' height='7' rx='1' />
        <rect fill='#5d78ff' x='11' y='7' width='2' height='2' rx='1' />
      </g>
    </svg>
  );
};

export default Info;
