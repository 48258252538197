import React from "react";
import { Button } from "expano-components";
import { connect } from "react-redux";
import { Form, FormNew, ListItem } from "./components";
import { PageLoader } from "components";
import { Info } from "icons";
import { getSliders } from "actions/sliders";

class Sliders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active_tab_id: null,
    };
  }

  componentDidMount = () => {
    this.props.getSliders().then(() => {
      if (this.props.sliders?.data?.length > 0) {
        this.setState({ active_tab_id: this.props.sliders.data[0].id });
      }
    });
  };

  render() {
    const { sliders } = this.props;
    const { active_tab_id } = this.state;
    if (sliders.status === "invalid" || sliders.status === "loading")
      return <PageLoader />;
    if (sliders.status === "failure") {
      return <p>Error</p>;
    }

    return (
      <div className="dashboard-wrapper-flex">
        <div className="column-1">
          <div className="dashboard bg-none m-b-0">
            <ul className="aside-tabs">
              {sliders?.data
                .sort((item, next) => item.position - next.position)
                .map((props) => (
                  <ListItem
                    count={sliders.data.length}
                    key={props.id}
                    is_active={props.id === active_tab_id}
                    setTab={() => this.setState({ active_tab_id: props.id })}
                    setFirstTab={() =>
                      this.setState({
                        active_tab_id:
                          sliders?.data?.length > 0 ? sliders.data[0].id : null,
                      })
                    }
                    {...props}
                  />
                ))}
            </ul>
          </div>
          <div className="aside-tabs__action">
            <Button
              type="add"
              text="Dodaj slide"
              onClick={() => this.setState({ active_tab_id: "new" })}
            />
          </div>
        </div>
        <div className="column-2">
          <div className="dashboard">
            <header className="dashboard__header dashboard__header--edit">
              <div className="icon__container">
                <Info />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            {sliders?.data.map(
              ({ id, name, url, image_url, mobile_image_url }) =>
                id === active_tab_id && (
                  <Form
                    key={id}
                    form={`slider_${id}`}
                    id={id}
                    data={{ image_url, mobile_image_url }}
                    initialValues={{
                      name,
                      url,
                    }}
                  />
                )
            )}
            {active_tab_id === "new" && (
              <FormNew
                handleChangeTab={(active_tab_id) =>
                  this.setState({ active_tab_id })
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSliders: () => dispatch(getSliders()),
});

export default connect(
  ({ sliders }) => ({ sliders }),
  mapDispatchToProps
)(Sliders);
