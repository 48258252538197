import React from "react";

import StyledEmpty from "./styles"

const Empty = ({ empty_text = "Brak danych" }) => {
  return (
    <StyledEmpty>
      <p>{empty_text}</p>
    </StyledEmpty>
  );
};

export default Empty;
