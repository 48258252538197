import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";
import ListRow from "./components/ListRow";
import { Link, withRouter } from "react-router-dom";

const ProductsPanel = ({
  discount_code,
  order_lines,
  match: {
    params: { platform_id, channel_id },
  },
}) => {
  return (
    <Dashboard
      header={{
        title: "Zamówione produkty",
        icon: "products",
        aside: discount_code?.name ? (
          <p>
            Zastosowany rabat:{" "}
            <Link
              className="text-link"
              to={`/platforms/${platform_id}/channels/${channel_id}/discount_codes/${discount_code.id}`}>
              {discount_code.name}
            </Link>
          </p>
        ) : null,
      }}>
      <table className="table">
        <thead className="table__header table-head">
          <tr>
            <th width="90px"></th>
            <th>Nazwa</th>
            <th>Sku</th>
            <th>Ilość</th>
            <th>Cena</th>
          </tr>
        </thead>
        <tbody className="table__body table-body order-lines">
          {order_lines?.length > 0 ? (
            order_lines.map((item) => (
              <ListRow key={item.id} {...item} />
            ))
          ) : (
            <tr>
              <td />
              <td>Brak produktów</td>
            </tr>
          )}
        </tbody>
      </table>
    </Dashboard>
  );
};

export default connect(({ order }) => ({
  order_lines: order?.data?.order_lines,
  discount_code: order?.data?.discount_code,
}))(withRouter(ProductsPanel));
