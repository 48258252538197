import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parseQuery } from "utils/api";
import { PageLoader, MainTable, Dashboard } from "components";
import { getBlogAuthorPosts } from "actions/authors";

const AuthorPosts = ({ author, author_posts, getBlogAuthorPosts, match: { params }, history, location, channel_id }) => {
  const query = parseQuery(location.search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };
  useEffect(() => {
    getBlogAuthorPosts({ ...query, ...sort, author_id: params?.id });
  }, []);
  if (!author || author.status === "invalid" || author.status === "loading") return <PageLoader />;

  if (author.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = author_posts;
  return (
    <Dashboard
      header={{
        icon: "posts",
        title: "Wpisy autora",
        aside: [
          <span key='keader-text' className='text-default text-muted'>
            Wszystkie wyniki: {meta?.total_count || "-"}
          </span>,
        ],
      }}
    >
      <MainTable
        is_loading={author.status === "invalid" || author.status === "loading"}
        is_searching={author.status === "searching"}
        data={data}
        meta={meta}
        sort={sort}
        head={[
          { label: "Tytuł wpisu", name: "title" },
          { label: "Data publikacji", name: "meta_created_at" },
          { label: "Data aktualizacji", name: "meta_updated_at" },
        ]}
        renderRow={({ id, title, meta_created_at, meta_updated_at }) => (
          <tr
            key={id}
            onMouseDown={event => {
              if (event.button === 1) {
                window.open(`/platforms/${params.platform_id}/channels/${params.channel_id}/articles/${id}`, "_blank");
              }
            }}
            onClick={() => history.push(`/platforms/${params.platform_id}/channels/${params.channel_id}/articles/${id}`)}
          >
            <td>{title}</td>
            <td>{meta_created_at}</td>
            <td>{meta_updated_at}</td>
          </tr>
        )}
        empty_text='Brak wpisów autora'
      />
    </Dashboard>
  );
};

const mapStateToProps = ({ author, author_posts, channel }) => ({
  channel_id: channel.data.id,
  author,
  author_posts,
});

const mapDispatchToProps = dispatch => ({
  getBlogAuthorPosts: data => dispatch(getBlogAuthorPosts(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthorPosts));
