import React, { useState } from "react";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import Editor from "react-markdown-editor-lite";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Dashboard } from "components";
import { updateChannelTaxon } from "actions/channel_taxons";

const Content = ({
  channel_taxon,
  updateChannelTaxon,
  handleSubmit,
  pristine,
  change,
  dispatch,
}) => {
  const [text, setText] = useState(channel_taxon?.content || "");

  const submit = (values) =>
    updateChannelTaxon(channel_taxon.id, values).catch((err) => {
      console.log(err);
      throw new SubmissionError(err);
    });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Dashboard
        header={{ title: "Content", icon: "edit" }}
        footer={{
          buttons: (
            <>
              {!pristine && (
                <Button
                  type="cancel"
                  onClick={() => {
                    dispatch(reset("edit-channel-taxon-content"));
                    setText(channel_taxon?.content || "");
                  }}
                />
              )}
              <Button
                type="save"
                text="Zapisz"
                disabled={pristine}
                onClick={handleSubmit(submit)}
              />
            </>
          ),
        }}>
        <div className="dashboard__form">
          <Field
            component={(props) => <Input {...props} />}
            type="hidden"
            name="content"
          />
          <Editor
            plugins={["header", "fonts", "link"]}
            value={text}
            config={{
              view: {
                menu: true,
                md: true,
                html: false,
                fullScreen: false,
                hideMenu: false,
              },
            }}
            style={{ height: "600px" }}
            onChange={({ text }) => {
              change("content", text);
              setText(text);
            }}
          />
        </div>
      </Dashboard>
    </form>
  );
};

const mapStateToProps = ({ channel_taxon }) => ({
  channel_taxon,
  initialValues: { content: channel_taxon?.content },
});

const mapDispatchToProps = (dispatch) => ({
  updateChannelTaxon: (id, data) =>
    dispatch(updateChannelTaxon(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-channel-taxon-content",
    enableReinitialize: true,
  })(Content)
);
