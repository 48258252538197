import React, { PureComponent, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Main } from "components";
import { Banners, HomeSections, Discounts, Sliders } from "./panels";

const Redirect = ({ history, params }) => {
  useEffect(() => {
    history.push(
      `/platforms/${params.platform_id}/channels/${params.channel_id}/home_page/banners`
    );
  }, []);
  return null;
};

class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        id: "banners",
        name: "Banery",
      },
      {
        id: "sliders",
        name: "Slider",
      },
      {
        id: "discounts",
        name: "Promocje",
      },
      {
        id: "home_sections",
        name: "Sekcje",
      },
    ];
  }

  renderPanels() {
    const {
      match: { params },
      history,
    } = this.props;
    switch (params?.tab) {
      case "banners":
        return <Banners />;
      case "sliders":
        return <Sliders />;
      case "home_sections":
        return <HomeSections />;
      case "discounts":
        return <Discounts />;
      default:
        return <Redirect history={history} params={params} />;
    }
  }
  render() {
    const {
      history,
      match: { params },
    } = this.props;

    return (
      <Main
        breadcrumbs={[
          {
            name: "Strona główna",
            path: `/platforms/${params.platform_id}/channels/${params.channel_id}/home_page`,
            icon: "home_page",
          },
        ]}>
        <div className="dashboard-wrapper-flex">
          <div className="dashboard dashboard--nav">
            <header className="dashboard__header dashboard__header--edit dashboard__header--edit--nav">
              <div className="icon__container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20">
                  <rect fill="none" width="20" height="20" />
                  <path
                    fill="#5d78f9"
                    d="M5.226,4H8.494A1.226,1.226,0,0,1,9.72,5.226v.817A1.226,1.226,0,0,1,8.494,7.269H5.226A1.226,1.226,0,0,1,4,6.043V5.226A1.226,1.226,0,0,1,5.226,4Zm7.354,9.806h3.269a1.226,1.226,0,0,1,1.226,1.226v.817a1.226,1.226,0,0,1-1.226,1.226H12.58a1.226,1.226,0,0,1-1.226-1.226v-.817A1.226,1.226,0,0,1,12.58,13.806Z"
                    transform="translate(-0.731 -0.731)"
                  />
                  <path
                    fill="#5d78f9"
                    opacity="0.3"
                    d="M5.226,8.9H8.494A1.226,1.226,0,0,1,9.72,10.129v5.72a1.226,1.226,0,0,1-1.226,1.226H5.226A1.226,1.226,0,0,1,4,15.849v-5.72A1.226,1.226,0,0,1,5.226,8.9ZM12.58,4h3.269a1.226,1.226,0,0,1,1.226,1.226v5.72a1.226,1.226,0,0,1-1.226,1.226H12.58a1.226,1.226,0,0,1-1.226-1.226V5.226A1.226,1.226,0,0,1,12.58,4Z"
                    transform="translate(-0.731 -0.731)"
                  />
                </svg>
              </div>

              <h2 className="dashboard__title heading dashboard__title--nav">
                Strona główna
              </h2>
            </header>

            <ul className="dashboard__tabs">
              {this.tabs.map(({ id, name }) => (
                <li
                  key={id}
                  className={`tab dashboard-tab-text ${
                    id === params.tab ? "active" : ""
                  }`}
                  onClick={() =>
                    history.push(
                      `/platforms/${params.platform_id}/channels/${params.channel_id}/home_page/${id}`
                    )
                  }>
                  {name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {this.renderPanels()}
      </Main>
    );
  }
}
export default connect(
  ({ channel }) => ({ channel_id: channel.data.id }),
  null
)(withRouter(HomePage));
