import React, { useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button, ImageUploader, Input } from "expano-components";
import { Field, reduxForm, reset } from "redux-form";
import { updateSlide } from "actions/sliders";
import { toBase64 } from "utils/helpers";
import { image_url } from "utils/api";

const InputField = (props) => <Input {...props} input={{ ...props.input }} />;

const Form = ({
  initialValues,
  pristine,
  dispatch,
  submitting,
  form,
  handleSubmit,
  updateSlide,
  change,
  data,
  id,
}) => {
  console.log(data);
  const init_image = data?.image_url
    ? [{ path: `${image_url}${data.image_url}` }]
    : [];
  const init_mobile_image = data?.mobile_image_url
    ? [{ path: `${image_url}${data.mobile_image_url}` }]
    : [];
  const [image, setImage] = useState(init_image);
  const [mobile_image, setMobileImage] = useState(init_mobile_image);
  const submit = async (data) => {
    updateSlide(id, data)
      .then(() => {
        toastr.success("Sukces", "Zaktualizowano pomyślnie");
        dispatch(reset(form));
      })
      .catch(() => toastr.error("Error", "Wystąpił błąd"));
  };

  const handleUploadImage = (file) => {
    return new Promise(async (resolve) => {
      const file_data = await toBase64(file);
      setImage([{ path: file_data }]);
      change("image_data", file_data);
      resolve();
    });
  };

  const handleUploadMobileImage = (file) => {
    return new Promise(async (resolve) => {
      const file_data = await toBase64(file);
      setMobileImage([{ path: file_data }]);
      change("mobile_image_data", file_data);
      resolve();
    });
  };

  const handleCancel = () => {
    setImage(init_image);
    dispatch(reset(form));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Helmet>
        <style>{`.image-uploader{padding: 0;} .image-uploader__box{ width: 100%; height: 180px; margin: 0; transition: none; }`}</style>
      </Helmet>
      <div className="dashboard__form__submit">
        <Field label="URL" type="text" name="url" component={InputField} />
        <Field
          label="Nazwa slide'u"
          type="text"
          name="name"
          component={InputField}
        />
        <Field type="hidden" name="image_data" component={InputField} />
        <Field type="hidden" name="mobile_image_data" component={InputField} />
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Grafika duża</label>
            <ImageUploader
              addText="dodaj grafikę"
              url={""}
              images={image}
              limit={1}
              handleUpload={handleUploadImage}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="inp-wrapper">
            <label htmlFor="">Grafika mała {"(< 1060px)"}</label>
            <ImageUploader
              addText="dodaj grafikę"
              url={""}
              images={mobile_image}
              limit={1}
              handleUpload={handleUploadMobileImage}
            />
          </div>
        </div>
      </div>
      <div className="dashboard__form__footer">
        {!pristine && (
          <Button
            type="cancel"
            style={{ marginRight: 15 }}
            onClick={handleCancel}
          />
        )}
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || submitting}
          onClick={handleSubmit(submit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateSlide: (id, data) => dispatch(updateSlide(id, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    enableReinitialize: true,
  })(Form)
);
