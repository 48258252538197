import React from "react";
import { connect } from "react-redux";
import { getCookie } from "utils/cookies";
import { handleLogin } from "actions/account";
import { Login } from "expano-components";
import PlatformView from "./PlatformView";

const app_info = {
  name: "SHOP",
  version: "v. 1.03",
};

const App = ({ handleLogin }) => {
  return !!getCookie("token") ? (
    <PlatformView appInfo={app_info} />
  ) : (
    <Login
      appInfo={app_info}
      handleLogin={data => {
        handleLogin({ user: { ...data } });
      }}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  handleLogin: val => dispatch(handleLogin(val)),
});
export default connect(null, mapDispatchToProps)(App);
